/* eslint-disable react/display-name */
import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';

interface ContainerProps {
  size?: number;
  width?: number;
  height?: number;
  disabled?: boolean;
}

const Container = styled(Box)<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size, width }) => width ?? size ?? 40}px;
  height: ${({ size, height }) => height ?? size ?? 40}px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
`;

interface IconContainerProps extends BoxProps {
  size?: number;
  width?: number;
  height?: number;
  children: React.ReactNode;
  disabled?: boolean;
}

const IconContainer: React.FC<IconContainerProps> = React.forwardRef(
  ({ size, width, height, children, disabled, ...otherProps }, ref) => {
    return (
      <Container
        className="IconContainer"
        size={size}
        width={width}
        height={height}
        disabled={disabled}
        {...otherProps}
        ref={ref}
      >
        {children}
      </Container>
    );
  },
);

export default IconContainer;
