import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocizeBackend from 'i18next-locize-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
// eslint-disable-next-line import/no-extraneous-dependencies
import { locizePlugin } from 'locize';

const LANGUAGE_SETS = ['en', 'zh-Hant', 'ja'];

let defaultLanguage = localStorage.getItem('language') ?? '';

if (!LANGUAGE_SETS.includes(defaultLanguage)) {
  defaultLanguage = 'en';
}

let backEndModule;
let backend;

if (__LOCIZE_ENV__ === 'development') {
  backEndModule = ChainedBackend;
  backend = {
    backends: [
      HttpBackend,
      resourcesToBackend((lng, ns, clb) => {
        import(`../public/locales/${lng}/${ns}.json`)
          .then((resources) => clb(null, resources))
          // @ts-ignore
          .catch(clb);
      }),
    ],
    backendOptions: [
      {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
    ],
  };
} else {
  backEndModule = LocizeBackend;
  backend = {
    projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID ?? '',
    apiKey: import.meta.env.VITE_LOCIZE_API_KEY ?? '',
    referenceLng: defaultLanguage,
    version: __LOCIZE_ENV__,
  };
}

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(locizePlugin)
  .use(initReactI18next)
  // @ts-ignore
  .use(backEndModule)
  .init({
    backend,
    saveMissing: __LOCIZE_ENV__ === 'staging',
    supportedLngs: ['en', 'zh-Hant', 'ja'],
    lng: defaultLanguage,
    ns: [
      'common',
      'login',
      'project',
      'sidebar',
      'devices',
      'account',
      'error',
      'register',
      'language-setting',
      'project-setting',
      'dashboard',
      'daspower-dashboard',
      'public-announcement',
      'daslock',
    ],
    fallbackLng: 'en',
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: 'languageChanged editorSaved',
    },
  });

export const changeDefaultLanguage = (lang: string) => {
  localStorage.setItem('language', lang);
};

export const toBCP47 = (code: string) => {
  switch (code) {
    case 'en':
      return 'en-US';
    case 'zh-Hant':
    case 'zh-hant':
      return 'zh-TW';
    case 'ja':
      return 'ja-JP';
    default:
      return code;
  }
};

export default i18n;
