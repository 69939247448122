import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Dropdown } from '@beeinventor/dasiot-react-component-lib';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { styled, Typography } from '@mui/material';

import {
  DropdownItem,
  InputValidationError,
  SettingMap,
  StructureAssign,
} from '../../../types';

import { useAppSelector } from '../../../hooks';
import useDebounce from '../../../hooks/useDebounce';
import { useLocalStorageObject } from '../../../hooks/useLocalStorageObject';

import PinSvgIcon from '../../../assets/SvgIcon/PinSvgIcon';
import i18n from '../../../i18n';
import {
  isLatitude,
  isLongitude,
  splitCoord,
} from '../../../utils/separateLongLat';
import DialogSetPinLocation3D from '../../DialogPinLocation/DialogSetPinLocation3D';
import OrgAutocompleteBasic from '../../OrgAutocompleteBasic';
import InputField from '../../TextField/InputField';
import InputTextArea from '../../TextField/InputTextArea';
import SizeInputField from '../../TextField/SizeInputField';
import {
  checkEmptyNumber,
  checkNameFormat,
} from '../validation/management-validation';

import './TargetOperationDialog.css';

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled('div')`
  flex: 1;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0 10px 10px 10px;
  border-radius: 4px;
`;

const ContentTitle = styled(Typography)`
  color: white;
  line-height: 2.125rem;
`;

const SpanRequire = styled('span')`
  color: ${({ theme }) => theme.color.highlight};
`;

const ContainerDetails = styled('div')`
  margin: 10px 0;
  height: 58px;
`;

const ContainerMap = styled('div')`
  height: 140px;
  margin: 10px 0;
  border-radius: 4px;
  z-index: 1;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.box_bbg,
}));

const ContainerDropdown = styled('div', { label: 'Container-Dropdown' })`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.secondary.$60};
  & > .Dropdown-empty {
    color: red;
  }
  & > .Dropdown-root {
    color: ${({ theme }) => theme.color.secondary.$60};
    font-weight: 400;
    font-size: 14px;
    padding: 0 8px;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
    & > .Dropdown-icon {
      width: 32px;
      height: 32px;
    }
  }

  & > .Dropdown--disabled {
    ${({ theme }) => ({ ...theme.typography.body2 })}
    background: #e5e5e5;
    color: ${({ theme }) => theme.color.secondary.$80};
  }
  & div[role='tooltip'] {
    & > .MuiBox-root {
      color: ${({ theme }) => theme.color.box_bbg};
      ${({ theme }) => ({ ...theme.typography.body2 })}
      background: ${({ theme }) => theme.color.secondary.$100};
      max-height: 150px;
      overflow: scroll;
    }
    z-index: 5;
  }
`;

interface StructureBasicInformationContentContentProps {
  handleStructureAssign: (data: StructureAssign) => void;
  data: StructureAssign | undefined;
}

const StructureBasicInformationContent: React.FC<
  StructureBasicInformationContentContentProps
> = ({ handleStructureAssign, data }) => {
  const { projectId } = useParams();
  const orgMap = useAppSelector((store) => store.system.orgMap);

  const [nameFormatError, setNameFormatError] = useState<InputValidationError>({
    isError: false,
    message: '',
  });

  const [altitudeFormatError, setAltitudeFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });
  const [standardStrengthFormatError, setStandardStrengthFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });
  const [settingAngleFormatError, setSettingAngleFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });
  const [alertAngleFormatError, setAlertAngleFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });
  const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null);
  const mapboxRef = useRef<mapboxgl.Map | null>(null);
  const markerRef = useRef<mapboxgl.Marker | null>(null);

  const [orgIdError, setOrgIdError] = useState<InputValidationError>({
    isError: false,
    message: '',
  });
  const { t } = useTranslation('project-setting');
  const [mapLocal] = useLocalStorageObject<SettingMap>(
    'mapStyleSetting_',
    projectId,
  );

  const [openSetPinLocation, setOpenPinLocation] = useState(false);
  const [localLongLat, setLocalLongLat] = useState('');
  const queryLatLong = useDebounce(localLongLat, 1000);
  const project = useAppSelector((store) => store.projects.currentProject);
  const [pinLocation, setPinLocation] = useState<[number, number]>([
    data?.location?.lon ?? 0,
    data?.location?.lat ?? 0,
  ]);

  useEffect(() => {
    if (queryLatLong) {
      const { lon, lat } = splitCoord(queryLatLong);
      if (isLongitude(lon) && isLatitude(lat)) {
        setPinLocation([lon, lat]);
      }
    }
  }, [queryLatLong]);

  const dialogRef = useRef<HTMLDivElement>(null);

  const handleOnChange = (
    propertyObjs: Array<{ propertyName: keyof StructureAssign; value: any }>,
  ) => {
    const currentValue = {
      ...data,
    };
    propertyObjs.forEach((obj) => {
      // @ts-ignore
      currentValue[obj.propertyName] = obj.value;
    });

    handleStructureAssign({
      orgId: currentValue?.orgId ?? '',
      alertAngle: currentValue.alertAngle,
      bindingDevices: currentValue.bindingDevices,
      deviceCad: currentValue.deviceCad,
      deviceCadPosition: currentValue.deviceCadPosition,
      endTime: currentValue.endTime,
      startTime: currentValue.startTime,
      fc28: currentValue.fc28 ?? 0,
      fieldType: currentValue.fieldType ?? undefined,
      groupIds: currentValue.groupIds ?? [],
      type: 'field',
      strengthTarget: currentValue.strengthTarget,
      imageUrl: currentValue.imageUrl ?? null,
      id: currentValue.id ?? '',
      materialType: currentValue.materialType,
      location: currentValue.location ?? null,
      name: currentValue.name ?? '',
      settingAngle: currentValue.settingAngle,
      size: currentValue.size,
      remark: currentValue.remark,
    });
  };

  const LIST_ORG = useMemo(() => {
    const orgs = Object.values(orgMap);
    if (orgs.length > 0) {
      return orgs.map((organization) => ({
        id: organization?.id ?? '',
        name: organization?.name ?? '',
        color: organization?.color ?? '',
      }));
    }
    return [
      {
        id: '',
        name: '',
        color: '',
      },
    ];
  }, [orgMap]);

  useEffect(() => {
    const initial = async () => {
      const { default: mapboxgl } = await import(
        /* webpackChunkName: "mapbox-gl" */ 'mapbox-gl'
      );

      mapboxRef.current = new mapboxgl.Map({
        accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN || '',
        container: 'project-setting-tower-crane-dialog-map',
        interactive: false,
        zoom: 15,
        style:
          mapLocal && mapLocal.mapStyle === 'satelite'
            ? 'mapbox://styles/mapbox/satellite-streets-v12'
            : 'mapbox://styles/ci-bot-tw/cl7iuaop2000014pbc5d3jgoe',
        center: pinLocation,
      });

      mapboxRef.current.addControl(
        new MapboxLanguage({
          defaultLanguage: i18n.language,
        }),
      );

      markerRef.current = new mapboxgl.Marker()
        .setLngLat(pinLocation)
        .addTo(mapboxRef.current);
    };

    if (mapRef) {
      if (!mapboxRef.current) {
        initial();
      }
    }

    return () => {
      mapboxRef.current?.remove();
      mapboxRef.current = null;
    };
  }, [mapRef, mapLocal]);

  useEffect(() => {
    if (project && pinLocation.every((val) => val === 0)) {
      setPinLocation(project.center);
    }
  }, [pinLocation, project]);

  useEffect(() => {
    mapboxRef.current?.setCenter(pinLocation);
    markerRef.current?.setLngLat(pinLocation);
    if (isLongitude(pinLocation[0]) && isLatitude(pinLocation[1])) {
      setLocalLongLat(pinLocation.join(', '));
    }
  }, [pinLocation]);

  const onOpenSetPinLocation = () => {
    setOpenPinLocation(true);
  };

  useEffect(() => {
    if (
      data &&
      pinLocation[0] !== 0 &&
      pinLocation[1] !== 0 &&
      (data?.location?.lat !== pinLocation[1] ||
        data?.location?.lon !== pinLocation[0])
    ) {
      handleStructureAssign({
        ...data,
        location: {
          ...data?.location,
          lat: pinLocation[1],
          lon: pinLocation[0],
        },
      } as StructureAssign);
    }
  }, [data, pinLocation]);

  const LIST_FIELD_TYPE: DropdownItem = [
    {
      id: 'floor',
      name: t('floor'),
      value: 'floor',
    },
    {
      id: 'wall',
      name: t('wall'),
      value: 'wall',
    },
    {
      id: 'pillar',
      name: t('pillar'),
      value: 'pillar',
    },
    {
      id: 'balk',
      name: t('balk'),
      value: 'balk',
    },
  ];

  const LIST_MATERIAL_TYPE: DropdownItem = [
    {
      id: 'normalConcrete',
      name: t('concrete-type-list.normalConcrete'),
      value: 'normalConcrete',
    },
    {
      id: 'earlyConcrete',
      name: t('concrete-type-list.earlyConcrete'),
      value: 'earlyConcrete',
    },
    {
      id: 'mediumConcrete',
      name: t('concrete-type-list.mediumConcrete'),
      value: 'mediumConcrete',
    },
    {
      id: 'lowTempConcrete',
      name: t('concrete-type-list.lowTempConcrete'),
      value: 'lowTempConcrete',
    },
    {
      id: 'blastFurnaceConcrete',
      name: t('concrete-type-list.blastFurnaceConcrete'),
      value: 'blastFurnaceConcrete',
    },
    {
      id: 'flyAshConcrete',
      name: t('concrete-type-list.flyAshConcrete'),
      value: 'flyAshConcrete',
    },
  ];

  return (
    <Container ref={dialogRef}>
      <ContentTitle>
        {t('page.plant-management.dialog.create-plant.basic-information.title')}
        <SpanRequire>*</SpanRequire>
      </ContentTitle>
      <Content>
        <ContainerDetails>
          <Title>
            {t('organization')}
            <SpanRequire>*</SpanRequire>
          </Title>
          <OrgAutocompleteBasic
            data={LIST_ORG}
            onSelect={(d) => {
              handleOnChange([{ propertyName: 'orgId', value: d.id }]);
            }}
            onBlur={() => {
              if (!data?.orgId) {
                setOrgIdError({ isError: true, message: t('error') });
              } else {
                setOrgIdError({ isError: false, message: t('') });
              }
            }}
            error={orgIdError}
            selectedId={data?.orgId ?? ''}
            data-cy="dropdown-org-plant"
            placeholder={t('page.select-organization')}
          />
        </ContainerDetails>
        <InputField
          title={t('name')}
          name={t('name')}
          value={data?.name ?? ''}
          isRequired
          onChange={(e) =>
            handleOnChange([
              { propertyName: 'name', value: e.currentTarget.value },
            ])
          }
          onBlur={() => setNameFormatError(checkNameFormat(data?.name ?? ''))}
          error={nameFormatError}
        />
        <ContainerDropdown>
          <Title>
            {t('structure-type')} <SpanRequire>*</SpanRequire>
          </Title>
          <Dropdown
            mode="dark"
            list={LIST_FIELD_TYPE}
            onSelect={(value) => {
              handleOnChange([
                {
                  propertyName: 'fieldType',
                  value: value,
                },
              ]);
            }}
            popperProps={{ disablePortal: true }}
            selectedId={data?.fieldType}
            placeholder={t('select-structure-type')}
          />
        </ContainerDropdown>
        <ContainerDropdown>
          <Title>
            {t('concrete-type')} <SpanRequire>*</SpanRequire>
          </Title>
          <Dropdown
            mode="dark"
            list={LIST_MATERIAL_TYPE}
            onSelect={(value) => {
              handleOnChange([
                {
                  propertyName: 'materialType',
                  value: value,
                },
              ]);
            }}
            popperProps={{ disablePortal: true }}
            selectedId={data?.materialType}
            placeholder={t('select-concrete-type')}
          />
        </ContainerDropdown>
        <InputField
          title={t('standard-strength')}
          name={t('standard-strength')}
          value={data?.strengthTarget}
          isRequired
          onChange={(e) =>
            handleOnChange([
              {
                propertyName: 'strengthTarget',
                value: e.currentTarget.value,
              },
            ])
          }
          onBlur={() =>
            setStandardStrengthFormatError(
              checkEmptyNumber(data?.strengthTarget),
            )
          }
          error={standardStrengthFormatError}
        />
        <InputField
          title={t('strength-coefficient')}
          name={t('strength-coefficient')}
          value={data?.fc28}
          isRequired
          onChange={(e) =>
            handleOnChange([
              { propertyName: 'fc28', value: e.currentTarget.value },
            ])
          }
          onBlur={() =>
            setStandardStrengthFormatError(
              checkEmptyNumber(data?.strengthTarget),
            )
          }
          error={standardStrengthFormatError}
        />
        <InputField
          title={t('setting-angle')}
          name={t('setting-angle')}
          value={data?.settingAngle}
          isRequired
          onChange={(e) =>
            handleOnChange([
              {
                propertyName: 'settingAngle',
                value: e.currentTarget.value,
              },
            ])
          }
          onBlur={() =>
            setSettingAngleFormatError(checkEmptyNumber(data?.strengthTarget))
          }
          error={settingAngleFormatError}
        />
        <InputField
          title={t('alert-angle')}
          name={t('alert-angle')}
          value={data?.alertAngle}
          isRequired
          onChange={(e) =>
            handleOnChange([
              {
                propertyName: 'alertAngle',
                value: e.currentTarget.value,
              },
            ])
          }
          onBlur={() =>
            setAlertAngleFormatError(checkEmptyNumber(data?.strengthTarget))
          }
          error={alertAngleFormatError}
        />
        <InputField
          title={t('location')}
          name="group-latlong"
          value={localLongLat}
          onChange={(e) => setLocalLongLat(e.target.value)}
          isRequired
          endAdornment={
            <PinSvgIcon
              sx={{
                width: '32px',
                height: '32px',
                cursor: 'pointer',
                ':hover': {
                  background: 'rgba(6, 5, 5, 0.3)',
                  borderRadius: '4px',
                },
              }}
              color="inherit"
              onClick={onOpenSetPinLocation}
            />
          }
        />
        <InputField
          title={t('altitude')}
          name={t('altitude')}
          value={data?.location?.alt}
          isRequired
          onChange={(e) => {
            handleOnChange([
              {
                propertyName: 'location',
                value: {
                  ...data?.location,
                  alt: e.currentTarget.value,
                },
              },
            ]);
          }}
          onBlur={() =>
            setAltitudeFormatError(checkEmptyNumber(data?.location?.alt))
          }
          error={altitudeFormatError}
        />
        <ContainerMap
          ref={(ref) => setMapRef(ref)}
          id="project-setting-tower-crane-dialog-map"
        />
        <SizeInputField
          name={t('size-structure')}
          title={t('size-structure')}
          values={data?.size ?? [0, 0, 0]}
          onChange={(index, val) => {
            const newSize = [...(data?.size ?? [])];
            newSize[index] = val;
            handleOnChange([
              {
                propertyName: 'size',
                value: newSize,
              },
            ]);
          }}
        />
        <InputTextArea
          value={data?.remark ?? ''}
          onChange={(e) => {
            handleOnChange([
              {
                propertyName: 'remark',
                value: e.currentTarget.value,
              },
            ]);
          }}
          autoComplete="off"
          title={t('remark')}
          name={t('remark')}
        />
      </Content>
      {openSetPinLocation && (
        <DialogSetPinLocation3D
          onClose={() => setOpenPinLocation(false)}
          open={openSetPinLocation}
          mode="dasconcrete"
          pinLocation={pinLocation}
          setPinLocation={setPinLocation}
          altitude={data?.location?.alt ?? 0}
          setAltitude={(alt) => {
            handleOnChange([
              {
                propertyName: 'location',
                value: {
                  ...data?.location,
                  alt,
                },
              },
            ]);
          }}
        />
      )}
    </Container>
  );
};

export default StructureBasicInformationContent;
