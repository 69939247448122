import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { Height, Layers, LocationOn } from '@mui/icons-material';
import {
  CircularProgress,
  Dialog as MuiDialog,
  IconButton,
  Slider,
  styled,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Group } from 'three';

import {
  Area,
  Building,
  PinLocationMode,
  Project,
  SettingMap,
} from '../../types';

import { getAreasOfProject } from '../../apis/AreaApi';
import {
  forwardGeocoding,
  MapboxGeocodingResponse,
} from '../../apis/MapboxApi';
import { get3dAssets } from '../../apis/ProjectApi';
import { useAppSelector } from '../../hooks';
import useThreeConfig, { ThreeConfig } from '../../hooks/use3DConfig';
import useDebounce from '../../hooks/useDebounce';
import { useLocalStorageObject } from '../../hooks/useLocalStorageObject';
import useMapboxArea from '../../pages/dashboard/hooks/useMapboxArea';
import useMapboxFloorPlan from '../../pages/dashboard/hooks/useMapboxFloorPlan';

import { Subset } from 'web-ifc-three/IFC/components/subsets/SubsetManager';

import searchSvg from '../../assets/images/svg/btn_ic_search.svg';
import AreaIconSvg from '../../assets/SvgIcon/AreaIconSvg';
import CloseMediumSvgIcon from '../../assets/SvgIcon/CloseMediumSvgIcon';
import i18n from '../../i18n';
import { addAreasLayer } from '../../pages/dashboard/Map2d/addLayers';
import DeviceItem from '../../pages/dashboard/ThreeDPlus/DeviceItem';
import { CoordinateConverter } from '../../pages/dashboard/ThreeDPlus/function/CoordinateConverter';
import GLTFAgent from '../../pages/dashboard/ThreeDPlus/function/GLTFAgent';
import IFCAgent from '../../pages/dashboard/ThreeDPlus/function/IFCAgent';
import { PickControl } from '../../pages/dashboard/ThreeDPlus/function/PickControl';
import ThreeManager, {
  WorkerConfig,
} from '../../pages/dashboard/ThreeDPlus/function/ThreeManager';
import LoadingDialog from '../../pages/dashboard/ThreeDPlus/LoadingDialog';
import MenuItemPopper from '../../pages/dashboard/ThreeDPlus/MenuItemPopper';
import { create3DModelLayer } from '../../utils/MapboxUtils';
import {
  isLatitude,
  isLongitude,
  separateLongLat,
} from '../../utils/separateLongLat';
import CheckboxDefault from '../Checkbox/CheckboxDefault';
import IconContainer from '../IconContainer';
import MapSwitch from '../MapSwitch';

const IconContainerStyled = styled(IconContainer)`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  color: ${({ theme }) => theme.color.secondary.$100};
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: ${({ theme }) => theme.color.secondary.$100};
    background-color: #3e3e3e2b;
  }
`;

interface DialogLocationProps {
  isSupport3d: boolean;
}

// prettier-ignore
const Dialog = styled(MuiDialog, {shouldForwardProp: (prop)=> prop !== "isSupport3d"})<DialogLocationProps>`
  & .MuiPaper-root {
    max-width: unset;
    max-height: unset;
    margin: 0;
    display: flex;
    flex-direction: row;
  }
  #pin-location {
    width: ${({isSupport3d})=> isSupport3d ? "50vw" : "100vw"};
    height: 100vh;
    background: #ffffff;
  }

  & .mapboxgl-popup-content {
    font-size: 1rem;
    color: ${({ theme }) => theme.color.secondary.$80};
    text-align: center;
    padding: 30px 37px;
    border-radius: 10px;
  }
`;

const AutoComplete = styled('div')`
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
  width: 200px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  & > .query {
    display: flex;
    align-items: center;
    background: white;
    padding: 4px;
    border-radius: 4px;
    margin-bottom: 10px;
    & > input {
      flex: 1;
      width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      color: ${({ theme }) => theme.color.secondary.$100};
      padding: 0;
      border: 0;
      outline: none;
    }
  }
  & > .autocomponent {
    list-style-type: none;
    background: white;
    padding: 4px 0;
    border-radius: 4px;
    margin: 0px;
    margin-top: 8px;
    & > li {
      cursor: pointer;
      display: flex;
      padding: 6px 4px;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
      & .place {
        & > p {
          font-size: 0.75rem;
          line-height: 1.5em;
          color: #969696;
          margin: 0;
          &:first-of-type {
            font-size: 0.88rem;
            line-height: 1.13rem;
            color: #606060;
          }
        }
      }
    }
  }
`;

const Container3D = styled('div')`
  height: 100vh;
  width: 50vw;
  border-left: 10px solid white;
  color: white;
  position: relative;

  & canvas {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;

const MenuList = styled('div')`
  width: 200px;
  max-height: 290px;
  color: white;
  background: rgba(62, 62, 62, 0.8);
  border-radius: 10px;
  padding: 8px;
  overflow: auto;
  & .checkbox-container {
    display: flex;
  }
`;

const TextDescription = styled(Typography)`
  ${({ theme }) => ({ ...theme.typography.h3 })}
  text-align: center;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`;

const Menu = styled('div')`
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 10px;
  min-width: 40px;
  min-height: 40px;
  transform: translateX(-50%);
  border-radius: 10px;
  background: rgba(62, 62, 62, 0.8);
  backdrop-filter: blur(10px);
  padding: 0 8px;
`;

const MenuListPopper = styled('div')`
  width: 200px;
  max-height: 400px;
  color: white;
  background: rgba(62, 62, 62, 0.8);
  border-radius: 10px;
  padding: 8px;
  overflow: auto;

  & .checkbox-container {
    display: flex;
  }
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContainerInput = styled('div')`
  position: absolute;
  top: 30px;
  left: 356px;
  z-index: 1;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  & > .container-input {
    display: flex;
    align-items: center;
    background: white;
    width: 200px;
    padding: 4px;
    border-radius: 4px;
    margin-left: 10px;
    margin-bottom: 10px;
    & > input {
      flex: 1;
      width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      color: ${({ theme }) => theme.color.secondary.$100};
      padding: 0;
      border: 0;
      outline: none;
    }
  }
`;

type MenuType =
  | 'none'
  | 'layer'
  | 'worker'
  | 'plant'
  | 'locator'
  | 'area'
  | 'aoa-tag'
  | 'cctv'
  | 'daswatch'
  | 'daswater';

interface DialogSetPinLocation3DProps {
  open: boolean;
  onClose: () => void;
  mode: PinLocationMode;
  pinLocation: [number, number];
  setPinLocation: React.Dispatch<React.SetStateAction<[number, number]>>;
  altitude: number;
  setAltitude: (value: number) => void;
}

const DialogSetPinLocation3D: React.FC<DialogSetPinLocation3DProps> = ({
  open,
  onClose,
  mode,
  pinLocation,
  setPinLocation,
  altitude,
  setAltitude,
}) => {
  const { t } = useTranslation();

  const { rotation } = useAppSelector((store) => store.projectPage);

  const { projectFloorPlan } = useAppSelector((store) => store.projects);
  const project = useAppSelector((store) => store.projects.currentProject);
  const [loadModelProgress, setLoadModelProgress] = useState(0);

  const queueRef = useRef<Array<number>>([]);
  const coordinateConverterRef = useRef<CoordinateConverter | null>(null);
  const [queryPosition, setQueryPosition] = useState<{
    x: number;
    y: number;
    z: number;
  }>({ x: 0, y: 0, z: 0 });

  const mapboxRef = useRef<mapboxgl.Map | null>(null);
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  const popupRefArea = useRef<mapboxgl.Popup | null>(null);
  const popUpInfoLatLongRef = useRef<mapboxgl.Popup | null>(null);
  const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null);
  const [containerRef, setContainerMapRef] = useState<HTMLDivElement | null>(
    null,
  );
  const [canvasRef, setCanvasMapRef] = useState<HTMLCanvasElement | null>(null);
  const [currrentbuildingSubsetMenu, setCurrrentbuildingSubsetMenu] =
    useState('');

  const threeManagerRef = useRef<ThreeManager | null>(null);
  const gltfAgentRef = useRef<GLTFAgent | null>(null);
  const ifcAgentRef = useRef<IFCAgent | null>(null);
  const pickControlRef = useRef<PickControl | null>(null);
  const { config: threeConfig, setDisplayLabelToLocalStorage } =
    useThreeConfig();

  const [popupRef, setPopupRef] = useState<HTMLDivElement | null>(null);
  const [placeQuery, setPlaceQuery] = useState('');
  const [queryLatLong, setQueryLatLong] = useState('');
  const [isMapReady, setIsMapReady] = useState<boolean>(false);
  const [isInitFinish, setIsInitFinish] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isCCTVReady, setIsCCTVReady] = useState(false);
  const [isConverterReady, setConverterReady] = useState(false);
  const [searchedList, setSearchedList] = useState<MapboxGeocodingResponse>();
  const [areasDisplay, setAreasDisplay] = useState<{
    [index: string]: boolean;
  }>({});

  const query = useDebounce(placeQuery, 2000);
  const queryLatLongSearch = useDebounce(queryLatLong, 2000);
  const [indexSlider, setIndexSlider] = useState({
    indexFloor: 0,
    indexBuilding: 0,
  });

  const buttonsRef = useRef<{ [index: string]: HTMLElement | null }>({});

  const [ifcBuildingStoreyMaps, setIfcBuildingStoreyMaps] = useState<
    Array<{
      name: string;
      position: {
        x: number;
        y: number;
        z: number;
      };
      storeyMap: {
        [key: string]: Subset;
      };
      gltfMap: {
        [key: string]: Group;
      };
    }>
  >([]);

  const [modelCount, setModelCount] = useState<{
    modelLoaded: number;
    totalModelLoaded: number;
  }>({
    modelLoaded: 0,
    totalModelLoaded: 0,
  });
  const [openLoading, setOpenLoading] = useState(true);
  const [buildingInfo, setBuildingInfo] = useState<{
    type: 'GLTF' | 'IFC' | 'UNSET';
    count: number;
  }>({ type: 'UNSET', count: 0 });
  const [currentOpenMenu, setCurrentOpenMenu] = useState<MenuType>('none');
  const [displayLabel, setDisplayLabel] = useState<{
    area: boolean;
  }>({
    area: threeConfig?.displayLabel.area ?? false,
  });

  const [mapLocal, setMapLocal] = useLocalStorageObject<SettingMap>(
    'mapStyleSetting_',
    project?.id,
  );

  const { data: projectOf3dAssets } = useQuery(
    ['3d-assets', project?.id],
    async () => {
      const res = await get3dAssets((project as Project).id);
      return res.data.data;
    },
    {
      enabled: !!project?.id,
      refetchOnWindowFocus: false,
    },
  );

  const { data: areas } = useQuery(
    [`get-project-areas`, project?.id],
    async () => {
      const localAreas: Area[] = [];
      const load = async (nextCursor?: string) => {
        const res = await getAreasOfProject((project as Project).id as string, {
          nextCursor,
          selectVisible: false,
        });
        res.data.data.forEach((area) => {
          localAreas.push(area);
        });

        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };

      await load();

      return localAreas;
    },
    {
      enabled: !!project?.id,
      refetchOnWindowFocus: false,
    },
  );

  const { data, isLoading } = useQuery(
    ['forwardGeocoding', query],
    async () => {
      const { data: response } = await forwardGeocoding(query, 'zh-Hant');
      return response;
    },
    {
      enabled: query !== '',
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const isSupport3d: boolean = useMemo(() => {
    const assets = projectOf3dAssets?.filter(
      (asset) => !!asset.gltfURL && !!asset.coordinates,
    );
    if (!projectOf3dAssets || assets?.length === 0) {
      return false;
    }
    return true;
  }, [projectOf3dAssets]);

  useEffect(() => {
    if (data) {
      setSearchedList(data);
    }
  }, [data]);

  useEffect(() => {
    const initial = async () => {
      const { default: mapboxgl } = await import(
        /* webpackChunkName: "mapbox-gl" */ 'mapbox-gl'
      );
      const map = new mapboxgl.Map({
        accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN || '',
        container: 'pin-location',
        center: pinLocation,
        zoom: 15,
        style:
          mapLocal && mapLocal.mapStyle === 'satelite'
            ? 'mapbox://styles/mapbox/satellite-streets-v12'
            : 'mapbox://styles/ci-bot-tw/cl7iuaop2000014pbc5d3jgoe',
      });

      mapboxRef.current = map;

      mapboxRef.current.addControl(
        new MapboxLanguage({
          defaultLanguage: i18n.language,
        }),
      );

      markerRef.current = new mapboxgl.Marker({ draggable: true })
        .setLngLat(pinLocation)
        .addTo(mapboxRef.current);

      popUpInfoLatLongRef.current = new mapboxgl.Popup({
        offset: 55,
        closeButton: false,
        closeOnClick: false,
      })
        .setDOMContent(popupRef as HTMLDivElement)
        .setLngLat(pinLocation)
        .addTo(mapboxRef.current);

      mapboxRef.current.on('load', async () => {
        addAreasLayer(map, 'path-pedestrian-label');
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          className: 'tooltip-area',
        });

        popupRefArea.current = popup;

        if (project && project.elevation && projectOf3dAssets) {
          const { layer } = create3DModelLayer(
            project.center,
            project.elevation,
            projectOf3dAssets,
          );
          map.addLayer(layer, 'path-pedestrian-label');
        }

        const onDragEnd = () => {
          const pos = markerRef.current?.getLngLat();
          setPinLocation([pos?.lng ?? 0, pos?.lat ?? 0]);
        };

        markerRef.current?.on('drag', onDragEnd);

        mapboxRef.current?.resize();
        setIsMapReady(true);
      });
    };

    if (mapRef && project && !mapboxRef.current && projectOf3dAssets) {
      setIsMapReady(false);
      initial();
    }

    return () => {
      mapboxRef.current?.remove();
      mapboxRef.current = null;
    };
  }, [mapRef, popupRef, projectOf3dAssets, isSupport3d, mapLocal, project]);

  useMapboxArea(isMapReady, areas, mapboxRef, popupRefArea);

  useMapboxFloorPlan(isMapReady, projectFloorPlan, mapboxRef.current);

  useEffect(() => {
    const init = async () => {
      if (containerRef && canvasRef && project) {
        threeManagerRef.current = new ThreeManager(containerRef, {
          editMode: mode,
          displayLabel: threeConfig?.displayLabel ?? undefined,
        });

        gltfAgentRef.current = new GLTFAgent(
          threeManagerRef.current.getScene(),
        );

        ifcAgentRef.current = new IFCAgent(
          threeManagerRef.current.getScene(),
          threeManagerRef.current.getLoading(),
        );

        threeManagerRef.current.getLoading().onProgress = function (
          _,
          itemsLoaded,
          itemsTotal,
        ) {
          setModelCount({
            modelLoaded: itemsLoaded,
            totalModelLoaded: itemsTotal,
          });
        };

        threeManagerRef.current.getLoading().onError = function () {
          setOpenLoading(false);
        };

        gltfAgentRef.current.onProgress = (progress) => {
          queueRef.current.push(progress);
        };

        ifcAgentRef.current.onProgress = (progress) => {
          queueRef.current.push(progress);
        };

        const pickControl = new PickControl(
          threeManagerRef.current.scene,
          threeManagerRef.current.camera,
          threeManagerRef.current.renderer,
          threeManagerRef.current.controls,
        );

        pickControlRef.current = pickControl;

        pickControlRef.current.onUpdatePosition = (pos) => {
          setQueryPosition(pos);
        };

        setIsInitFinish(true);
      }
    };

    if (isMapReady) {
      init();
    }

    return () => {
      threeManagerRef.current?.dispose();
      threeManagerRef.current = null;
      ifcAgentRef.current?.dispose();
      ifcAgentRef.current = null;
      gltfAgentRef.current?.dispose();
      gltfAgentRef.current = null;
      pickControlRef.current?.dispose();
      pickControlRef.current = null;
      setBuildingInfo({ type: 'UNSET', count: 0 });
      setLoadModelProgress(0);
    };
  }, [project, isMapReady, mode, threeConfig]);

  useEffect(() => {
    let timer: NodeJS.Timer;
    const load = async () => {
      if (
        isInitFinish &&
        project &&
        projectOf3dAssets &&
        threeManagerRef.current &&
        ifcAgentRef.current &&
        gltfAgentRef.current &&
        pickControlRef.current
      ) {
        timer = setInterval(() => {
          const progress = queueRef.current.shift();
          setLoadModelProgress(progress ?? 0);
          if (progress === 100) {
            clearInterval(timer);
          }
        }, 200);

        coordinateConverterRef.current = new CoordinateConverter(
          project.center[1],
          project.center[0],
        );
        setConverterReady(true);

        threeManagerRef.current.setOriginOffset(0, project.elevation ?? 0, 0);

        threeManagerRef.current.init();
        threeManagerRef.current.initEditMesh(mode);
        await threeManagerRef.current.initialCCTV().then((state) => {
          setIsCCTVReady(state);
        });

        ifcAgentRef.current.setOriginOffset(
          threeManagerRef.current.originOffset.x,
          threeManagerRef.current.originOffset.y,
          threeManagerRef.current.originOffset.z,
        );

        gltfAgentRef.current.setOriginOffset(
          threeManagerRef.current.originOffset.x,
          threeManagerRef.current.originOffset.y,
          threeManagerRef.current.originOffset.z,
        );

        await ifcAgentRef.current.init();

        const ifcAssetsFiltered = projectOf3dAssets?.filter(
          (asset) => !!asset.ifcURL && !!asset.coordinates,
        );

        const gltfAssetsFiltered = projectOf3dAssets?.filter(
          (asset) => !!asset.gltfURL && !!asset.coordinates,
        );

        const storeyMaps: Array<{
          name: string;
          storeyMap: {};
          gltfMap: {};
          position: {
            x: number;
            y: number;
            z: number;
          };
        }> = [];

        const loadIfcBuilding = async (ifcAssets: Building[]) => {
          for (let i = 0; i < ifcAssets.length; i++) {
            if (coordinateConverterRef.current && ifcAgentRef.current) {
              const asset = ifcAssets[i];
              const modelPosition =
                coordinateConverterRef.current.geographicToCartesian(
                  asset.coordinates?.lat ?? 0,
                  asset.coordinates?.lon ?? 0,
                );

              const worldPosition = {
                x: modelPosition.x,
                y: asset.coordinates?.alt ?? 0,
                z: modelPosition.z,
              };

              const ifc = await ifcAgentRef.current
                .loadIfc(
                  asset.ifcURL as string,
                  worldPosition,
                  asset?.rotation ?? {
                    heading: 0,
                    pitch: 0,
                    roll: 0,
                  },
                  asset.scale ?? 1,
                )
                .then(({ modelID, centerPosition }) => {
                  return (
                    ifcAgentRef.current as IFCAgent
                  ).getModelOfBuildingStoreySubsets(modelID, centerPosition);
                })
                .then((item) => {
                  return {
                    name: asset.name,
                    position: item.centerPosition,
                    storeyMap: item.subsets.reduce((prev, subset) => {
                      prev[subset.ifcBuildingStoreyName] = subset.subset;
                      return prev;
                    }, {}),
                  };
                });

              storeyMaps.push({
                ...ifc,
                gltfMap: {},
              });
            }
          }
          setIfcBuildingStoreyMaps(storeyMaps);
        };

        const loadGLTFBuilding = async (gltfAssets: Building[]) => {
          for (let j = 0; j < gltfAssets.length; j++) {
            if (coordinateConverterRef.current && gltfAgentRef.current) {
              const asset = gltfAssets[j];

              const modelPosition = (
                coordinateConverterRef.current as CoordinateConverter
              ).geographicToCartesian(
                asset.coordinates?.lat ?? 0,
                asset.coordinates?.lon ?? 0,
              );

              const worldPosition = {
                x: modelPosition.x,
                y: asset.coordinates?.alt ?? 0,
                z: modelPosition.z,
              };

              const gltf = await gltfAgentRef.current.loadGLTF(
                asset.gltfURL as string,
                worldPosition,
                asset?.rotation ?? {
                  heading: 0,
                  pitch: 0,
                  roll: 0,
                },
                asset.scale ?? 1,
                asset.rotationTuneParams,
              );

              let obj: { [key: string]: Group } = {};
              obj.GLTF = gltf.group;
              storeyMaps.push({
                name: asset.name,
                position: gltf.centerPosition,
                storeyMap: {},
                gltfMap: obj,
              });
            }
          }
          setIfcBuildingStoreyMaps(storeyMaps);
        };

        if (ifcAssetsFiltered.length !== 0) {
          setBuildingInfo({ type: 'IFC', count: ifcAssetsFiltered.length });
          loadIfcBuilding(ifcAssetsFiltered);
        }

        if (gltfAssetsFiltered.length !== 0) {
          const gltfLoaded = gltfAssetsFiltered.filter((asset) =>
            ifcAssetsFiltered.every((item) => !item.id.includes(asset.id)),
          );
          if (gltfLoaded.length !== 0) {
            setBuildingInfo({ type: 'GLTF', count: gltfLoaded.length });
            loadGLTFBuilding(gltfLoaded);
          }
        }

        if (gltfAssetsFiltered.length === 0 && ifcAssetsFiltered.length === 0) {
          clearInterval(timer);
          setLoadModelProgress(100);
          setOpenLoading(false);
        }

        setIsReady(true);
      }
    };

    load();

    return () => {
      clearInterval(timer);
      setIsReady(false);
    };
  }, [isInitFinish, project, projectOf3dAssets, mode]);

  useEffect(() => {
    const process = async () => {
      if (
        isReady &&
        coordinateConverterRef.current &&
        threeManagerRef.current &&
        areas
      ) {
        let newAreasDisplay: { [index: string]: boolean } = {};

        const areaPositionInfo = areas
          .filter(
            (area) =>
              !!area.active && area.type !== 'path' && area.type !== 'pipe',
          )
          .flatMap((area) => {
            newAreasDisplay[area.id] = true;
            return area.area.flatMap((polygon) =>
              polygon.map((ring) => ({
                ...area,
                positions: ring.map(([long, lat]) => {
                  const coord = (
                    coordinateConverterRef.current as CoordinateConverter
                  ).geographicToCartesian(lat, long);
                  return [coord.x, area.altitude, coord.z];
                }),
              })),
            );
          });
        setAreasDisplay(newAreasDisplay);

        threeManagerRef.current.setArea(areaPositionInfo);
      }
    };

    process();
  }, [isReady, areas]);

  useEffect(() => {
    if (loadModelProgress == 100) {
      if (buildingInfo.type === 'IFC') {
        const timer = setTimeout(() => {
          if (
            modelCount.modelLoaded === buildingInfo.count &&
            modelCount.totalModelLoaded === buildingInfo.count
          ) {
            setOpenLoading(false);
          }
        }, 500);

        return () => clearTimeout(timer);
      }

      if (buildingInfo.type === 'GLTF') {
        const timer = setTimeout(() => {
          setOpenLoading(false);
        }, 500);

        return () => clearTimeout(timer);
      }
    }
  }, [loadModelProgress, modelCount, buildingInfo]);

  useEffect(() => {
    if (pickControlRef.current && isReady && mode !== 'cctv') {
      pickControlRef.current.setTransformControl();
    }

    let timer: NodeJS.Timeout;
    if (pickControlRef.current && isReady && mode === 'cctv' && isCCTVReady) {
      timer = setTimeout(() => {
        pickControlRef.current?.setTransformControl();
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isReady, mode, isCCTVReady]);

  useEffect(() => {
    const coord = coordinateConverterRef.current?.geographicToCartesian(
      pinLocation[1],
      pinLocation[0],
    );
    const newSetting: WorkerConfig = {
      name: '-',
      dasId: '-',
      position: {
        x: coord?.x ?? 0,
        y: altitude,
        z: coord?.z ?? 0,
      },
      status: 'outdoor',
      rotation: rotation,
    };
    if (threeManagerRef.current && isReady && mode !== 'cctv') {
      threeManagerRef.current.updateEditSetting(newSetting);
    }

    if (threeManagerRef.current && isReady && mode === 'cctv' && isCCTVReady) {
      threeManagerRef.current.updateEditSetting(newSetting);
    }
  }, [pinLocation, rotation, altitude, isReady, isCCTVReady, mode]);

  useEffect(() => {
    if (coordinateConverterRef.current && threeManagerRef.current) {
      const { longitude, latitude } =
        coordinateConverterRef.current.cartesianToGeographic(
          queryPosition.x,
          queryPosition.z,
        );
      setPinLocation([longitude, latitude]);
      const initAlt = queryPosition.y + threeManagerRef.current.originOffset.y;
      const alt = parseFloat(initAlt.toFixed(1));
      setAltitude(alt);
    }
  }, [queryPosition]);

  useEffect(() => {
    markerRef.current?.setLngLat(pinLocation);
    popUpInfoLatLongRef.current?.setLngLat(pinLocation);

    if (isLongitude(pinLocation[0]) && isLatitude(pinLocation[1])) {
      setQueryLatLong(pinLocation.join(', '));
    }
  }, [pinLocation]);

  useEffect(() => {
    if (
      isConverterReady &&
      threeManagerRef.current &&
      coordinateConverterRef.current &&
      pinLocation.every((val) => val !== 0) &&
      altitude !== 0
    ) {
      const { x, z } = coordinateConverterRef.current.geographicToCartesian(
        pinLocation[1],
        pinLocation[0],
      );

      threeManagerRef.current.flyTo(x, altitude, z);
    }
  }, [isConverterReady]);

  useEffect(() => {
    if (isConverterReady && markerRef.current) {
      const onDragEnd = () => {
        const pos = markerRef.current?.getLngLat();

        if (threeManagerRef.current && coordinateConverterRef.current && pos) {
          const { x, z } = coordinateConverterRef.current.geographicToCartesian(
            pos.lat,
            pos.lng,
          );
          threeManagerRef.current.flyTo(x, altitude, z);
        }
      };
      markerRef.current.on('dragend', onDragEnd);
    }
  }, [isConverterReady, altitude]);

  const handleOnSelectPlace = (center: [number, number]) => {
    setPinLocation(center);
    mapboxRef.current?.setCenter(center);
  };

  useEffect(() => {
    const value = String(queryLatLongSearch);
    const { lon, lat } = separateLongLat(value);
    if (isLongitude(lon) && isLatitude(lat)) {
      setPinLocation([lon, lat]);
    }
  }, [queryLatLongSearch]);

  const ifcBuildingStoreyMenu = ifcBuildingStoreyMaps.map((obj, index) => {
    const key = `building-${obj.name}-${index}`;

    const marks: { value: number; label: string }[] = [
      { value: 0, label: t('all') },
    ];

    Object.entries(obj.storeyMap).forEach(
      ([ifcBuildingStoreyName, subset], i) => {
        if (indexSlider.indexBuilding === index) {
          if (indexSlider.indexFloor === 0) {
            subset.visible = true;
          } else {
            if (indexSlider.indexFloor === i + 1) {
              subset.visible = true;
            } else {
              subset.visible = false;
            }
          }
        }
        marks.push({ label: ifcBuildingStoreyName, value: i + 1 });
      },
    );

    return (
      <Fragment key={key}>
        <DeviceItem
          name={obj.name}
          ref={(dom) => (buttonsRef.current[key] = dom)}
          onClick={() => {
            setCurrrentbuildingSubsetMenu((current) => {
              if (current === key) {
                return '';
              }
              threeManagerRef.current?.flyTo(
                obj.position.x,
                obj.position.y,
                obj.position.z,
              );
              return key;
            });

            setIndexSlider({ indexBuilding: index, indexFloor: 0 });
          }}
        />
        <MenuItemPopper
          open={currrentbuildingSubsetMenu === key}
          anchorEl={buttonsRef.current[key]}
          placement="right-end"
          sx={{
            zIndex: '2000',
          }}
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [8, 10],
                },
              },
            ],
          }}
        >
          <MenuListPopper>
            {marks.length === 0 ? (
              <DeviceItem name={t('no-data')} disabled />
            ) : (
              <>
                <Slider
                  aria-label="Always visible"
                  defaultValue={0}
                  onChange={(e, value) => {
                    setIndexSlider({
                      indexFloor: value as number,
                      indexBuilding: index,
                    });
                  }}
                  step={1}
                  max={marks.length - 1}
                  marks={marks}
                  orientation="vertical"
                  sx={{
                    margin: '15px 0',
                    height: `${marks.length * 20}px`,
                    '& > .MuiSlider-markLabel': {
                      color: 'white',
                    },
                    '& > .MuiSlider-track': {
                      width: 'none',
                      border: 'none',
                      backgroundColor: 'transparent',
                    },
                  }}
                />
              </>
            )}
          </MenuListPopper>
        </MenuItemPopper>
      </Fragment>
    );
  });

  const areaItems = useMemo(() => {
    if (
      areas === undefined ||
      (areas.length === 0 && !isReady && Object.values(areasDisplay).length > 0)
    ) {
      return [
        <DeviceItem
          key={`area-item-empty-data`}
          name={t('no-working.area')}
          disabled
        />,
      ];
    }

    return areas
      .filter((area) => !!area.active && !!area.visible)
      .filter((area) => area.type !== 'path' && area.type !== 'pipe')
      .map((area) => {
        const obj = threeManagerRef.current?.getAnObject(area.id);

        return (
          <CheckboxDefault
            key={`checkbox-${area.id}`}
            label={area.name}
            checked={areasDisplay[area.id]}
            value="display-area-label"
            onChange={(val, checked) => {
              const newAreaDisplay: { [index: string]: boolean } = {
                ...areasDisplay,
              };
              newAreaDisplay[area.id] = checked;
              if (obj) {
                obj.visible = checked;
              }
              setAreasDisplay(newAreaDisplay);
            }}
          />
        );
      });
  }, [areas, isReady, areasDisplay]);

  const handleOpenMenu = (type: MenuType) => {
    if (currentOpenMenu === type) {
      setCurrentOpenMenu('none');
    } else {
      setCurrentOpenMenu(type);
    }
  };

  const handleDisplayLabel = (type: keyof ThreeConfig['displayLabel']) => {
    const current = displayLabel[type];
    setDisplayLabelToLocalStorage(type, !current);
    setDisplayLabel({ ...displayLabel, [type]: !current });
    threeManagerRef.current?.displayLabel(!current, type);
  };

  return (
    <Dialog className="PinLocationDialog" open={open} isSupport3d={isSupport3d}>
      <AutoComplete>
        <div className="query">
          <IconContainer size={32}>
            <img src={searchSvg} alt="search" />
          </IconContainer>
          <input
            onChange={(e) => setPlaceQuery(e.currentTarget.value)}
            placeholder={t('place-name')}
          />
          {isLoading && <CircularProgress color="primary" size="1rem" />}
        </div>

        {searchedList && (
          <ul className="autocomponent">
            {searchedList?.features.map((feature) => {
              return (
                <li
                  key={`query-reuslt-${feature.id}`}
                  onClick={() => {
                    handleOnSelectPlace(feature.center);
                    setSearchedList(undefined);
                  }}
                >
                  <IconContainer size={32}>
                    <img src={searchSvg} alt="search" />
                  </IconContainer>
                  <div className="place">
                    <Typography>{feature.text}</Typography>
                    <Typography>{feature.place_name}</Typography>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </AutoComplete>
      <ContainerInput>
        <div className="container-input">
          <IconContainer size={32}>
            <LocationOn />
          </IconContainer>
          <input
            value={queryLatLong}
            onChange={(e) => setQueryLatLong(e.currentTarget.value)}
            placeholder={t('long-lat')}
          />
        </div>
        <div className="container-input">
          <IconContainer size={32}>
            <Height />
          </IconContainer>
          <input
            type="number"
            onChange={(e) => {
              setAltitude(Number(e.currentTarget.value));
            }}
            placeholder={t('altitude')}
            value={altitude}
            name="altitude"
          />
          <label htmlFor="altitude">m</label>
        </div>
      </ContainerInput>
      <div ref={(ref) => setMapRef(ref)} id="pin-location" />
      <MapSwitch
        mapLocal={mapLocal}
        setMapLocal={setMapLocal}
        sx={{
          position: 'absolute',
          margin: '10px',
          top: '20px',
          left: '230px',
          zIndex: '5',
        }}
      />
      {isSupport3d && (
        <Container3D
          ref={(ref) => {
            setContainerMapRef(ref);
          }}
        >
          <TextDescription sx={{ bottom: '60px' }}>
            {t('pin-location-3d-plus.control-description')}
          </TextDescription>
          <Menu>
            <IconButton
              color="primary"
              onClick={handleOpenMenu.bind(null, 'layer')}
              ref={(ref) => {
                buttonsRef.current.layer = ref;
              }}
            >
              <Layers />
            </IconButton>
            {buttonsRef.current && (
              <MenuItemPopper
                open={currentOpenMenu === 'layer'}
                placement="top-start"
                anchorEl={buttonsRef.current.layer}
                sx={{
                  zIndex: '2000',
                }}
              >
                <MenuList>
                  {ifcBuildingStoreyMenu.length === 0
                    ? t('no-working.building')
                    : ifcBuildingStoreyMenu}
                </MenuList>
              </MenuItemPopper>
            )}
            <IconButton
              ref={(ref) => {
                buttonsRef.current.area = ref;
              }}
              color="primary"
              onClick={handleOpenMenu.bind(null, 'area')}
            >
              <AreaIconSvg />
            </IconButton>
            {buttonsRef.current.area && (
              <MenuItemPopper
                open={currentOpenMenu === 'area'}
                anchorEl={buttonsRef.current.area}
                placement="top-start"
                sx={{
                  zIndex: '2000',
                }}
              >
                <MenuList>
                  {areaItems}
                  <CheckboxDefault
                    label={t('display-area-name')}
                    checked={displayLabel.area}
                    value="display-area-label"
                    onChange={handleDisplayLabel.bind(null, 'area')}
                  />
                </MenuList>
              </MenuItemPopper>
            )}
          </Menu>

          <canvas ref={(ref) => setCanvasMapRef(ref)} />
          <LoadingDialog open={openLoading} progress={loadModelProgress} />
        </Container3D>
      )}

      <Content ref={(ref) => setPopupRef(ref)}>
        <h3>{t('project:dialog.pinLocationDialog.title')}</h3>
        {pinLocation[0]}, {pinLocation[1]}
        <DialogButton
          color="primary"
          variant="contained"
          onClick={onClose}
          data-cy="btn-pin-location-submit"
        >
          {t('project:dialog.pinLocationDialog.submit')}
        </DialogButton>
      </Content>
      <IconContainerStyled onClick={onClose}>
        <CloseMediumSvgIcon sx={{ width: '32px', height: '32px' }} />
      </IconContainerStyled>
    </Dialog>
  );
};

export default DialogSetPinLocation3D;
