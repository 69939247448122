import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { Avatar as MuiAvatar, styled, Typography } from '@mui/material';
import { format } from 'date-fns';

import { Plant } from '../../../../types/Resource';

import { useAppSelector } from '../../../../hooks';

import TowerCraneDefault from '../../../../assets/images/default-image/tower_crane_default.png';
import i18n from '../../../../i18n';

const Container = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
`;
const ContainerContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.color.box_bbg};
  padding: 10px;
`;

const ContentTitle = styled(Typography)`
  color: ${({ theme }) => theme.color.box_bbg};
`;

const ContainerImage = styled('div')`
  margin: 0 auto;
  width: 205px;
  position: relative;
`;

const Avatar = styled(MuiAvatar)`
  width: 205px;
  height: 205px;
  object-fit: cover;
  border-radius: 4px;
  margin: 0 auto;
`;

const ContainerDetail = styled('div')`
  display: flex;
  flex: 1;
`;

const Row = styled('div')`
  width: 50%;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.secondary.$60,
  height: '16px',
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.color.box_bbg,
  height: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Item = styled('div')`
  margin-top: 2px;
  height: 42px;
  width: 140px;
`;

const ItemRemark = styled('div')`
  margin-top: 8px;
  height: 42px;
  width: 300px;
`;

const ContainerRemark = styled('div')`
  height: 70px;
  margin-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.color.secondary.$80};
`;

const OrgStatus = styled('div')`
  width: 6px;
  height: 14px;
  border-radius: 3px;
`;

const ContainerMap = styled('div')`
  height: 140px;
  margin-top: 10px;
  border-radius: 4px;
`;

interface BasicInformationContentViewProps {
  data: Plant | undefined;
}

const BasicInformationContentView: React.VFC<
  BasicInformationContentViewProps
> = ({ data }) => {
  const { t } = useTranslation('project-setting');
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const org = useMemo(() => {
    return orgMap[data?.orgId ?? ''];
  }, [data, orgMap]);

  const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null);
  const mapboxRef = useRef<mapboxgl.Map | null>(null);
  const markerRef = useRef<mapboxgl.Marker | null>(null);

  useEffect(() => {
    const initial = async () => {
      const { default: mapboxgl } = await import(
        /* webpackChunkName: "mapbox-gl" */ 'mapbox-gl'
      );

      mapboxRef.current = new mapboxgl.Map({
        accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN || '',
        container: 'project-setting-crane-view',
        interactive: false,
        zoom: 15,
        style: 'mapbox://styles/ci-bot-tw/cl7iuaop2000014pbc5d3jgoe',
        center: [data?.location?.lon ?? 0, data?.location?.lat ?? 0],
      });

      mapboxRef.current.addControl(
        new MapboxLanguage({
          defaultLanguage: i18n.language,
        }),
      );

      markerRef.current = new mapboxgl.Marker()
        .setLngLat([data?.location?.lon ?? 0, data?.location?.lat ?? 0])
        .addTo(mapboxRef.current);
    };

    if (mapRef) {
      if (!mapboxRef.current) {
        initial();
      }
    }

    return () => {
      mapboxRef.current?.remove();
      mapboxRef.current = null;
    };
  }, [mapRef, data]);

  return (
    <Container>
      <ContentTitle>
        {t('page.plant-management.dialog.create-plant.basic-information.title')}
      </ContentTitle>
      <ContainerContent>
        <ContainerImage>
          <Avatar
            src={data?.imageURL ?? ''}
            alt="worker photo"
            sx={{ marginBottom: '10px' }}
            variant="square"
          >
            <Avatar src={TowerCraneDefault} />
          </Avatar>
        </ContainerImage>
        <ContainerDetail>
          <Row>
            <Item>
              <Title>{t('organization')}</Title>
              <div
                style={{
                  display: 'flex',
                  height: '24px',
                  alignItems: 'center',
                }}
              >
                <OrgStatus sx={{ backgroundColor: org?.color ?? '#58B99E' }} />
                <Text title={org?.name} sx={{ marginLeft: '4px' }}>
                  {org?.name ?? 'N/A'}
                </Text>
              </div>
            </Item>

            <Item>
              <Title>{t('model')}</Title>
              <Text title={data?.model}>{data?.model ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.permit-number',
                )}
              </Title>
              <Text title={data?.permitNumber}>
                {data?.permitNumber ?? 'N/A'}
              </Text>
            </Item>
            <Item>
              <Title
                title={data?.safetyLoad ? data.safetyLoad.toString() : 'N/A'}
              >
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.safety-load',
                )}
              </Title>
              <Text>{data?.safetyLoad ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title title={'brand'}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.brand',
                )}
              </Title>
              <Text title={data?.brand ?? 'N/A'}>{data?.brand ?? 'N/A'}</Text>
            </Item>
          </Row>
          <Row>
            <Item>
              <Title title={data?.name}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.name',
                )}
              </Title>
              <Text>{data?.name ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title title={data?.serialNumber}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.serial-number',
                )}
              </Title>
              <Text>{data?.serialNumber ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title title={data?.permitIssuer ?? 'N/A'}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.permit-issued',
                )}
              </Title>
              <Text>{data?.permitIssuer ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title title={data?.yearOfProduction?.toString() ?? 'N/A'}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.year-of-production',
                )}
              </Title>
              <Text>{data?.yearOfProduction ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title title={'expiryDate'}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.expiry-date',
                )}
              </Title>
              <Text>
                {data?.permitExpiryDate
                  ? format(new Date(data.permitExpiryDate), 'yyyy-MM-dd')
                  : 'N/A'}
              </Text>
            </Item>
          </Row>
        </ContainerDetail>
        <div>
          <Title>{t('location')}</Title>
          <Text
            title={
              data?.location
                ? `${data.location.lon}, ${data.location.lat}, ${
                    data.location.alt ? data.location.alt : 'N/A'
                  }`
                : 'N/A'
            }
          >
            {data?.location
              ? `${data.location.lon}, ${data.location.lat}, ${
                  data.location.alt ? data.location.alt : 'N/A'
                }`
              : 'N/A'}
          </Text>
          <ContainerMap
            ref={(ref) => setMapRef(ref)}
            id="project-setting-crane-view"
          />
        </div>
        <ContainerRemark>
          <ItemRemark>
            <Title>{t('remark')}</Title>
            <Text title={data?.remark ? data?.remark : ''}>
              {data?.remark ?? 'N/A'}
            </Text>
          </ItemRemark>
        </ContainerRemark>
      </ContainerContent>
    </Container>
  );
};

export default BasicInformationContentView;
