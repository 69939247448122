import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface SOSSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const SOSSvgIcon = ({ disabled, ...props }: SOSSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <path
      d="M13.9369 21.9169C13.9369 22.4441 13.8015 22.9446 13.5305 23.4186C13.2595 23.8881 12.8372 24.2691 12.2637 24.5615C11.6946 24.8538 10.9585 25 10.0552 25C9.60361 25 9.20618 24.9801 8.86295 24.9402C8.52423 24.9003 8.20809 24.8361 7.91454 24.7475C7.62098 24.6545 7.31614 24.5349 7 24.3887V22.0498C7.53743 22.3156 8.07938 22.5194 8.62585 22.6611C9.17231 22.7984 9.66684 22.8671 10.1094 22.8671C10.3759 22.8671 10.5927 22.8361 10.7598 22.7741C10.9314 22.7121 11.0578 22.6279 11.1391 22.5216C11.2204 22.4109 11.2611 22.2846 11.2611 22.1429C11.2611 21.9745 11.2001 21.8328 11.0782 21.7176C10.9607 21.598 10.7711 21.474 10.5091 21.3455C10.2472 21.2171 9.90168 21.0576 9.47264 20.8671C9.10683 20.6988 8.77488 20.526 8.47681 20.3488C8.18326 20.1716 7.93035 19.9701 7.71808 19.7442C7.51034 19.5138 7.35001 19.2458 7.2371 18.9402C7.1242 18.6346 7.06774 18.2735 7.06774 17.8571C7.06774 17.2326 7.22355 16.7099 7.53517 16.289C7.85131 15.8638 8.28939 15.5449 8.8494 15.3322C9.41393 15.1196 10.0643 15.0133 10.8004 15.0133C11.4462 15.0133 12.0266 15.0842 12.5414 15.2259C13.0608 15.3677 13.526 15.5293 13.9369 15.711L13.1172 17.7375C12.6927 17.5471 12.275 17.3965 11.864 17.2857C11.453 17.1705 11.0714 17.113 10.7191 17.113C10.4888 17.113 10.2991 17.1417 10.1501 17.1993C10.0056 17.2525 9.89717 17.3256 9.82491 17.4186C9.75717 17.5116 9.72329 17.6179 9.72329 17.7375C9.72329 17.8881 9.782 18.0233 9.89943 18.1429C10.0214 18.2625 10.2223 18.3931 10.5023 18.5349C10.7869 18.6722 11.173 18.8516 11.6608 19.0731C12.144 19.2857 12.555 19.5161 12.8937 19.7641C13.2324 20.0078 13.4898 20.3023 13.666 20.6478C13.8466 20.9889 13.9369 21.412 13.9369 21.9169Z"
      fill="currentColor"
    />
    <path
      d="M24.7353 19.9967C24.7353 20.7453 24.6427 21.4275 24.4575 22.0432C24.2769 22.6545 23.9924 23.1816 23.604 23.6246C23.2156 24.0676 22.7165 24.4086 22.1068 24.6478C21.4971 24.8826 20.7678 25 19.9187 25C19.0877 25 18.3674 24.8826 17.7577 24.6478C17.1525 24.4086 16.6535 24.0698 16.2606 23.6312C15.8676 23.1883 15.5763 22.6589 15.3867 22.0432C15.197 21.4275 15.1021 20.7409 15.1021 19.9834C15.1021 18.9734 15.2715 18.0963 15.6102 17.3522C15.9534 16.6035 16.4818 16.0255 17.1954 15.6179C17.909 15.206 18.8213 15 19.9323 15C21.0613 15 21.9781 15.206 22.6826 15.6179C23.3917 16.0299 23.9111 16.6102 24.2408 17.3588C24.5704 18.1074 24.7353 18.9867 24.7353 19.9967ZM17.9203 19.9967C17.9203 20.5814 17.988 21.0842 18.1235 21.505C18.259 21.9258 18.4735 22.2492 18.7671 22.4751C19.0606 22.701 19.4445 22.814 19.9187 22.814C20.411 22.814 20.8016 22.701 21.0907 22.4751C21.3842 22.2492 21.5942 21.9258 21.7207 21.505C21.8517 21.0842 21.9171 20.5814 21.9171 19.9967C21.9171 19.1196 21.7681 18.4264 21.47 17.9169C21.172 17.4075 20.6594 17.1528 19.9323 17.1528C19.449 17.1528 19.0584 17.268 18.7603 17.4983C18.4667 17.7287 18.2522 18.0565 18.1167 18.4817C17.9858 18.907 17.9203 19.412 17.9203 19.9967Z"
      fill="currentColor"
    />
    <path
      d="M33 21.9169C33 22.4441 32.8645 22.9446 32.5935 23.4186C32.3226 23.8881 31.9003 24.2691 31.3267 24.5615C30.7577 24.8538 30.0215 25 29.1183 25C28.6667 25 28.2692 24.9801 27.926 24.9402C27.5873 24.9003 27.2711 24.8361 26.9776 24.7475C26.684 24.6545 26.3792 24.5349 26.0631 24.3887V22.0498C26.6005 22.3156 27.1424 22.5194 27.6889 22.6611C28.2354 22.7984 28.7299 22.8671 29.1725 22.8671C29.4389 22.8671 29.6557 22.8361 29.8228 22.7741C29.9944 22.7121 30.1209 22.6279 30.2022 22.5216C30.2835 22.4109 30.3241 22.2846 30.3241 22.1429C30.3241 21.9745 30.2632 21.8328 30.1412 21.7176C30.0238 21.598 29.8341 21.474 29.5722 21.3455C29.3102 21.2171 28.9647 21.0576 28.5357 20.8671C28.1699 20.6988 27.8379 20.526 27.5399 20.3488C27.2463 20.1716 26.9934 19.9701 26.7811 19.7442C26.5734 19.5138 26.4131 19.2458 26.3002 18.9402C26.1873 18.6346 26.1308 18.2735 26.1308 17.8571C26.1308 17.2326 26.2866 16.7099 26.5982 16.289C26.9144 15.8638 27.3524 15.5449 27.9125 15.3322C28.477 15.1196 29.1273 15.0133 29.8635 15.0133C30.5093 15.0133 31.0896 15.0842 31.6045 15.2259C32.1238 15.3677 32.589 15.5293 33 15.711L32.1803 17.7375C31.7558 17.5471 31.338 17.3965 30.927 17.2857C30.5161 17.1705 30.1344 17.113 29.7822 17.113C29.5518 17.113 29.3622 17.1417 29.2131 17.1993C29.0686 17.2525 28.9602 17.3256 28.888 17.4186C28.8202 17.5116 28.7863 17.6179 28.7863 17.7375C28.7863 17.8881 28.8451 18.0233 28.9625 18.1429C29.0844 18.2625 29.2854 18.3931 29.5654 18.5349C29.8499 18.6722 30.2361 18.8516 30.7238 19.0731C31.2071 19.2857 31.618 19.5161 31.9567 19.7641C32.2955 20.0078 32.5529 20.3023 32.729 20.6478C32.9097 20.9889 33 21.412 33 21.9169Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default SOSSvgIcon;
