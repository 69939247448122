import * as turf from '@turf/turf';
import {
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  Position,
} from 'geojson';
import mapboxgl, {
  CustomLayerInterface,
  Map,
  MercatorCoordinate,
} from 'mapbox-gl';
import {
  AmbientLight,
  Box3,
  Camera,
  Matrix4,
  Mesh,
  MeshLambertMaterial,
  Scene,
  Vector3,
  WebGLRenderer,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import {
  AreaMultiPolygon,
  CoordinatesSource,
  Model,
  Path,
  Track,
  TrackNum,
} from '../types';

import dasAOAPinPng from '../assets/images/png/dashboard/AOAPinPng.png';
import dasBeaconPinPng from '../assets/images/png/dashboard/beaconPinPng.png';
import dascasPingPng from '../assets/images/png/dashboard/dascas_pin.png';
import dascasSdfPng from '../assets/images/png/dashboard/dascas_sdf.png';
import dascasGIndoorPinPng from '../assets/images/png/dashboard/dascas-g_indoor_pin.png';
import dascasGOfflinePinPng from '../assets/images/png/dashboard/dascas-g_offline_pin.png';
import dascasGOutdoorPinPng from '../assets/images/png/dashboard/dascas-g_outdoor_pin.png';
import dascasGSdfPng from '../assets/images/png/dashboard/dascas-g_sdf.png';
import dasgasAlertPinPng from '../assets/images/png/dashboard/dasgas_alert_pin.png';
import dasgasIndoorPinPng from '../assets/images/png/dashboard/dasgas_indoor_pin.png';
import dasgasOfflinePinPng from '../assets/images/png/dashboard/dasgas_offline_pin.png';
import dasgasOutdoorPinPng from '../assets/images/png/dashboard/dasgas_outdoor_pin.png';
import daslockAlertPng from '../assets/images/png/dashboard/daslock_alert.png';
import daslockIndoorPng from '../assets/images/png/dashboard/daslock_indoor.png';
import daslockOfflinePng from '../assets/images/png/dashboard/daslock_offline.png';
import daslockOutdoorPng from '../assets/images/png/dashboard/daslock_outdoor.png';
import dasloopAlertPinPng from '../assets/images/png/dashboard/dasloop_alert_pin.png';
import dasloopIndoorPinPng from '../assets/images/png/dashboard/dasloop_indoor_pin.png';
import dasloopLiveCallAlertPinPng from '../assets/images/png/dashboard/dasloop_live-call_alert_pin.png';
import dasloopLiveCallIndoorPinPng from '../assets/images/png/dashboard/dasloop_live-call_indoor_pin.png';
import dasloopLiveCallOfflinePinPng from '../assets/images/png/dashboard/dasloop_live-call_offline_pin.png';
import dasloopLiveCallOutdoorPinPng from '../assets/images/png/dashboard/dasloop_live-call_outdoor_pin.png';
import dasloopOfflinePinPng from '../assets/images/png/dashboard/dasloop_offline_pin.png';
import dasloopOutdoorPinPng from '../assets/images/png/dashboard/dasloop_outdoor_pin.png';
import daspowerAlertPng from '../assets/images/png/dashboard/daspower_alert.png';
import daspowerIndoorPng from '../assets/images/png/dashboard/daspower_indoor.png';
import daspowerOfflinePng from '../assets/images/png/dashboard/daspower_offline.png';
import daspowerOutdoorPng from '../assets/images/png/dashboard/daspower_outdoor.png';
import dastempAlertPng from '../assets/images/png/dashboard/dastemp_alert.png';
import dastempIndoorPng from '../assets/images/png/dashboard/dastemp_indoor.png';
import dastempOfflinePng from '../assets/images/png/dashboard/dastemp_offline.png';
import dastempOutdoorPng from '../assets/images/png/dashboard/dastemp_outdoor.png';
import dastrackAlertPinPng from '../assets/images/png/dashboard/dastrack_alert_pin.png';
import dastrackIndoorPinPng from '../assets/images/png/dashboard/dastrack_indoor_pin.png';
import dastrackOfflinePinPng from '../assets/images/png/dashboard/dastrack_offline_pin.png';
import dastrackOutdoorPinPng from '../assets/images/png/dashboard/dastrack_outdoor_pin.png';
import dastrackVAlertPinPng from '../assets/images/png/dashboard/dastrack-v_alert_pin.png';
import dastrackVIndoorPinPng from '../assets/images/png/dashboard/dastrack-v_indoor_pin.png';
import dastrackVOfflinePinPng from '../assets/images/png/dashboard/dastrack-v_offline_pin.png';
import dastrackVOutdoorPinPng from '../assets/images/png/dashboard/dastrack-v_outdoor_pin.png';
import daswatchAlertPinPng from '../assets/images/png/dashboard/daswatch_alert_pin.png';
import daswatchIndoorPinPng from '../assets/images/png/dashboard/daswatch_indoor_pin.png';
import daswatchOfflinePinPng from '../assets/images/png/dashboard/daswatch_offline_pin.png';
import daswatchOutdoorPinPng from '../assets/images/png/dashboard/daswatch_outdoor_pin.png';
import daswaterAlertPinPng from '../assets/images/png/dashboard/daswater_alert_pin.png';
import daswaterIndoorPinPng from '../assets/images/png/dashboard/daswater_indoor_pin.png';
import daswaterOfflinePinPng from '../assets/images/png/dashboard/daswater_offline_pin.png';
import daswaterOutdoorPinPng from '../assets/images/png/dashboard/daswater_outdoor_pin.png';
import directionPin from '../assets/images/png/dashboard/direction_pin.png';
import endPointPng from '../assets/images/png/dashboard/end_point.png';
import middlePointPng from '../assets/images/png/dashboard/idle_point.png';
import rotatePng from '../assets/images/png/dashboard/rotate.png';
import scalePng from '../assets/images/png/dashboard/scale.png';
import startPointPng from '../assets/images/png/dashboard/start_point.png';
import dasAoaTagAlertPinPng from '../assets/images/png/dashboard/tag_alert_pin.png';
import dasAoaTagIndoorPinPng from '../assets/images/png/dashboard/tag_indoor_pin.png';
import dasAoaTagOfflinePinPng from '../assets/images/png/dashboard/tag_offline_pin.png';
import dasAoaTagOutdoorPinPng from '../assets/images/png/dashboard/tag_outdoor_pin.png';
import { CoordinateConverter } from '../pages/dashboard/ThreeDPlus/function/CoordinateConverter';
import { MeshoptDecoder } from '../utils/3d/meshopt_decoder.module';

export const imageDasloopTuples = [
  'dasloop-outdoor-pin',
  dasloopOutdoorPinPng,
  'dasloop-indoor-pin',
  dasloopIndoorPinPng,
  'dasloop-offline-pin',
  dasloopOfflinePinPng,
  'dasloop-alert-pin',
  dasloopAlertPinPng,
  'dasloop-outdoor-pin-livecall',
  dasloopLiveCallOutdoorPinPng,
  'dasloop-indoor-pin-livecall',
  dasloopLiveCallIndoorPinPng,
  'dasloop-offline-pin-livecall',
  dasloopLiveCallOfflinePinPng,
  'dasloop-alert-pin-livecall',
  dasloopLiveCallAlertPinPng,
];

export const imageDastrackTuples = [
  'dastrack-outdoor-pin',
  dastrackOutdoorPinPng,
  'dastrack-indoor-pin',
  dastrackIndoorPinPng,
  'dastrack-offline-pin',
  dastrackOfflinePinPng,
  'dastrack-alert-pin',
  dastrackAlertPinPng,
];

export const imageDasAoaTagTuples = [
  'dasAoaTag-outdoor-pin',
  dasAoaTagOutdoorPinPng,
  'dasAoaTag-indoor-pin',
  dasAoaTagIndoorPinPng,
  'dasAoaTag-offline-pin',
  dasAoaTagOfflinePinPng,
  'dasAoaTag-alert-pin',
  dasAoaTagAlertPinPng,
];

export const imageDaswatchTuples = [
  'daswatch-outdoor-pin',
  daswatchOutdoorPinPng,
  'daswatch-indoor-pin',
  daswatchIndoorPinPng,
  'daswatch-offline-pin',
  daswatchOfflinePinPng,
  'daswatch-alert-pin',
  daswatchAlertPinPng,
];

export const imageDastrackVTuples = [
  'dastrack-v-outdoor-pin',
  dastrackVOutdoorPinPng,
  'dastrack-v-indoor-pin',
  dastrackVIndoorPinPng,
  'dastrack-v-offline-pin',
  dastrackVOfflinePinPng,
  'dastrack-v-alert-pin',
  dastrackVAlertPinPng,
];

export const imageDasgasTuples = [
  'dasgas-outdoor-pin',
  dasgasOutdoorPinPng,
  'dasgas-indoor-pin',
  dasgasIndoorPinPng,
  'dasgas-offline-pin',
  dasgasOfflinePinPng,
  'dasgas-alert-pin',
  dasgasAlertPinPng,
];

export const imageDastempTuples = [
  'dastemp-outdoor',
  dastempOutdoorPng,
  'dastemp-indoor',
  dastempIndoorPng,
  'dastemp-offline',
  dastempOfflinePng,
  'dastemp-alert',
  dastempAlertPng,
];

export const imageDaslockTuples = [
  'daslock-outdoor',
  daslockOutdoorPng,
  'daslock-indoor',
  daslockIndoorPng,
  'daslock-offline',
  daslockOfflinePng,
  'daslock-alert',
  daslockAlertPng,
];

export const imageDascasTuples = [
  'dascas-pin',
  dascasPingPng,
  'dascas_g-outdoor-pin',
  dascasGOutdoorPinPng,
  'dascas_g-indoor-pin',
  dascasGIndoorPinPng,
  'dascas_g-offline-pin',
  dascasGOfflinePinPng,
];

export const imageDascasSdfTuples = [
  'dascas-border',
  dascasSdfPng,
  'dascas_g-border',
  dascasGSdfPng,
];

export const imagePointsTuples = [
  'start-point',
  startPointPng,
  'middle-point',
  middlePointPng,
  'end-point',
  endPointPng,
];

export const imageDaswaterTuples = [
  'daswater-outdoor-pin',
  daswaterOutdoorPinPng,
  'daswater-indoor-pin',
  daswaterIndoorPinPng,
  'daswater-offline-pin',
  daswaterOfflinePinPng,
  'daswater-alert-pin',
  daswaterAlertPinPng,
];

export const imageDaspowerTuples = [
  'daspower-outdoor',
  daspowerOutdoorPng,
  'daspower-indoor',
  daspowerIndoorPng,
  'daspower-offline',
  daspowerOfflinePng,
  'daspower-alert',
  daspowerAlertPng,
];

const imageTuples = [
  'dasAOA-pin',
  dasAOAPinPng,
  'direction-pin',
  directionPin,
  'dasBeacon-pin',
  dasBeaconPinPng,
]
  .concat(imageDasloopTuples)
  .concat(imageDastrackTuples)
  .concat(imageDasAoaTagTuples)
  .concat(imageDaswatchTuples);

const imageScaleRotateTuples = ['rotate', rotatePng, 'scale', scalePng];

/**
 * @deprecated this func will be replaced by loadImageSets()
 */
export const loadImages = async (map: mapboxgl.Map) => {
  const load = async (index: number) => {
    return new Promise<HTMLImageElement | ImageBitmap | undefined>(
      (resolve, rejects) => {
        map.loadImage(imageTuples[index + 1], (error, image) => {
          if (error) {
            rejects(error);
          }
          resolve(image);
        });
      },
    );
  };

  for (let i = 0; i < imageTuples.length; i = i + 2) {
    try {
      const image = await load(i);
      map.addImage(imageTuples[i], image as HTMLImageElement | ImageBitmap);
    } catch (error) {
      console.error(error);
    }
  }
};

/**
 * @deprecated this func will be replaced by loadImageSets()
 */
export const loadImagesScaleRotate = async (map: mapboxgl.Map) => {
  const load = async (index: number) => {
    return new Promise<HTMLImageElement | ImageBitmap | undefined>(
      (resolve, rejects) => {
        map.loadImage(imageScaleRotateTuples[index + 1], (error, image) => {
          if (error) {
            rejects(error);
          }
          resolve(image);
        });
      },
    );
  };

  for (let i = 0; i < imageScaleRotateTuples.length; i = i + 2) {
    try {
      const image = await load(i);
      map.addImage(
        imageScaleRotateTuples[i],
        image as HTMLImageElement | ImageBitmap,
      );
    } catch (error) {
      console.error(error);
    }
  }
};

/**
 * @deprecated this func will be replaced by loadImageSets()
 */
export const loadImagesPoints = async (map: mapboxgl.Map) => {
  const load = async (index: number) => {
    return new Promise<HTMLImageElement | ImageBitmap | undefined>(
      (resolve, rejects) => {
        map.loadImage(imagePointsTuples[index + 1], (error, image) => {
          if (error) {
            rejects(error);
          }
          resolve(image);
        });
      },
    );
  };

  for (let i = 0; i < imagePointsTuples.length; i = i + 2) {
    try {
      const image = await load(i);
      map.addImage(
        imagePointsTuples[i],
        image as HTMLImageElement | ImageBitmap,
      );
    } catch (error) {
      console.error(error);
    }
  }
};

/**
 * @deprecated this func will be replaced by loadImageSets()
 */
export const loadImageDastrackV = async (map: mapboxgl.Map) => {
  const load = async (index: number) => {
    return new Promise<HTMLImageElement | ImageBitmap | undefined>(
      (resolve, rejects) => {
        map.loadImage(imageDastrackVTuples[index + 1], (error, image) => {
          if (error) {
            rejects(error);
          }
          resolve(image);
        });
      },
    );
  };

  for (let i = 0; i < imageDastrackVTuples.length; i = i + 2) {
    try {
      const image = await load(i);
      map.addImage(
        imageDastrackVTuples[i],
        image as HTMLImageElement | ImageBitmap,
      );
    } catch (error) {
      console.error(error);
    }
  }
};

/**
 * @deprecated this func will be replaced by loadImageSets()
 */
export const loadImageDaswater = async (map: mapboxgl.Map) => {
  const load = async (index: number) => {
    return new Promise<HTMLImageElement | ImageBitmap | undefined>(
      (resolve, rejects) => {
        map.loadImage(imageDaswaterTuples[index + 1], (error, image) => {
          if (error) {
            rejects(error);
          }
          resolve(image);
        });
      },
    );
  };

  for (let i = 0; i < imageDaswaterTuples.length; i = i + 2) {
    try {
      const image = await load(i);
      map.addImage(
        imageDaswaterTuples[i],
        image as HTMLImageElement | ImageBitmap,
      );
    } catch (error) {
      console.error(error);
    }
  }
};

/**
 * @deprecated this func will be replaced by loadImageSets()
 */
export const loadImageDasgas = async (map: mapboxgl.Map) => {
  const load = async (index: number) => {
    return new Promise<HTMLImageElement | ImageBitmap | undefined>(
      (resolve, rejects) => {
        map.loadImage(imageDasgasTuples[index + 1], (error, image) => {
          if (error) {
            rejects(error);
          }
          resolve(image);
        });
      },
    );
  };

  for (let i = 0; i < imageDasgasTuples.length; i = i + 2) {
    try {
      const image = await load(i);
      map.addImage(
        imageDasgasTuples[i],
        image as HTMLImageElement | ImageBitmap,
      );
    } catch (error) {
      console.error(error);
    }
  }
};

export const loadImageSets = async (map: mapboxgl.Map, imageSets: string[]) => {
  const load = async (index: number) => {
    return new Promise<HTMLImageElement | ImageBitmap | undefined>(
      (resolve, rejects) => {
        map.loadImage(imageSets[index + 1], (error, image) => {
          if (error) {
            rejects(error);
          }
          resolve(image);
        });
      },
    );
  };

  for (let i = 0; i < imageSets.length; i = i + 2) {
    try {
      const image = await load(i);
      map.addImage(imageSets[i], image as HTMLImageElement | ImageBitmap);
    } catch (error) {
      console.error(error);
    }
  }
};

export const loadSdfImageSets = async (
  map: mapboxgl.Map,
  imageSets: string[],
) => {
  const load = async (index: number) => {
    return new Promise<HTMLImageElement | ImageBitmap | undefined>(
      (resolve, rejects) => {
        map.loadImage(imageSets[index + 1], (error, image) => {
          if (error) {
            rejects(error);
          }
          resolve(image);
        });
      },
    );
  };

  for (let i = 0; i < imageSets.length; i = i + 2) {
    try {
      const image = await load(i);
      map.addImage(imageSets[i], image as HTMLImageElement | ImageBitmap, {
        sdf: true,
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const transformTracksToGeojson = (
  tracks: Array<TrackNum>,
  type: '2d' | '3d',
) => {
  let source: CoordinatesSource | null = null;
  let data: Array<[number, number] | [number, number, number]> = [];
  let geojson: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: [],
  };

  tracks.forEach((track) => {
    if (source && track.source !== source) {
      if (type === '2d') {
        data.push([track.coordinates.lon, track.coordinates.lat]);
      } else if (type === '3d') {
        data.push([
          track.coordinates.lon,
          track.coordinates.lat,
          track.coordinates.alt ?? 0,
        ]);
      }
      geojson.features.push({
        type: 'Feature',
        properties: {
          coordinateSource: source,
        },
        geometry: {
          type: 'LineString',
          coordinates: data,
        },
      });

      data = [];
    }

    source = track.source;
    if (type === '2d') {
      data.push([track.coordinates.lon, track.coordinates.lat]);
    } else if (type === '3d') {
      data.push([
        track.coordinates.lon,
        track.coordinates.lat,
        track.coordinates.alt ?? 0,
      ]);
    }
  });

  geojson.features.push({
    type: 'Feature',
    properties: {
      coordinateSource: source,
    },
    geometry: {
      type: 'LineString',
      coordinates: data,
    },
  });

  return geojson;
};

export const transformTracksToGeojsonFeature = (
  tracks: Array<TrackNum>,
  type: '2d' | '3d',
) => {
  let geojson: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: [],
  };

  let init: Array<TrackNum[]> = [];
  const trackObject: Array<TrackNum[]> = tracks.reduce(
    (acc, curr) => (
      acc[acc.length - 1]?.[0].source != curr.source
        ? acc.push([curr])
        : acc[acc.length - 1].push(curr),
      acc
    ),
    init,
  );

  trackObject.forEach((val) => {
    if (val.length > 0) {
      geojson.features.push({
        type: 'Feature',
        properties: {
          coordinateSource: val[0].source,
        },
        geometry: {
          type: 'LineString',
          coordinates: val.map((track) => {
            if (type === '2d') {
              return [track.coordinates.lon, track.coordinates.lat];
            } else if (type === '3d') {
              return [
                track.coordinates.lon,
                track.coordinates.lat,
                track.coordinates.alt ?? 0,
              ];
            }
            return [track.coordinates.lon, track.coordinates.lat];
          }),
        },
      });
    }
  });

  return geojson;
};

export const transformTracksToHeatMapGeojson = (
  tracks: Array<Omit<Track, 'timestamp'>>,
) => {
  const geojson: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: [],
  };

  tracks.forEach((track) => {
    geojson.features.push({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: [track.coordinates.lon ?? 0, track.coordinates.lat ?? 0],
      },
    });
  });

  return geojson;
};

const EARTH_RADIUS = 6371000;

export const coordinatesToLngLat = (
  coordinates: { x: number; y: number },
  origin: { lng: number; lat: number },
) => {
  const offsetLat = (coordinates.y * 180) / (Math.PI * EARTH_RADIUS);
  const lat = origin.lat + offsetLat;
  const offsetLng =
    (coordinates.x * 180) /
    (Math.PI * EARTH_RADIUS * Math.cos((lat * Math.PI) / 180));
  const lng = origin.lng + offsetLng;
  return { offset: { lng: offsetLng, lat: offsetLat }, lngLat: { lng, lat } };
};

export const getModelTransform = (
  lngLatAlt: [number, number, number],
  rotate: [number, number, number],
) => {
  const mc = MercatorCoordinate.fromLngLat(
    [lngLatAlt[0], lngLatAlt[1]],
    lngLatAlt[2],
  );

  return {
    translateX: mc.x,
    translateY: mc.y,
    translateZ: mc.z,
    rotateX: rotate[0],
    rotateY: rotate[1],
    rotateZ: rotate[2],
    scale: mc.meterInMercatorCoordinateUnits(),
  };
};

export const create3DModelLayer = (
  center: [number, number],
  projectElevation: number,
  models: Model[],
): { layer: CustomLayerInterface; scene: Scene } => {
  const camera: Camera = new Camera();
  const scene: Scene = new Scene();
  let renderer: WebGLRenderer;
  let map: Map;

  return {
    layer: {
      id: '3d-model-layer',
      type: 'custom',
      renderingMode: '3d',
      onAdd: (m, gl) => {
        const material = new MeshLambertMaterial({
          transparent: true,
          opacity: 0.7,
          emissive: 0x000080,
        });

        const converter = new CoordinateConverter(center[1], center[0]);

        // const directionalLight2 = new DirectionalLight(0xffffff);
        // directionalLight2.position.set(5, 70, 5).normalize();
        // scene.add(directionalLight2);

        const light = new AmbientLight(0x404040);
        scene.add(light);

        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        models.forEach((model) => {
          let isTaller = true;

          if (
            typeof model.coordinates?.alt !== 'undefined' &&
            model.coordinates.alt < projectElevation
          ) {
            isTaller = false;
          }

          loader.load(model.gltfURL, (gltf) => {
            const scaleValue = model.scale ?? 1;
            gltf.scene.scale.set(scaleValue, scaleValue, scaleValue);
            gltf.scene.rotation.set(
              ((model.rotation?.roll ?? 0) * Math.PI) / 180,
              ((model.rotation?.heading ?? 0) * Math.PI) / 180,
              ((model.rotation?.pitch ?? 0) * Math.PI) / 180,
            );
            gltf.scene.updateMatrixWorld();
            const aabb = new Box3().setFromObject(gltf.scene);
            const aabbCenter = aabb.getCenter(new Vector3());
            const position = converter.geographicToCartesian(
              model.coordinates?.lat ?? 0,
              model.coordinates?.lon ?? 0,
            );
            gltf.scene.userData.name = model.name;
            gltf.scene.position.set(
              position.x * -1 - aabbCenter.x,
              isTaller
                ? 0 + (model.heightTune ?? 0)
                : (model.coordinates?.alt ?? 0) -
                    projectElevation +
                    (model.heightTune ?? 0),
              position.z * -1 - aabbCenter.z,
            );

            gltf.scene.traverse((child) => {
              if (child instanceof Mesh) {
                child.material = material;
              }
            });

            scene.add(gltf.scene);
          });
        });

        map = m;

        renderer = new WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true,
        });

        renderer.autoClear = false;
      },
      render: (gl, matrix) => {
        const mt = getModelTransform(
          [center[0], center[1], 0],
          [Math.PI / 2, 0, 0],
        );

        const rotationX = new Matrix4().makeRotationAxis(
          new Vector3(1, 0, 0),
          mt.rotateX,
        );
        const rotationY = new Matrix4().makeRotationAxis(
          new Vector3(0, 1, 0),
          mt.rotateY,
        );
        const rotationZ = new Matrix4().makeRotationAxis(
          new Vector3(0, 0, 1),
          mt.rotateZ,
        );

        const m = new Matrix4().fromArray(matrix);
        const l = new Matrix4()
          .makeTranslation(mt.translateX, mt.translateY, mt?.translateZ ?? 0)
          .scale(new Vector3(mt.scale, -mt.scale, mt.scale))
          .multiply(rotationX)
          .multiply(rotationY)
          .multiply(rotationZ);

        camera.projectionMatrix = m.multiply(l);
        renderer.resetState();
        renderer.render(scene, camera);
        map.triggerRepaint();
      },
      onRemove: () => {
        scene.remove();
        camera.remove();
        renderer.clear();
      },
    },
    scene,
  };
};

export const getCenterArea: (polygon: Position[][]) => [number, number] = (
  polygon,
) => {
  //@ts-ignore
  const multipolygon = turf.polygon(polygon);
  const getCenter = turf.centerOfMass(multipolygon);

  const lon = getCenter.geometry.coordinates[0];
  const lat = getCenter.geometry.coordinates[1];
  return [lon, lat];
};

export const getCenterAreaMultiPolygon: (
  multiPolygon: AreaMultiPolygon,
) => [number, number] = (multiPolygon) => {
  const multipolygon = turf.multiPolygon(multiPolygon);
  const getCenter = turf.centerOfMass(multipolygon);

  const lon = getCenter.geometry.coordinates[0];
  const lat = getCenter.geometry.coordinates[1];
  return [lon, lat];
};

const getRouteArea = (path: Path) => {
  const lineString = turf.lineString(path);
  return turf.buffer(lineString, 50, {
    units: 'meters',
  });
};

export const countAreaPath = (path: Path) => {
  const routeArea = getRouteArea(path);
  return routeArea.geometry.coordinates.length;
};

export const lineToPolygon = (path: Path) => {
  return getRouteArea(path);
};

export const getCircleFromPoint = (
  center: [number, number],
  radius: number,
  steps: number,
) => {
  const circle = turf.circle(center, radius, {
    steps,
    units: 'kilometers',
    properties: { foo: 'bar' },
  });
  return circle;
};
