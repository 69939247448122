import type { SVGProps } from 'react';

const AiHostSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 7h12v12H12zm3.625 3h1.75L20 17.012h-1.75L17.87 16H15.13l-.38 1.012H13zm.252 4 .623-1.663.622 1.663zM23 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0m0 2v4h-2v-4zm-6 8.298V21h8.181v5.14zm17.18-6.453L26 19.682v-9.68zM17 22.913l8.181 4.842V33L17 28.158zm18-6.86-8.181 4.842v5.245l8.18-4.842zm-.817 1.879-2.454 1.475v2.56l2.454-1.476zm-7.364 9.822 8.18-4.842v5.246L26.82 33zm4.91-1.487 2.454-1.476v2.56l-2.454 1.475z"
      clipRule="evenodd"
    />
  </svg>
);
export default AiHostSvgIcon;
