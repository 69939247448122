import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface HeartRateSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const HeartRateSvgIcon = ({ disabled, ...props }: HeartRateSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <path
      d="M20 29C19.8487 29 19.7026 28.9441 19.5887 28.8427C18.7258 28.0733 17.8953 27.3517 17.1629 26.7154C12.8492 22.968 10 20.4926 10 16.8743C10 13.5256 12.284 11 15.3125 11C16.3977 11 17.393 11.3504 18.2699 12.0422C19.1105 12.7052 19.6703 13.5487 20 14.1635C20.3297 13.5495 20.8895 12.7052 21.7301 12.0422C22.607 11.3504 23.6023 11 24.6875 11C27.716 11 30 13.5256 30 16.8743C30 20.4926 27.1508 22.9672 22.8375 26.715C22.1047 27.3521 21.275 28.0733 20.4113 28.8427C20.2974 28.9441 20.1513 29 20 29Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default HeartRateSvgIcon;
