import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton, styled, Typography } from '@mui/material';

import { DasIdWillAssign } from '../../../../types';

import AddSvgIcon from '../../../../assets/SvgIcon/AddSvgIcon';

import ConntectVehicleCard from './ConntectVehicleCard';

const Container = styled('div')`
  height: calc(100% - 80px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  margin-top: 8px;
`;

const ContentTitle = styled(Typography)`
  color: white;
  margin-bottom: 8px;
`;

const Content = styled('div')`
  border-radius: 4px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  margin-top: 10px;
`;

const Button = styled(MuiButton)`
  text-transform: none;
  height: 40px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: start;
  align-items: center;
  color: white;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

interface DeviceVehicleProps {
  handleDasIDWillAssign: (dasIds: Array<DasIdWillAssign>) => void;
  data: Array<DasIdWillAssign>;
}

const DeviceVehicle: React.FC<DeviceVehicleProps> = ({
  handleDasIDWillAssign,
  data,
}) => {
  const { t } = useTranslation('project-setting');
  const cards = data;

  const handleAddCard = () => {
    let initPlantCard: DasIdWillAssign = {
      id: '',
      name: '',
      batteryLevel: 0,
    };
    handleDasIDWillAssign([...cards, initPlantCard]);
  };

  const onChangeCard = (index: number, datas: DasIdWillAssign) => {
    let newDatas = [...cards];
    newDatas[index] = datas;
    handleDasIDWillAssign(newDatas);
  };

  const onDeleteCard = (index: number) => {
    let newcards = [...cards];
    newcards.splice(index, 1);
    handleDasIDWillAssign(newcards);
  };

  const items = cards.map((item, i) => (
    <ConntectVehicleCard
      key={i}
      onChange={onChangeCard}
      onDeleteCard={onDeleteCard}
      connectedDasId={item}
      index={i}
    />
  ));

  return (
    <Container>
      <ContainerContent>
        <ContentTitle>
          {t(
            'page.plant-management.dialog.create-plant.connected-device.title',
          )}
        </ContentTitle>
        <Button
          startIcon={<AddSvgIcon sx={{ width: '32px', height: '32px' }} />}
          onClick={handleAddCard}
          data-cy="btn-add-dastrack-v"
        >
          {t('page.vehicle-management.add-dastrack-v')}
        </Button>
        <Content>{items}</Content>
      </ContainerContent>
    </Container>
  );
};

export default DeviceVehicle;
