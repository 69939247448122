import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { LocationOn } from '@mui/icons-material';
import {
  CircularProgress,
  Dialog as MuiDialog,
  styled,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { SettingMap } from '../types';

import { forwardGeocoding } from '../apis/MapboxApi';
import { useAppDispatch, useAppSelector } from '../hooks';
import useDebounce from '../hooks/useDebounce';
import { useLocalStorageObject } from '../hooks/useLocalStorageObject';
import useMapboxArea from '../pages/dashboard/hooks/useMapboxArea';
import useMapboxFloorPlan from '../pages/dashboard/hooks/useMapboxFloorPlan';
import { setPinLocation } from '../slices/pages/projectPageSlice';

import searchSvg from '../assets/images/svg/btn_ic_search.svg';
import CloseMediumSvgIcon from '../assets/SvgIcon/CloseMediumSvgIcon';
import i18n from '../i18n';
import { addAreasLayer } from '../pages/dashboard/Map2d/addLayers';
import {
  isLatitude,
  isLongitude,
  separateLongLat,
} from '../utils/separateLongLat';

import IconContainer from './IconContainer';
import MapSwitch from './MapSwitch';

const IconContainerStyled = styled(IconContainer)`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  color: ${({ theme }) => theme.color.secondary.$100};
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: ${({ theme }) => theme.color.secondary.$100};
    background-color: #3e3e3e2b;
  }
`;

const Dialog = styled(MuiDialog)`
  & .MuiPaper-root {
    max-width: unset;
    max-height: unset;
    margin: 0;
  }
  #map-crane {
    width: 100vw;
    height: 100vh;
    background: #ffffff;
  }

  & .mapboxgl-popup-content {
    font-size: 1rem;
    color: ${({ theme }) => theme.color.secondary.$80};
    text-align: center;
    padding: 30px 37px;
    border-radius: 10px;
    & h3 {
      font-size: 1rem;
      font-weight: 500;
      color: black;
      margin: 0;
      margin-bottom: 12px;
    }
  }

  & button {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: white;
    background: ${({ theme }) => theme.color.primary.$100};
    padding: 4px 44px;
    border: none;
    border-radius: 4px;
    margin-top: 16px;
  }
`;

const AutoComplete = styled('div')`
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
  width: 320px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  & > .query {
    display: flex;
    align-items: center;
    background: white;
    padding: 4px;
    border-radius: 4px;
    & > input {
      flex: 1;
      font-size: 1rem;
      line-height: 1.5;
      color: ${({ theme }) => theme.color.secondary.$100};
      padding: 0;
      border: 0;
      outline: none;
    }
  }
  & > .autocomponent {
    list-style-type: none;
    background: white;
    padding: 4px 0;
    border-radius: 4px;
    margin: 0px;
    margin-top: 8px;
    & > li {
      cursor: pointer;
      display: flex;
      padding: 6px 4px;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
      & .place {
        & > p {
          font-size: 0.75rem;
          line-height: 1.5em;
          color: #969696;
          margin: 0;
          &:first-of-type {
            font-size: 0.88rem;
            line-height: 1.13rem;
            color: #606060;
          }
        }
      }
    }
  }
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContainerInput = styled('div')`
  position: absolute;
  top: 30px;
  left: 356px;
  z-index: 1;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  & > .container-input {
    display: flex;
    align-items: center;
    background: white;
    width: 200px;
    padding: 4px;
    border-radius: 4px;
    margin-left: 10px;
    margin-bottom: 10px;
    & > input {
      flex: 1;
      width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      color: ${({ theme }) => theme.color.secondary.$100};
      padding: 0;
      border: 0;
      outline: none;
    }
  }
`;

interface DialogSetPinLocationProps {
  open;
  onClose;
}

const DialogSetPinLocation: React.FC<DialogSetPinLocationProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const pinLocation = useAppSelector((store) => store.projectPage.pinLocation);
  const mapboxRef = useRef<mapboxgl.Map | null>(null);
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  const popUpInfoRef = useRef<mapboxgl.Popup | null>(null);
  const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null);
  const [popupRef, setPopupRef] = useState<HTMLDivElement | null>(null);
  const popupRefArea = useRef<mapboxgl.Popup | null>(null);
  const [placeQuery, setPlaceQuery] = useState('');
  const query = useDebounce(placeQuery, 2000);
  const [queryLatLong, setQueryLatLong] = useState('');
  const queryLatLongSearch = useDebounce(queryLatLong, 1000);
  const [isMapReady, setIsMapReady] = useState<boolean>(false);
  const [mapLocal, setMapLocal] = useLocalStorageObject<SettingMap>(
    'mapStyleSetting_',
    projectId,
  );

  const { data, isLoading } = useQuery(
    ['forwardGeocoding', query],
    async () => {
      const { data: response } = await forwardGeocoding(query, 'zh-Hant');
      return response;
    },
    {
      enabled: query !== '',
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { projectArea, projectFloorPlan } = useAppSelector(
    (store) => store.projects,
  );

  useEffect(() => {
    const initial = async () => {
      const { default: mapboxgl } = await import(
        /* webpackChunkName: "mapbox-gl" */ 'mapbox-gl'
      );

      const map = new mapboxgl.Map({
        accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN || '',
        container: 'map-crane',
        center: pinLocation,
        zoom: 15,
        style:
          mapLocal && mapLocal.mapStyle === 'satelite'
            ? 'mapbox://styles/mapbox/satellite-streets-v12'
            : 'mapbox://styles/ci-bot-tw/cl7iuaop2000014pbc5d3jgoe',
      });

      map.addControl(
        new MapboxLanguage({
          defaultLanguage: i18n.language,
        }),
      );
      mapboxRef.current = map;

      map.on('load', () => {
        addAreasLayer(map, 'path-pedestrian-label');
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          className: 'tooltip-area',
        });

        popupRefArea.current = popup;

        setIsMapReady(true);
      });

      markerRef.current = new mapboxgl.Marker()
        .setLngLat(pinLocation)
        .addTo(map);

      popUpInfoRef.current = new mapboxgl.Popup({
        offset: 55,
        closeButton: false,
        closeOnClick: false,
      })
        .setLngLat(pinLocation)
        .setDOMContent(popupRef as HTMLDivElement)
        .addTo(map);

      map.on('click', (e) => {
        dispatch(setPinLocation([e.lngLat.lng, e.lngLat.lat]));
      });
    };

    if (mapRef && popupRef && !mapboxRef.current) {
      initial();
    }
    return () => {
      mapboxRef.current?.remove();
      mapboxRef.current = null;
    };
  }, [mapRef, popupRef, mapLocal]);

  useMapboxArea(isMapReady, projectArea, mapboxRef, popupRefArea);

  useMapboxFloorPlan(isMapReady, projectFloorPlan, mapboxRef.current);

  useEffect(() => {
    mapboxRef.current?.setCenter(pinLocation);
    markerRef.current?.setLngLat(pinLocation);
    popUpInfoRef.current?.setLngLat(pinLocation);

    if (isLongitude(pinLocation[0]) && isLatitude(pinLocation[1])) {
      setQueryLatLong(pinLocation.join(', '));
    }
  }, [pinLocation]);

  const handleOnSelectPlace = (center: [number, number]) => {
    dispatch(setPinLocation(center));
  };

  useEffect(() => {
    const value = String(queryLatLongSearch);
    const { lon, lat } = separateLongLat(value);
    if (isLongitude(lon) && isLatitude(lat)) {
      dispatch(setPinLocation([lon, lat]));
    }
  }, [queryLatLongSearch]);

  return (
    <Dialog className="PinLocationDialog" open={open}>
      <AutoComplete>
        <div className="query">
          <IconContainer size={32}>
            <img src={searchSvg} alt="search" />
          </IconContainer>
          <input
            onChange={(e) => setPlaceQuery(e.currentTarget.value)}
            data-cy="input-pin-location"
          />
          {isLoading && <CircularProgress color="primary" size="1rem" />}
        </div>
        {data && (
          <ul className="autocomponent">
            {data?.features.map((feature) => {
              return (
                <li
                  key={`query-reuslt-${feature.id}`}
                  onClick={() => handleOnSelectPlace(feature.center)}
                >
                  <IconContainer size={32}>
                    <img src={searchSvg} alt="search" />
                  </IconContainer>
                  <div className="place">
                    <Typography>{feature.text}</Typography>
                    <Typography>{feature.place_name}</Typography>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </AutoComplete>
      <ContainerInput>
        <div className="container-input">
          <IconContainer size={32}>
            <LocationOn />
          </IconContainer>
          <input
            value={queryLatLong}
            onChange={(e) => setQueryLatLong(e.currentTarget.value)}
            placeholder={t('long-lat')}
          />
        </div>
      </ContainerInput>
      <div ref={(ref) => setMapRef(ref)} id="map-crane" />
      <MapSwitch
        mapLocal={mapLocal}
        setMapLocal={setMapLocal}
        sx={{
          position: 'absolute',
          margin: '10px',
          top: '20px',
          left: '570px',
          zIndex: '5',
        }}
      />
      <Content ref={(ref) => setPopupRef(ref)}>
        <h3>{t('project:dialog.pinLocationDialog.title')}</h3>
        {pinLocation[0]}, {pinLocation[1]}
        <DialogButton
          color="primary"
          variant="contained"
          data-cy="btn-pin-location-submit"
          onClick={onClose}
        >
          {t('project:dialog.pinLocationDialog.submit')}
        </DialogButton>
      </Content>
      <IconContainerStyled onClick={onClose}>
        <CloseMediumSvgIcon sx={{ width: '32px', height: '32px' }} />
      </IconContainerStyled>
    </Dialog>
  );
};

export default DialogSetPinLocation;
