import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Participant } from 'livekit-client';

import { RoomProps } from '@livekit/react-components';

type RoomState = {
  room: RoomProps | undefined;
  isSpeaking: boolean;
  liveCallVideo: {
    height: number;
    width: number;
  };
  participants: Participant[];
};

const initialState: RoomState = {
  room: undefined,
  isSpeaking: false,
  liveCallVideo: {
    height: 0,
    width: 0,
  },
  participants: [],
};

export const liveCallSlice = createSlice({
  name: 'liveCallPage',
  initialState,
  reducers: {
    setRoom: (state, action: PayloadAction<RoomState['room']>) => {
      return { ...state, room: action.payload };
    },
    setIsSPeaking: (state, action: PayloadAction<RoomState['isSpeaking']>) => {
      return { ...state, isSpeaking: action.payload };
    },
    setParticipants: (
      state,
      action: PayloadAction<RoomState['participants']>,
    ) => {
      return { ...state, participants: action.payload };
    },
    setLiveCallVideoDetail: (
      state,
      action: PayloadAction<RoomState['liveCallVideo']>,
    ) => {
      return { ...state, liveCallVideo: action.payload };
    },
  },
});

export const {
  setRoom,
  setIsSPeaking,
  setParticipants,
  setLiveCallVideoDetail,
} = liveCallSlice.actions;
export default liveCallSlice.reducer;
