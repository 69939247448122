import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  Avatar as MuiAvatar,
  Box,
  DialogActions,
  DialogContent as MuiDialogContent,
  FormControl,
  styled,
  TextareaAutosize,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { InputValidationError, UpdateStatus } from '../../../types';
import {
  CreateResourceGroupPayload,
  OperationApiPayload,
} from '../../../types/Resource';

import { uploadResourceGroupAvatar } from '../../../apis/ResourceApi';

import PhotoDefault from '../../../assets/images/default-image/photo_default.png';
import UploadSvg from '../../../assets/images/svg/btn_upload_image.svg';
import ErrorSvg from '../../../assets/images/svg/ic_error_ifo.svg';
import { handleStatusButton } from '../../../assets/SvgIcon/HandleIcon';
import mainTheme from '../../../theme';
import ConfirmButtonDark from '../../ConfirmButtonDark';
import EditDialog from '../../Dialog/EditDialog';
import Input from '../../TextField/Input';

const Avatar = styled(MuiAvatar)`
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
  margin: 0 auto;
`;

const ContainerImage = styled('div')`
  margin: 10px auto;
  width: 200px;
  position: relative;
`;

const ContainerUploadImage = styled('div')`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
`;

const DialogContent = styled(MuiDialogContent)`
  ${({ theme }) => ({ ...theme.externalTypography.body3 })}
  padding: 0px 30px;
  color: white;

  & .MuiFormControl-root {
    width: 100%;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    & .MuiInputBase-root {
      width: 100%;
    }

    & textarea {
      ${({ theme }) => ({ ...theme.typography.body2 })}
      width: 100%;
      font-family: 'Noto Sans TC', 'Noto Sans';
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      outline: none;
      color: white;
      padding: 10px;
      border-color: transparent;
      resize: none;

      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.5);
      }
    }
  }
`;

const Error = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  & > span {
    color: #ff6b00;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

const SpanRequire = styled('span')`
  color: ${({ theme }) => theme.color.highlight};
`;

interface TargetOperationFieldProps {
  open: boolean;
  onClose: () => void;
  name?: string;
  remark?: string;
  groupId?: string;
  buildingGroupId?: string;
  imageURL?: string;
  operationApi: (
    data: CreateResourceGroupPayload | OperationApiPayload,
  ) => Promise<any>;
  onSuccess: () => void;
  mode: 'edit' | 'create';
}

const TargetOperationField: React.FC<TargetOperationFieldProps> = ({
  open,
  onClose,
  name,
  remark,
  groupId,
  buildingGroupId,
  imageURL,
  operationApi,
  onSuccess: onSuccessOperation,
  mode,
}) => {
  const { projectId } = useParams();
  const { t } = useTranslation('project-setting');
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [localName, setLocalName] = useState<string | undefined>(name);
  const [localRemark, setLocalRemark] = useState<string | undefined>(remark);
  const [saveButtonStatus, setSaveButtonStatus] = useState<UpdateStatus>();
  const [localImageFile, setImageFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>(imageURL ?? '');
  const [imageFormatError, setImageFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });

  const { mutateAsync: mutateOperationApi } = useMutation(operationApi);

  const handleSubmit = async () => {
    setSaveButtonStatus('loading');
    try {
      if (projectId) {
        const fieldGroup = await mutateOperationApi({
          projectId,
          resourceType: 'field',
          groupId,
          name: localName ?? '',
          data: {
            name: localName ?? '',
            buildingGroupId: buildingGroupId,
            remark: localRemark,
          },
          payload: {
            name: localName ?? '',
            remark: localRemark,
          },
        });

        if (fieldGroup && localImageFile) {
          await uploadResourceGroupAvatar({
            groupId:
              mode === 'edit' ? fieldGroup.data.id : fieldGroup.data.data.id,
            imageFile: localImageFile,
            projectId,
            resourceType: 'field',
          });
        }
      }

      let timer: NodeJS.Timeout;
      let timer2: NodeJS.Timeout;
      timer = setTimeout(() => {
        setSaveButtonStatus('success');
        onSuccessOperation();
        timer2 = setTimeout(() => {
          setSaveButtonStatus(undefined);
          onClose();
        }, 500);
      }, 2000);

      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } catch (err) {
      setSaveButtonStatus(undefined);
    }
  };

  const saveable = () => {
    if (!localName) return false;
    return true;
  };

  const handleOnClickUploadImage = () => {
    fileRef.current?.click();
  };

  const handleOnSelectFile = () => {
    setImageFormatError({ isError: false, message: '' });
    if (fileRef.current && fileRef.current?.files) {
      const fileImge = fileRef.current?.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const newSrc = reader.result as string;
        if (fileRef.current && fileRef.current.files) {
          setImageUrl(newSrc);
          setImageFile(fileRef.current.files[0]);
        }
      });
      reader.readAsDataURL(fileImge);
    }
  };

  return (
    <EditDialog
      open={open}
      onClose={onClose}
      title={t(`project-setting:page.devices.puring-area-setting`)}
      sx={{
        '& .MuiPaper-root': {
          width: '480px',
          maxWidth: 'unset',
          maxHeight: 'unset !important',
          margin: '30px',
        },
      }}
    >
      <DialogContent>
        <ContainerImage>
          <Avatar
            src={imageUrl ?? ''}
            alt="plant photo"
            sx={{ marginBottom: '10px' }}
            variant="square"
          >
            <Avatar src={PhotoDefault} alt=" beeinventor" />
          </Avatar>
          <ContainerUploadImage onClick={handleOnClickUploadImage}>
            <img src={UploadSvg} alt="upload icon" />
            <input
              ref={fileRef}
              type="file"
              hidden
              accept=".jpg, .png, .jpeg, .svg"
              onChange={handleOnSelectFile}
              data-cy="input-image-plant"
            />
          </ContainerUploadImage>
        </ContainerImage>
        {imageFormatError.message && imageFormatError.message !== '' && (
          <Error>
            {<img src={ErrorSvg} alt="error" />}
            <span>{imageFormatError.message}</span>
          </Error>
        )}
        <FormControl>
          <label htmlFor="group-name-input">
            {t('puring-area-name')}
            <SpanRequire>*</SpanRequire>
          </label>
          <Input
            id="group-name-input"
            name="group-name"
            value={localName}
            onChange={(e) => setLocalName(e.currentTarget.value)}
            autoComplete="off"
          />
        </FormControl>
        <FormControl>
          <label htmlFor="group-remark-input">{t('remark')}</label>
          <TextareaAutosize
            id="group-remark-input"
            name="group-remark"
            maxRows={3}
            minRows={3}
            value={localRemark}
            onChange={(e) => setLocalRemark(e.currentTarget.value)}
            autoComplete="off"
          />
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '24px',
        }}
      >
        <DialogButton
          sx={{
            ml: '0',
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': { background: mainTheme.color.secondary.$60 },
          }}
          color="secondary"
          onClick={onClose}
        >
          {t('cancel')}
        </DialogButton>
        <ConfirmButtonDark
          onClick={handleSubmit}
          disabled={!saveable()}
          status={saveButtonStatus}
          sx={{
            ml: 'auto',
            width: '120px',
            height: '32px',
          }}
        >
          {handleStatusButton(t, saveButtonStatus)}
        </ConfirmButtonDark>
      </DialogActions>
    </EditDialog>
  );
};

export default TargetOperationField;
