import { DasIdWillAssign } from '../../../../types';

import i18n from '../../../../i18n';

export const hasAssignedDevice = (devices: DasIdWillAssign[]) => {
  if (devices.length === 0) return false;
  return devices.reduce((prev, curr) => {
    if (curr.id) return prev && true;
    return false;
  }, true);
};

export const checkNameFormat = (name) => {
  if (name === '') {
    return {
      isError: true,
      message: i18n.t('error:inputIsEmpty'),
    };
  }
  return { isError: false, message: '' };
};

export const checkAlertInterval = (value: number) => {
  if (value < 5) {
    return {
      isError: true,
      message: i18n.t('error:minimumInputValue', { value: 5 }),
    };
  }
  return { isError: false, message: '' };
};

export const hasGroupsIds = (groupIds: Array<string>) => {
  return groupIds.length > 0;
};

export const checkResourceHasContact = (contacts: string[]) => {
  if (contacts.length === 0) return false;
  return true;
};

export const checkIsDasIdEqual = (
  dasIdInit: Array<DasIdWillAssign>,
  dasIdAssign: Array<DasIdWillAssign>,
) => {
  let n = dasIdInit.length;
  let m = dasIdAssign.length;

  let initIds = [...dasIdInit].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  let assignIds = [...dasIdAssign].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  if (n !== m) {
    return false;
  } else {
    for (let i = 0; i < n; i += 1) {
      if (initIds[i].name === null) {
        if (assignIds[i].name) return false;
      } else {
        if (initIds[i].name !== assignIds[i].name) return false;
      }
    }
  }

  return true;
};

export const checkIdEqual = (
  idSelected: string | undefined,
  idAssign: string | undefined,
) => {
  if (idSelected !== idAssign) return false;
  return true;
};

export const checkIsAvatarUpdated = (avatar: File | undefined) => {
  if (avatar) {
    return true;
  }
  return false;
};
