import { useEffect, useState } from 'react';

export const useScrollButton = (ref: HTMLDivElement | null) => {
  const [posDown, setPosDown] = useState(false);
  const [posUp, setPosUp] = useState(false);

  const handleTop = () => {
    if (ref) {
      ref.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setPosDown(false);
  };

  const handleDown = () => {
    if (ref) {
      ref.scrollTo({
        top: ref.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    if (ref && ref.scrollTop > 0) {
      if (!posDown) setPosDown(true);
    } else {
      if (posDown) setPosDown(false);
    }

    if (ref) {
      const isBottom =
        ref.scrollHeight - ref.scrollTop <= ref.clientHeight + 10;
      if (isBottom) {
        setPosUp(false);
      } else {
        setPosUp(true);
      }
    }
  };

  useEffect(() => {
    const temp = ref;

    temp?.addEventListener('scroll', handleScroll);
    return () => {
      if (temp) {
        temp?.removeEventListener('scroll', handleScroll);
      }
    };
  });

  useEffect(() => {
    if (ref && ref.scrollHeight > ref.clientHeight) {
      setPosUp(true);
    }
  }, [ref]);

  return { posDown, posUp, handleTop, handleDown };
};
