import React from 'react';
import { Avatar as MuiAvatar, styled, Typography } from '@mui/material';

import { DeviceType } from '../../../types';

import { useAppSelector } from '../../../hooks';

import DefaultImage from '../../../assets/images/default-image/default_blank.jpg';
import DefaultEquipment from '../../../assets/images/default-image/equipment_default.png';
import DefaultPipe from '../../../assets/images/default-image/pipe_default.png';
import DefaultPlant from '../../../assets/images/default-image/plant_default.png';
import DefaultVehicle from '../../../assets/images/default-image/vehicle_default.png';
import DefaultWorker from '../../../assets/images/default-image/worker_default.png';
import CloseSvgIcon from '../../../assets/SvgIcon/CloseSvgIcon';

export interface DataItemBind {
  id: string;
  name: string;
  organization: string;
  imageUrl: string | null;
  isDisable?: boolean;
  color: string;
  handleClick?: (id: string, disable: boolean) => void;
}

const Container = styled('div')`
  display: flex;
  height: 64px;
  width: 100%;
  align-items: center;
  transition: 0.3s;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

const ContainerDetail = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
`;

const Avatar = styled(MuiAvatar)`
  height: 40px;
  width: 40px;
  border-radius: 4px;
  object-fit: cover;
`;

const ContainerInfo = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const TextName = styled(Typography)`
  color: white;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TextOrg = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  marginLeft: '6px',
  color: theme.color.secondary.$60,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const OrgStatus = styled('div')`
  width: 6px;
  height: 14px;
  border-radius: 3px;
`;

const ContainerOrg = styled('div')`
  display: flex;
  max-width: 15rem;
`;

const ContainerClose = styled('div')`
  color: white;
  cursor: pointer;
`;

const handleDefaultImage = (deviceType: DeviceType) => {
  switch (deviceType) {
    case 'dastrack':
      return <Avatar src={DefaultPlant} alt="plant beeinventor" />;
    case 'dastrack-v':
      return <Avatar src={DefaultVehicle} alt="vehicle beeinventor" />;
    case 'dasloop':
    case 'dasgas':
      return <Avatar src={DefaultWorker} alt="worker beeinventor" />;
    case 'daspower':
    case 'daslock':
    case 'dastemp':
      return <Avatar src={DefaultEquipment} alt="equipment beeinventor" />;
    case 'daswater':
      return <Avatar src={DefaultPipe} alt="pipe beeinventor" />;
    default:
      return <Avatar src={DefaultImage} alt="blank beeinventor" />;
  }
};

const ItemBindSelected: React.VFC<DataItemBind> = ({
  id,
  name,
  organization,
  imageUrl,
  color: colorOrg,
  handleClick,
  isDisable,
}) => {
  const { modeDevice } = useAppSelector((store) => store.deviceManagementPage);

  return (
    <Container>
      <ContainerDetail>
        <Avatar variant="square" src={imageUrl ?? ''}>
          {handleDefaultImage(modeDevice ? modeDevice : 'dasloop')}
        </Avatar>
        <ContainerInfo>
          <TextName variant="body2" title={name}>
            {name}
          </TextName>
          <ContainerOrg>
            <OrgStatus sx={{ backgroundColor: colorOrg ?? '#58B99E' }} />
            <TextOrg>{organization}</TextOrg>
          </ContainerOrg>
        </ContainerInfo>
      </ContainerDetail>
      <ContainerClose
        data-cy="btn-unselect-device-item-device-management"
        onClick={() => {
          if (typeof handleClick === 'function') {
            handleClick(id, !isDisable);
          }
        }}
      >
        <CloseSvgIcon sx={{ width: '32px', height: '32px' }} />
      </ContainerClose>
    </Container>
  );
};

export default ItemBindSelected;
