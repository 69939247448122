import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { styled, Typography } from '@mui/material';

import { ConnectedDeviceInfo, ResourceType } from '../../../types';

import { getIconBond } from '../../../assets/SvgIcon/HandleIcon';
import i18n from '../../../i18n';

const Container = styled('div')`
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 4px;
`;

const Content = styled('div')`
  border-radius: 4px;
  padding: 10px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  height: 32px;
  color: white;
  padding-left: 10px;
`;

const BindToDevice = styled('div')`
  background: rgba(0, 0, 0, 0.1);
  height: 64px;
  padding: 12px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.box_bbg};
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.secondary.$60,
  height: '16px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.color.box_bbg,
  height: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const ContainerMap = styled('div')`
  height: 140px;
  margin-top: 10px;
  border-radius: 4px;
`;

interface ConnectedDeviceCardViewProps {
  data: ConnectedDeviceInfo;
  index: number;
  resource: ResourceType;
}

const ConnectedDeviceCardView: React.FC<ConnectedDeviceCardViewProps> = ({
  index,
  data,
  resource,
}) => {
  const { t } = useTranslation('project-setting');

  const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null);
  const mapboxRef = useRef<mapboxgl.Map | null>(null);
  const markerRef = useRef<mapboxgl.Marker | null>(null);

  useEffect(() => {
    const initial = async () => {
      const { default: mapboxgl } = await import(
        /* webpackChunkName: "mapbox-gl" */ 'mapbox-gl'
      );

      mapboxRef.current = new mapboxgl.Map({
        accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN || '',
        container: `project-setting-structure-view-${index}`,
        interactive: false,
        zoom: 15,
        style: 'mapbox://styles/ci-bot-tw/cl7iuaop2000014pbc5d3jgoe',
        center: [data?.location?.lon ?? 0, data?.location?.lat ?? 0],
      });

      mapboxRef.current.addControl(
        new MapboxLanguage({
          defaultLanguage: i18n.language,
        }),
      );

      markerRef.current = new mapboxgl.Marker()
        .setLngLat([data?.location?.lon ?? 0, data?.location?.lat ?? 0])
        .addTo(mapboxRef.current);
    };

    if (mapRef) {
      if (!mapboxRef.current) {
        initial();
      }
    }

    return () => {
      mapboxRef.current?.remove();
      mapboxRef.current = null;
    };
  }, [mapRef, data]);

  return (
    <Container>
      <Header>
        <Typography variant="body2">
          {t(
            `page.${resource}-management.dialog.create-${resource}.connected-device.title-card`,
            {
              count: index + 1,
            },
          )}
        </Typography>
      </Header>
      <Content>
        <BindToDevice>
          {getIconBond(resource)}
          <Typography variant="body2" sx={{ ml: '9px' }}>
            {data.dasId}
          </Typography>
        </BindToDevice>
        {resource === 'structure' && (
          <div>
            <Title>{t('location')}</Title>
            <Text
              title={
                data?.location
                  ? `${data.location.lon}, ${data.location.lat}, ${
                      data.location.alt ? data.location.alt : 'N/A'
                    }`
                  : 'N/A'
              }
            >
              {data?.location
                ? `${data.location.lon}, ${data.location.lat}, ${
                    data.location.alt ? data.location.alt : 'N/A'
                  }`
                : 'N/A'}
            </Text>
            <ContainerMap
              ref={(ref) => setMapRef(ref)}
              id={`project-setting-structure-view-${index}`}
            />
          </div>
        )}
      </Content>
    </Container>
  );
};

export default ConnectedDeviceCardView;
