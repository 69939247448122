import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface FreeFallSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const FreeFallSvgIcon = ({ disabled, ...props }: FreeFallSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1597 18.6488C26.9958 18.6488 28.4842 17.1604 28.4842 15.3244C28.4842 13.4884 26.9958 12 25.1597 12C23.3237 12 21.8353 13.4884 21.8353 15.3244C21.8353 17.1604 23.3237 18.6488 25.1597 18.6488ZM26.0602 21.1636L32.6394 23.9685C35.3051 25.0441 33.6799 28.7965 30.9772 27.7085L26.0602 25.6308C21.4195 30.6174 11.1762 24.7993 7.02069 21.4749C4.69331 19.6127 6.75897 16.6787 9.0977 18.1505C9.62548 18.4827 10.1348 18.8425 10.6485 19.2053C12.3188 20.3852 14.0346 21.5972 16.577 21.9946C14.4994 20.6441 12.8372 18.5664 11.2088 15.6758C9.67849 13.1784 12.9826 11.1068 14.5005 13.5795C16.5911 17.176 19.0968 20.1441 22.0122 19.816C22.7581 19.7321 23.5083 19.6971 24.2205 19.9342C24.9327 20.1713 25.5682 20.5959 26.0602 21.1636Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default FreeFallSvgIcon;
