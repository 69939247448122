import { configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/authSlice';
import deviceSliceReducer from './slices/devices/deviceSlice';
import myDevicePageSliceReducer from './slices/pages/account/myDevicePageSlice';
import notificationListPageReducer from './slices/pages/account/notificationListPageSlice';
import areaManagementPageReducer from './slices/pages/area-management/areaManagementSlice';
import cctvDashboardPageReducer from './slices/pages/cctvDashboardPageSlice';
import dashboardReducer from './slices/pages/dashboardSlice';
import liveCallReducer from './slices/pages/liveCallSlice';
import loginPageReducer from './slices/pages/loginPageSlice';
import deviceManagementPageSliceReducer from './slices/pages/project-setting/deviceManagementPageSlice';
import equipmentManagementPageReducer from './slices/pages/project-setting/equipmentManagementPageSlice';
import floorPlanReducer from './slices/pages/project-setting/floorPlanManagementPageSlice';
import pipeManagementReducer from './slices/pages/project-setting/pipeManagementPageSlice';
import plantManagementPageReducer from './slices/pages/project-setting/plantManagementPageSlice';
import vehicleManagementReducer from './slices/pages/project-setting/vehicleManagementPageSlice';
import projectPageReducer from './slices/pages/projectPageSlice';
import publicAnnouncementReducer from './slices/pages/publicAnnouncementSlice';
import registerPageReducer from './slices/pages/registerPageSlice';
import reportPageReducer from './slices/pages/reportPageSlice';
import projectsReducer from './slices/projectSlice';
import systemReducer from './slices/systemSlice';
import userReducer from './slices/userSlice';
import websocketReducer from './slices/websocketSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    loginPage: loginPageReducer,
    projectPage: projectPageReducer,
    system: systemReducer,
    registerPage: registerPageReducer,
    reportPage: reportPageReducer,
    notificationListPage: notificationListPageReducer,
    projects: projectsReducer,
    myDevicePage: myDevicePageSliceReducer,
    deviceManagementPage: deviceManagementPageSliceReducer,
    devices: deviceSliceReducer,
    plantManagement: plantManagementPageReducer,
    vehicleManagement: vehicleManagementReducer,
    pipeManagement: pipeManagementReducer,
    equipmentManagement: equipmentManagementPageReducer,
    areaManagement: areaManagementPageReducer,
    websocket: websocketReducer,
    publicAnnouncement: publicAnnouncementReducer,
    dashboard: dashboardReducer,
    liveCall: liveCallReducer,
    floorPlanManagement: floorPlanReducer,
    cctvDashboardPage: cctvDashboardPageReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
