import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  ChevronLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';

import { FeatureListItem, FeatureType, SSSSFeatureList } from '../../types';

import alertDashboard from '../../assets/images/svg/alert-dashboard.svg';
import areaManagementSvg from '../../assets/images/svg/area-management.svg';
import logSvg from '../../assets/images/svg/book-open.svg';
import dascasSvg from '../../assets/images/svg/dascasSvg.svg';
import dasloopSvg from '../../assets/images/svg/dasloop.svg';
import downloadSvg from '../../assets/images/svg/download.svg';
import gearSvg from '../../assets/images/svg/gear.svg';
import overviewSvg from '../../assets/images/svg/img_overview.svg';
import plantSvg from '../../assets/images/svg/plant.svg';
import paSvg from '../../assets/images/svg/public-announcement.svg';
import reportSvg from '../../assets/images/svg/report_svg_icon.svg';
import tunnelSvg from '../../assets/images/svg/tunnel.svg';
import AiCameraSvgIcon from '../../assets/SvgIcon/AiCameraSvgIcon';
import ConfinedSpaceSvgIcon from '../../assets/SvgIcon/ConfinedSpaceSvgIcon';
import DasfindSvgIcon from '../../assets/SvgIcon/DasfindSvgIcon';
import DasLockSvgIcon from '../../assets/SvgIcon/DaslockSvgIcon';
import HomeSvgIcon from '../../assets/SvgIcon/HomeSvgIcon';
import PermitToWorkSvgIcon from '../../assets/SvgIcon/PermitToWorkSvgIcon';
import VrSvgIcon from '../../assets/SvgIcon/VrSvgIcon';
import IconContainer from '../IconContainer';
import DarkTooltip from '../Toolip/DarkTooltip';

import { Divider } from '.';

interface SSSSMenuProps {
  dataFeatures: { [key in FeatureType]: FeatureListItem } | undefined;
  projectId: string | undefined;
  selectedProjectId: string | null;
  enterDelay: number;
  overviewReadable: boolean;
  reportReadable: boolean;
  alertReadable: boolean;
  areaWriteable: boolean;
  PAreadable: boolean;
  topScroll: {
    posDown: boolean;
    posUp: boolean;
    handleDown: () => void;
    handleTop: () => void;
    setRef: any;
  };
  bottomScroll: {
    posDown: boolean;
    posUp: boolean;
    handleDown: () => void;
    handleTop: () => void;
    setRef: any;
  };
  onReset: () => void;
}

const SSSSMenu: React.FC<SSSSMenuProps> = ({
  dataFeatures,
  selectedProjectId,
  enterDelay,
  overviewReadable,
  reportReadable,
  alertReadable,
  areaWriteable,
  PAreadable,
  topScroll,
  bottomScroll,
  onReset,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('sidebar');

  const ssssFeatureList: SSSSFeatureList | undefined =
    dataFeatures?.four_s?.metadata;

  return (
    <nav>
      {/^\/dashboard.*/.test(location.pathname) && (
        <div
          className="back"
          onClick={() => {
            onReset();
            navigate('/project');
          }}
        >
          <div>
            <ChevronLeft />
          </div>
        </div>
      )}
      <NavLink
        className="home"
        to={`/dashboard/${selectedProjectId}`}
        data-cy="nav-sidebar-home"
      >
        <div>
          <HomeSvgIcon sx={{ width: '32px', height: '32px' }} />
        </div>
      </NavLink>
      <Divider />
      <div className="swtich-menu">
        {topScroll.posDown && (
          <IconContainer
            onClick={topScroll.handleTop}
            sx={{ width: '50px', height: 'unset', cursor: 'pointer' }}
          >
            <KeyboardArrowUp />
          </IconContainer>
        )}
        <div
          className="switch-item"
          ref={(ref) => {
            if (ref && ref.scrollHeight > ref.clientHeight) {
              topScroll.setRef(ref);
            }
          }}
        >
          {ssssFeatureList?.DigitisedTrackingSystem && (
            <NavLink
              to={`/asset-management-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dasfind"
            >
              <DarkTooltip
                title={t('tooltip.ssss.DigitisedTrackingSystem')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <DasfindSvgIcon />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {ssssFeatureList?.DigitalisedPermitToWorkSystem && (
            <NavLink
              to={`/permit-to-work-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-permit-to-work"
            >
              <DarkTooltip
                title={t('tooltip.ssss.DigitalisedPermitToWorkSystem')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <PermitToWorkSvgIcon />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {ssssFeatureList?.HazardousAreasAccessControl && (
            <NavLink
              to={`/daslock-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-daslock"
            >
              <DarkTooltip
                title={t('tooltip.ssss.HazardousAreasAccessControl')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <DasLockSvgIcon />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {ssssFeatureList?.[
            'UnsafeActs/DangerousSituationAlertForMobilePlantOperationDangerZone'
          ] && (
            <NavLink
              to={`/dastrack-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dastrack"
            >
              <DarkTooltip
                title={t(
                  'tooltip.ssss.UnsafeActs/DangerousSituationAlertForMobilePlantOperationDangerZone',
                )}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={plantSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {ssssFeatureList?.[
            'UnsafeActs/DangerousSituationAlertForTowerCraneLiftingZone'
          ] && (
            <NavLink
              to={`/dascas-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dascas"
            >
              <DarkTooltip
                title={t(
                  'tooltip.ssss.UnsafeActs/DangerousSituationAlertForTowerCraneLiftingZone',
                )}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={dascasSvg} alt="DasCAS" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {ssssFeatureList?.SmartMonitoringDevices && (
            <NavLink
              to={`/dasloop-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dasloop"
            >
              <DarkTooltip
                title={t('tooltip.ssss.SmartMonitoringDevices')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={dasloopSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {ssssFeatureList?.SafetyMonitoringSystem && (
            <NavLink
              to={`/safety-monitoring-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dasloop"
            >
              <DarkTooltip
                title={t('tooltip.ssss.SafetyMonitoringSystem')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <AiCameraSvgIcon />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {ssssFeatureList?.ConfinedSpaceMonitoringSystem && (
            <NavLink
              to={`/confined-space-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dasloop"
            >
              <DarkTooltip
                title={t('tooltip.ssss.ConfinedSpaceMonitoringSystem')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <ConfinedSpaceSvgIcon />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {ssssFeatureList?.SafetyTrainingWithVirtualRealityTechnology && (
            <NavLink
              to={`/safety-training-vr-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-dasloop"
            >
              <DarkTooltip
                title={t(
                  'tooltip.ssss.SafetyTrainingWithVirtualRealityTechnology',
                )}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <VrSvgIcon />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
        </div>
        {topScroll.posUp && (
          <IconContainer
            onClick={topScroll.handleDown}
            sx={{ width: '50px', height: 'unset', cursor: 'pointer' }}
          >
            <KeyboardArrowDown />
          </IconContainer>
        )}
        <Divider />{' '}
        {bottomScroll.posDown && (
          <IconContainer
            onClick={bottomScroll.handleTop}
            sx={{ width: '50px', height: 'unset', cursor: 'pointer' }}
          >
            <KeyboardArrowUp />
          </IconContainer>
        )}
        <div
          className="switch-item"
          ref={(ref) => {
            if (ref && ref.scrollHeight > ref.clientHeight) {
              bottomScroll.setRef(ref);
            }
          }}
        >
          {overviewReadable && (
            <NavLink
              to={`/overview-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-overview"
            >
              <DarkTooltip
                title={t('tooltip.project-overview')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={overviewSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {reportReadable && (
            <NavLink
              to={`/report-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-report"
            >
              <DarkTooltip
                title={t('tooltip.report')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={reportSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {alertReadable && (
            <NavLink
              to={`/alert-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-alert"
            >
              <DarkTooltip
                title={t('tooltip.alert')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={alertDashboard} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {PAreadable && (
            <NavLink
              to={`public-announcement/${selectedProjectId}`}
              data-cy="nav-sidebar-public-announcement"
            >
              <DarkTooltip
                title={t('tooltip.public-announcement')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={paSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {areaWriteable && (
            <NavLink
              to={`/area-management-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-area-management"
            >
              <DarkTooltip
                title={t('tooltip.area')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={areaManagementSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.tunnel && (
            <NavLink
              to={`/tunnel-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-tunnel"
            >
              <DarkTooltip
                title={t('tooltip.tunnel')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={tunnelSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
          {dataFeatures?.report && (
            <NavLink
              to={`/data-download-dashboard/${selectedProjectId}`}
              data-cy="nav-sidebar-data-download"
            >
              <DarkTooltip
                title={t('tooltip.data-download')}
                placement="right"
                enterDelay={enterDelay}
              >
                <div>
                  <IconContainer className="icon">
                    <img src={downloadSvg} alt="" />
                  </IconContainer>
                </div>
              </DarkTooltip>
            </NavLink>
          )}
        </div>
        {bottomScroll.posUp && (
          <IconContainer
            onClick={bottomScroll.handleDown}
            sx={{ width: '50px', height: 'unset', cursor: 'pointer' }}
          >
            <KeyboardArrowDown />
          </IconContainer>
        )}
      </div>
      <NavLink to={`/log/${selectedProjectId}`} data-cy="nav-sidebar-log">
        <DarkTooltip
          title={t('tooltip.log')}
          placement="right"
          enterDelay={enterDelay}
        >
          <div>
            <IconContainer className="icon">
              <img src={logSvg} alt="" />
            </IconContainer>
          </div>
        </DarkTooltip>
      </NavLink>
      <NavLink
        to={`/project-setting/${selectedProjectId}`}
        data-cy="nav-sidebar-project-setting"
        rel="noopener noreferrer"
      >
        <DarkTooltip
          title={t('tooltip.project-setting')}
          placement="right"
          enterDelay={enterDelay}
        >
          <div>
            <IconContainer className="icon">
              <img src={gearSvg} alt="" />
            </IconContainer>
          </div>
        </DarkTooltip>
      </NavLink>
    </nav>
  );
};

export default SSSSMenu;
