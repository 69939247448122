import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipProps } from '@mui/material';

interface DarkTooltipProps extends TooltipProps {}

const DarkTooltip: React.FC<DarkTooltipProps> = ({
  title,
  children,
  PopperProps = {},
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { sx, ...otherPopperProps } = PopperProps;

  return (
    <Tooltip
      title={title ? title : (t('no-data') as string)}
      placement="right"
      PopperProps={{
        sx: (theme) => {
          return {
            marginTop: '0',
            '& .MuiTooltip-tooltip': {
              color: theme.color.secondary.$40,
              backgroundColor: theme.externalColor.secondary.$140,
              borderRadius: '4px',
              boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
              maxWidth: 'unset',
              padding: '10px',
              ...(sx ?? {})['& .MuiTooltip-tooltip'],
            },
          };
        },
        ...otherPopperProps,
      }}
      {...otherProps}
    >
      <div style={{ display: 'inline-block' }}>{children}</div>
    </Tooltip>
  );
};

export default DarkTooltip;
