import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  Contact,
  DasIdWillAssign,
  Group,
  StatusType,
  WorkerCertificate,
  WorkerCertificateWillAssign,
  WorkerWillAssign,
} from '../../../types';
import { Dasloop } from '../../../types/Device';
import { Worker } from '../../../types/Resource';

import {
  bindDasloopToWorker,
  createWorkerCertificate,
  getProjectDasloops,
  getProjectWorkers,
  getWorkerCertificate,
  unbindDasloop,
  updateWorker,
  updateWorkerCertificate,
  updateWorkerGroupIds,
  uploadAvatarCertificate,
  uploadWorkerAvatar,
} from '../../../apis/DasloopApi';
import {
  bindProjectContact,
  unBindProjectContact,
} from '../../../apis/ProjectApi';
import { useAppSelector } from '../../../hooks';
import useFilteredGroups from '../../../hooks/useFilteredGroups';
import useHasPolicy from '../../../hooks/useHasPolicy';

import CompleteButtonSvgIcon from '../../../assets/SvgIcon/CompleteButtonSvgIcon';
import LoadingButtonSvgIcon from '../../../assets/SvgIcon/LoadingButtonSvgIcon';
import SaveSvgIcon from '../../../assets/SvgIcon/SaveSvgIcon';
import mainTheme from '../../../theme';
import { checkIsArrayEqual } from '../../../utils/checkArrayEqual';
import {
  navigatorWorker as navigator,
  phoneRegex,
} from '../../../utils/common';
import CheckListItem from '../../CheckListItem';
import ConfirmButton from '../../ConfirmButton';
import ManagementDialog from '../../Dialog/ManagementDialog';
import Loading from '../../Loading';
import ManagementNoPermissionContent from '../../ManagementNoPermissionContent';
import CertificateContent from '../edit-content/CertificateContent';
import ConnectedDevice from '../edit-content/ConnectedDeviceContent';
import ContactEdit from '../edit-content/ContactEdit';
import GroupEdit from '../edit-content/GroupEdit';
import BasicInformationContent from '../edit-content/WorkerBasicInformationContent';
import {
  checkIsAvatarUpdated,
  checkIsDasIdEqual,
} from '../validation/management-validation';
import {
  checkIsArrayAvatarUpdated,
  checkisCertificateEdited,
  checkIsFormWorkerEdited,
  checkWorkerBasicInformationAvailable,
  checkWorkerCertificate,
  checkWorkerContactAvailable,
  checkWorkerDasloopConnected,
} from '../validation/worker-validation';

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  display: flex;
  flex: 1;
`;

const ContentWrapper = styled('div')`
  width: 340px;
  border-radius: 4px;
  display: flex;
  flex: 1;

  & .loading {
    height: 100%;
  }
`;

const DialogActions = styled(MuiDialogActions)`
  display: flex;
  padding: 24px;
  height: 80px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  width: 280px;
  margin-top: 20px;
`;

interface EditWorkerDialogProps extends DialogProps {
  title: string;
  navigatorIndex: number;
  groups: Array<Group> | undefined;
  selectedResource: Worker | undefined;
  selectedGroups: Array<Group>;
  resourceContacts: Array<Contact>;
  connectedDevice: Array<Dasloop>;
  onSuccessCreateWorker: () => void;
  onSuccessBind?: () => void;
  onCloseDialog: () => void;
  onSelectNavigatorIndex: (index: number) => void;
}

const EditWorker: React.FC<EditWorkerDialogProps> = ({
  open,
  title,
  navigatorIndex,
  groups = [],
  resourceContacts,
  selectedGroups,
  onCloseDialog,
  onSuccessCreateWorker,
  onSuccessBind,
  onSelectNavigatorIndex,
}) => {
  const projectId = useParams().projectId;
  const [searchParams] = useSearchParams();
  const { policies, role: userProjectRole } = useAppSelector(
    (store) => store.projects,
  );
  const queryClient = useQueryClient();
  const { mutateAsync: mutateUpdateWorker } = useMutation(updateWorker);
  const { mutateAsync: mutateUploadAvatar } = useMutation(uploadWorkerAvatar);
  const { mutateAsync: mutateUpdateWorkerGroups } =
    useMutation(updateWorkerGroupIds);
  const { t } = useTranslation('project-setting');
  const [checkedValue, setCheckedValue] = useState(
    new Array(navigator.length).fill(false),
  );
  const [saveButtonStatus, setSaveButtonStatus] = useState<
    'loading' | 'success'
  >();
  const [workerDataWillAssign, setWorkerDataWillAssign] =
    useState<WorkerWillAssign>();
  const [workerGroupWillAssign, setWorkerGroupWillAssign] = useState<
    Array<string>
  >([]);
  const [listContact, setListContact] = useState<string[]>(
    resourceContacts.map((contact) => contact.id),
  );

  const [workerCertificateWillAssign, setWorkerCertificateWillAssign] =
    useState<Array<WorkerCertificateWillAssign>>([]);
  const [assignedDasLoopFirstInit, setAssignedDasLoopFirstInit] =
    useState(false);
  const [dasloopWillAssign, setDasloopWillAssign] = useState<
    Array<DasIdWillAssign>
  >([]);

  const [initDasId, setInitDasId] = useState<Array<DasIdWillAssign>>([]);
  const [status, setStatus] = useState<StatusType>('default');
  const [message, setMessage] = useState('');
  const [checkedNavigator, setCheckedNavigator] = useState(
    new Array(navigator.length).fill(false),
  );
  const hasBindDevicePolicy = useHasPolicy(policies, 'WriteDeviceBinding');
  const initContact = resourceContacts.map((contact) => contact.id);

  const { mutateAsync: mutateUnbindContact } =
    useMutation(unBindProjectContact);

  const { mutateAsync: mutateBindContact } = useMutation(bindProjectContact);

  const { mutateAsync: mutateBindDasloop } = useMutation(bindDasloopToWorker);
  const { mutateAsync: mutateUnbindDasloop } = useMutation(unbindDasloop);

  const filteredGroups = useFilteredGroups(
    'WriteOneWorkerGroup',
    groups,
    userProjectRole,
    policies,
  );

  const workerId = useMemo(() => {
    return searchParams.get('workerId');
  }, [searchParams]);

  const { data: worker } = useQuery(
    ['get-worker-by-id', projectId, workerId],
    async () => {
      const res = await getProjectWorkers({
        projectId: projectId as string,
        params: {
          id: workerId as string,
        },
      });

      if (res.data.data.length === 1) return res.data.data[0];
    },
    {
      enabled: !!projectId && !!workerId,
      refetchOnWindowFocus: false,
    },
  );

  const { data: assignedDasLoops } = useQuery(
    ['assigned-dasloops', projectId, worker?.id],
    async () => {
      let dasLoops: Dasloop[] = [];
      const bindingDasloops = worker?.bindingDasloops;
      if (bindingDasloops) {
        for (let i = 0; i < bindingDasloops.length; i++) {
          const res = await getProjectDasloops({
            projectId: projectId as string,
            params: {
              dasId: bindingDasloops[i],
              limit: 1,
            },
          });

          if (res.data.data.length === 1) {
            dasLoops.push(res.data.data[0]);
          }
        }
      }
      return dasLoops;
    },
    {
      enabled: !!projectId && !!worker,
      initialData: [],
      refetchOnWindowFocus: false,
    },
  );

  const { data: workerCertificates, isFetching: isFethingCertificates } =
    useQuery(
      ['get-worker-certificates', projectId, worker?.id],
      async () => {
        const res = await getWorkerCertificate({
          projectId: projectId as string,
          workerId: worker?.id as string,
        });

        return res.data.data;
      },
      {
        enabled: !!worker,
        refetchOnWindowFocus: false,
        retry: false,
      },
    );

  useEffect(() => {
    setWorkerDataWillAssign({
      name: worker?.name ?? '',
      imageURL: worker?.imageURL ?? '',
      gender: worker?.gender as 'male' | 'female',
      birthday: worker?.birthday,
      email: worker?.email,
      phone: worker?.phone ?? '',
      division: worker?.division,
      trade: worker?.trade,
      registrationNumber: worker?.registrationNumber ?? null,
      groupIds: worker?.groupIds ?? [],
      remark: worker?.remark,
      orgId: worker?.orgId ?? '',
      id: worker?.id ?? '',
    });
  }, [worker]);

  useEffect(() => {
    if (open) {
      setWorkerGroupWillAssign(selectedGroups.map((group) => group.id));
    }
  }, [open, selectedGroups]);

  useEffect(() => {
    if (workerCertificates) {
      const initCertificate: WorkerCertificateWillAssign[] =
        workerCertificates.map((certificate) => ({
          name: certificate.name,
          number: certificate.number,
          expiryDate: certificate.expiryDate,
          imageURL: certificate.imageURL,
          id: certificate.id,
        }));
      setWorkerCertificateWillAssign(initCertificate);
    }
  }, [workerCertificates]);

  useEffect(() => {
    if (assignedDasLoops.length > 0 && assignedDasLoopFirstInit === false) {
      const init: Array<DasIdWillAssign> = assignedDasLoops.map((dasloop) => ({
        id: dasloop.id,
        name: dasloop.dasId,
        batteryLevel: dasloop.shadow?.dataPoint?.batteryLevel?.value
          ? dasloop.shadow.dataPoint.batteryLevel.value
          : 0,
      }));
      setDasloopWillAssign(init);
      setInitDasId(init);
      setAssignedDasLoopFirstInit(true);
    }
  }, [assignedDasLoops, assignedDasLoopFirstInit]);

  const saveable = () => {
    if (
      worker &&
      workerDataWillAssign &&
      resourceContacts &&
      workerCertificates
    ) {
      if (
        !checkIsArrayEqual(worker.groupIds, workerGroupWillAssign) ||
        !checkIsDasIdEqual(initDasId, dasloopWillAssign) ||
        !checkIsArrayEqual(initContact, listContact) ||
        checkIsAvatarUpdated(workerDataWillAssign.avatarFile) ||
        checkIsArrayAvatarUpdated(workerCertificateWillAssign) ||
        checkIsFormWorkerEdited(worker, workerDataWillAssign) ||
        checkisCertificateEdited(
          workerCertificates,
          workerCertificateWillAssign,
        )
      ) {
        return true;
      }
    }

    return false;
  };

  const handleWorkerDataWillAssign = (workerData: WorkerWillAssign) => {
    setWorkerDataWillAssign(workerData);
  };

  const handleCertificateWillAssign = (
    certificate: Array<WorkerCertificateWillAssign>,
  ) => {
    setWorkerCertificateWillAssign(certificate);
  };

  useEffect(() => {
    const updateChecked = checkedValue.map((item, index) =>
      index === navigatorIndex ? true : false,
    );
    setCheckedValue(updateChecked);
  }, [navigatorIndex]);

  const handleCloseEditDialog = () => {
    onCloseDialog();
    setWorkerDataWillAssign(undefined);
    setListContact([]);
    setWorkerCertificateWillAssign([]);
    setWorkerGroupWillAssign([]);
    setInitDasId([]);
    onSelectNavigatorIndex(1);
    setDasloopWillAssign([]);
    setStatus('default');
    setMessage('');
  };

  const couldBindDevice =
    userProjectRole === 'owner' ||
    userProjectRole === 'admin' ||
    hasBindDevicePolicy;

  const unbindContacts = async (contactIds: string[]) => {
    const requestUnbindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateUnbindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: workerDataWillAssign?.id ?? '',
      });
      return data;
    });
    await Promise.all(requestUnbindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries(['project-contacts', projectId as string]);
  };

  const bindContacts = async (contactIds: string[]) => {
    const requestBindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateBindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: workerDataWillAssign?.id ?? '',
        type: 'worker',
      });
      return data;
    });
    await Promise.all(requestBindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries(['project-contacts', projectId as string]);
  };

  const handleUpdoadAvatar = async (wid: string, file: File) => {
    await mutateUploadAvatar({
      projectId: projectId ?? '',
      workerId: wid,
      imageFile: file,
    });
  };

  const handleUpdateWorker = async (
    workerData: WorkerWillAssign,
    wid: string,
  ) => {
    const {
      name,
      orgId,
      birthday,
      gender,
      email,
      phone,
      division,
      trade,
      registrationNumber,
      remark,
    } = workerData;

    let fullPhone: string | undefined;
    const phoneResult = phoneRegex.exec(phone);
    if (phoneResult && phoneResult.length >= 3 && phoneResult[2] === '') {
      fullPhone = undefined;
    } else if (!phoneResult) {
      fullPhone = undefined;
    } else {
      fullPhone = phone;
    }

    const res = await mutateUpdateWorker(
      {
        projectId: projectId as string,
        workerId: wid,
        payload: {
          orgId,
          name,
          birthday,
          gender,
          email: email ?? undefined,
          phone: fullPhone,
          division: division ?? undefined,
          trade: trade ?? undefined,
          registrationNumber:
            registrationNumber === '' ? null : registrationNumber ?? null,
          remark: remark ?? undefined,
        },
      },
      {
        onError: (error) => {
          if (error instanceof Error) {
            setMessage(error.message);
            setStatus('error');
          }
        },
      },
    );

    return res.data.data;
  };

  const updateWorkerGroup = async () => {
    if (workerGroupWillAssign.length > 0) {
      await mutateUpdateWorkerGroups({
        projectId: projectId as string,
        workerId: workerDataWillAssign?.id ?? '',
        groupIds: workerGroupWillAssign,
      });
    } else {
      setMessage('Must select at least one group');
      setStatus('error');
    }
  };

  const handleCertificate = async () => {
    // UPDATE WORKER CERTIFICATE
    if (workerCertificateWillAssign.length > 0) {
      const requestUpdateCertificates: Promise<{
        data: WorkerCertificate;
      }>[] = [];

      workerCertificateWillAssign.forEach(async (v) => {
        if (v.id) {
          requestUpdateCertificates.push(
            updateWorkerCertificate({
              projectId: projectId ?? '',
              workerId: workerDataWillAssign?.id as string,
              certificateId: v.id ?? '',
              name: v.name ?? '',
              number: v.number ?? '',
              expiryDate: v.expiryDate ?? '',
            }),
          );
          await Promise.all(requestUpdateCertificates).catch((err) => {
            const error = err as Error;
            setMessage(error.message);
            setStatus('error');
            setSaveButtonStatus(undefined);
            return;
          });
          const requestAvatarCertificate: Promise<any>[] = [];
          if (v.imageFile) {
            requestAvatarCertificate.push(
              uploadAvatarCertificate({
                projectId: projectId ?? '',
                workerId: workerDataWillAssign?.id ?? '',
                certificateId: v.id ?? '',
                imageFile: v.imageFile ?? '',
              }),
            );
            await Promise.all(requestAvatarCertificate);
          } else {
            return;
          }
        } else {
          // CREATE NEW CERTIFICATE
          const requestNewCertificates: Promise<{
            data: { data: WorkerCertificate };
          }>[] = [];
          requestNewCertificates.push(
            createWorkerCertificate({
              projectId: projectId ?? '',
              workerId: workerDataWillAssign?.id ?? '',
              name: v.name ?? '',
              number: v.number ?? '',
              expiryDate: v.expiryDate ?? '',
            }),
          );
          const certificateData = await Promise.all(requestNewCertificates);

          certificateData.forEach(async (certificate) => {
            const requestAvatarCertificate: Promise<any>[] = [];
            if (v.imageFile) {
              requestAvatarCertificate.push(
                uploadAvatarCertificate({
                  projectId: projectId ?? '',
                  workerId: workerDataWillAssign?.id ?? '',
                  certificateId: certificate.data.data.id ?? '',
                  imageFile: v.imageFile,
                }),
              );
              await Promise.all(requestAvatarCertificate);
            } else {
              return;
            }
          });
        }
      });
    }
  };

  const handleBindDasloop = async (dasIds: DasIdWillAssign[], id: string) => {
    const requestBindDasloop = dasIds
      .filter((dasId) => dasId.id !== '')
      .map(async (dasId) => {
        const { data } = await mutateBindDasloop({
          projectId: projectId as string,
          dasId: dasId.name,
          workerId: id,
        });
        return data;
      });
    await Promise.all(requestBindDasloop).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    });
  };

  const handleUnbindDasloop = async (dasIds: DasIdWillAssign[]) => {
    const requestUnbindDasloop = dasIds.map(async (d) => {
      const { data } = await mutateUnbindDasloop({
        dasId: d.name,
        projectId: projectId as string,
      });
      return data;
    });

    await Promise.all(requestUnbindDasloop).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    });
  };

  const handleSubmitWorker = async () => {
    if (!workerDataWillAssign) return;
    setSaveButtonStatus('loading');
    setStatus('default');
    try {
      await handleUpdateWorker(workerDataWillAssign, workerDataWillAssign.id);

      await updateWorkerGroup();

      if (workerDataWillAssign.avatarFile) {
        await handleUpdoadAvatar(
          workerDataWillAssign.id,
          workerDataWillAssign.avatarFile,
        );
      }

      // UNBIND CONTACT REQUEST
      const listContactUnbind = initContact.filter(
        (id) => !listContact.includes(id),
      );
      if (listContactUnbind.length > 0) {
        unbindContacts(listContactUnbind);
      }

      // BIND CONTACT REQUEST
      const listContactWillBind = listContact.filter(
        (id) => !initContact.includes(id),
      );
      if (listContactWillBind.length > 0) {
        bindContacts(listContactWillBind);
      }

      await handleCertificate();

      if (couldBindDevice && dasloopWillAssign.length > 0) {
        await handleBindDasloop(dasloopWillAssign, workerDataWillAssign.id);
      }

      const dasIdWillUnbind = initDasId.filter(
        (item) => !dasloopWillAssign.some((dw) => dw.name === item.name),
      );

      if (couldBindDevice && dasIdWillUnbind.length > 0) {
        await handleUnbindDasloop(dasIdWillUnbind);
      }

      const onSuccsess = () => {
        setSaveButtonStatus('success');
        onSuccessCreateWorker();
        onSuccessBind?.();
      };

      let timer: NodeJS.Timeout;
      let timer2: NodeJS.Timeout;
      timer = setTimeout(() => {
        onSuccsess();
        handleCloseEditDialog();

        timer2 = setTimeout(() => {
          setSaveButtonStatus(undefined);
        }, 500);
      }, 500);
      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } catch (err) {
      setSaveButtonStatus(undefined);
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    }
  };

  let saveButtonStatusIcon;
  switch (saveButtonStatus) {
    case 'loading':
      saveButtonStatusIcon = <LoadingButtonSvgIcon />;
      break;
    case 'success':
      saveButtonStatusIcon = <CompleteButtonSvgIcon />;
      break;
    default:
      saveButtonStatusIcon = <SaveSvgIcon />;
  }

  useEffect(() => {
    setCheckedNavigator(
      checkedNavigator.map((_, index) =>
        index === navigatorIndex ? true : false,
      ),
    );
  }, [navigatorIndex]);

  const handleOnChange = (value: any) => {
    const contactId = value;
    const isContain = listContact.includes(contactId);
    if (!isContain) {
      setListContact([...listContact, contactId]);
    } else {
      setListContact(listContact.filter((item) => item !== contactId));
    }
  };

  return (
    <ManagementDialog
      open={open}
      onClose={handleCloseEditDialog}
      status={status}
      message={message}
      title={title}
    >
      {isFethingCertificates ? (
        <Loading />
      ) : (
        <>
          <DialogContent>
            <CheckList>
              {navigator.map((item, index) => {
                let checked: boolean = false;
                switch (item.name) {
                  case 'group':
                    checked = true;
                    break;
                  case 'worker-basic-information':
                    checked =
                      checkWorkerBasicInformationAvailable(
                        workerDataWillAssign,
                      );
                    break;
                  case 'emergency-contact':
                    checked = checkWorkerContactAvailable(listContact);
                    break;
                  case 'certificate':
                    checked = checkWorkerCertificate(
                      workerCertificateWillAssign,
                    );
                    break;
                  case 'connected-device':
                    checked = checkWorkerDasloopConnected(dasloopWillAssign);
                    break;
                }
                return (
                  <CheckListItem
                    key={item.id}
                    name={t(`navigator.${item.name}`)}
                    required={item.required}
                    checked={checked}
                    onClick={() => onSelectNavigatorIndex(index)}
                    selected={checkedValue[index]}
                    data-cy={`btn-list-navigator-worker-${item.name}`}
                  />
                );
              })}
            </CheckList>
            <ContentWrapper className="content wrapper">
              {navigator[navigatorIndex].name === 'group' && (
                <GroupEdit
                  groupIdsSelected={workerGroupWillAssign}
                  handleGroupAssign={(groupIds) =>
                    setWorkerGroupWillAssign(groupIds)
                  }
                  listGroup={filteredGroups}
                  resource="worker"
                />
              )}
              {navigator[navigatorIndex].name ===
                'worker-basic-information' && (
                <BasicInformationContent
                  handleWorkerDataWillAssign={handleWorkerDataWillAssign}
                  data={workerDataWillAssign}
                />
              )}
              {navigator[navigatorIndex].name === 'emergency-contact' && (
                <ContactEdit
                  onChange={handleOnChange}
                  listContact={listContact}
                />
              )}
              {navigator[navigatorIndex].name === 'certificate' && (
                <CertificateContent
                  handleCertificateWillAssign={handleCertificateWillAssign}
                  data={workerCertificateWillAssign}
                />
              )}
              {navigator[navigatorIndex].name === 'connected-device' && (
                <>
                  {couldBindDevice ? (
                    <ConnectedDevice
                      projectId={projectId}
                      resourceType="worker"
                      data={dasloopWillAssign}
                      handleDasIDWillAssign={(dasIds) =>
                        setDasloopWillAssign(dasIds)
                      }
                    />
                  ) : (
                    <ManagementNoPermissionContent />
                  )}
                </>
              )}
            </ContentWrapper>
          </DialogContent>
          <DialogActions>
            <DialogButton
              sx={{
                '&.Mui-disabled': {
                  color: 'white',
                },
                '&:hover, &:active': {
                  background: mainTheme.color.secondary.$60,
                },
                marginRight: 'auto',
              }}
              color="secondary"
              onClick={handleCloseEditDialog}
              data-cy="btn-cancel-edited-worker"
            >
              {t('cancel')}
            </DialogButton>
            <ConfirmButton
              sx={{
                '& .MuiButton-startIcon > svg': {
                  width: '32px',
                  height: '32px',
                },
              }}
              startIcon={saveButtonStatusIcon}
              status={saveButtonStatus}
              onClick={handleSubmitWorker}
              disabled={!saveable()}
              data-cy="btn-save-edited-worker"
            >
              {t('save')}
            </ConfirmButton>
          </DialogActions>
        </>
      )}
    </ManagementDialog>
  );
};

export default EditWorker;
