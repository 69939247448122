import { Avatar, CircularProgress } from '@mui/material';

import { DascasType, DeviceType, FieldType, ResourceType } from '../../types';

import balkDefaultPng from '../../assets/images/default-image/balk_default.png';
import DefaultImage from '../../assets/images/default-image/default_blank.jpg';
import DefaultEquipment from '../../assets/images/default-image/equipment_default.png';
import floorDefaultPng from '../../assets/images/default-image/floor_default.png';
import pilarDefaultPng from '../../assets/images/default-image/pilar_default.png';
import DefaultPipe from '../../assets/images/default-image/pipe_default.png';
import DefaultPlant from '../../assets/images/default-image/plant_default.png';
import DefaultVehicle from '../../assets/images/default-image/vehicle_default.png';
import wallDefaultPng from '../../assets/images/default-image/wall_default.png';
import DefaultWorker from '../../assets/images/default-image/worker_default.png';
import groundDascasSvg from '../../assets/images/svg/ground-dascas.svg';

import AddBuildingSvgIcon from './AddBuildingSvgIcon';
import AddGroupSvgIcon from './AddGroupSvgIcon';
import AddMemberSvgIcon from './AddMemberSvgIcon';
import AddPouringArea from './AddPouringArea';
import AddStructureSvgIcon from './AddStructureSvgIcon';
import CompleteButtonSvgIcon from './CompleteButtonSvgIcon';
import DasairSvgColor from './DasairSvgColor';
import DasairSvgColorMedium from './DasairSvgColorMedium';
import DasAiServerSvgColor from './DasAiServerSvgColor';
import DasAIServerSvgIcon from './DasAIServerSvgIcon';
import DasAoASvgColor from './DasAoASvgColor';
import DasAoATagSvgColor from './DasAoATagSvgColor';
import DasBeaconSvgColor from './DasBeaconSvgColor';
import DascasSvgColor from './DascasSvgColor';
import DascasTagSvgColorMedium from './DascasTagSvgColorMedium';
import DasconcreteColorSvgIcon from './DasconcreteColorSvgIcon';
import DasconcreteSvgColor from './DasconcreteSvgColor';
import DasGasSvgColor from './DasGasSvgColor';
import DaslockSvgColor from './DaslockSvgColor';
import DaslockSvgColorMedium from './DaslockSvgColorMedium';
import DasloopLiveCallSvgColor from './DasloopLiveCallSvgColor';
import DasloopSvgColor from './DasloopSvgColor';
import DasloopSvgColorMedium from './DasloopSvgColorMedium';
import DaspowerSvgColor from './DaspowerSvgColor';
import DaspowerSvgColorMedium from './DaspowerSvgColorMedium';
import DastempSvgColor from './DastempSvgColor';
import DastempSvgColorMedium from './DastempSvgColorMedium';
import DastrackSvgColor from './DastrackSvgColor';
import DastrackSvgColorMedium from './DastrackSvgColorMedium';
import DastrackVSvgColor from './DastrackVSvgColor';
import DastrackVSvgColorMedium from './DastrackVSvgColorMedium';
import DaswaterSvgColor from './DaswaterSvgColor';
import DaswaterSvgColorMedium from './DaswaterSvgColorMedium';
import EmptyIconSvg from './EmptyIconSvg';

export const handleIconMedium = (iconType: DeviceType) => {
  switch (iconType) {
    case 'dasloop':
      return <DasloopSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'dastrack':
      return <DastrackSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'dastrack-v':
      return <DastrackVSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'daspower':
      return <DaspowerSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'dastemp':
      return <DastempSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'dasair':
      return <DasairSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'daswater':
      return <DaswaterSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'daslock':
      return <DaslockSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'dascas':
      return <DascasSvgColor />;
    case 'dasconcrete':
      return <DasconcreteSvgColor />;
    default:
      return <EmptyIconSvg sx={{ width: '40px', height: '40px' }} />;
  }
};

export const getIconBond = (resource: ResourceType) => {
  switch (resource) {
    case 'worker':
      return <DasloopSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'plant':
      return <DastrackSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'crane':
      return <DascasSvgColor />;
    case 'vehicle':
      return <DastrackVSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'equipment':
      return <DaspowerSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'pipe':
      return <DaswaterSvgColorMedium sx={{ width: '40px', height: '40px' }} />;
    case 'structure':
      return <DasconcreteSvgColor />;
    case 'ai':
      return <DasAiServerSvgColor />;
    default:
      return <EmptyIconSvg sx={{ width: '40px', height: '40px' }} />;
  }
};

export const handleIcon = (iconType: DeviceType) => {
  switch (iconType) {
    case 'dasloop':
      return <DasloopSvgColor />;
    case 'dasloop-live-call':
      return <DasloopLiveCallSvgColor />;
    case 'dasgas':
      return <DasGasSvgColor />;
    case 'dastrack':
      return <DastrackSvgColor />;
    case 'dascas':
      return <DascasSvgColor />;
    case 'dastrack-v':
      return <DastrackVSvgColor />;
    case 'daspower':
      return <DaspowerSvgColor />;
    case 'dastemp':
      return <DastempSvgColor />;
    case 'dasair':
      return <DasairSvgColor />;
    case 'daswater':
      return <DaswaterSvgColor />;
    case 'beacon':
      return <DasBeaconSvgColor />;
    case 'aoa-ble':
    case 'aoa-uwb':
      return <DasAoASvgColor />;
    case 'aoa-tag':
      return <DasAoATagSvgColor />;
    case 'daslock':
      return <DaslockSvgColor />;
    case 'dasconcrete':
      return <DasconcreteColorSvgIcon />;
    case 'das_ai_box':
      return <DasAIServerSvgIcon />;
    default:
      return <EmptyIconSvg />;
  }
};

export const handleIconDascas = (iconType: DascasType) => {
  switch (iconType) {
    case 'dascas':
    case 'dascas_alarm':
      return <DastrackSvgColor />;
    case 'dascas_g':
      return (
        <Avatar
          src={groundDascasSvg}
          sx={{
            width: '32px',
            height: '32px',
          }}
        />
      );
    case 'dascas_tag':
      return (
        <DascasTagSvgColorMedium
          sx={{
            width: '32px',
            height: '32px',
          }}
        />
      );
    default:
      return <EmptyIconSvg />;
  }
};

export const handleDefaultImage = (deviceType: DeviceType) => {
  switch (deviceType) {
    case 'dastrack':
      return <Avatar src={DefaultPlant} alt="plant beeinventor" />;
    case 'dascas':
      return <Avatar src={DefaultPlant} alt="plant beeinventor" />;
    case 'dastrack-v':
      return <Avatar src={DefaultVehicle} alt="plant beeinventor" />;
    case 'dasloop':
      return <Avatar src={DefaultWorker} alt="worker beeinventor" />;
    case 'dasgas':
      return <Avatar src={DefaultWorker} alt="worker beeinventor" />;
    case 'daspower':
      return <Avatar src={DefaultEquipment} alt="daspower beeinventor" />;
    case 'dastemp':
      return <Avatar src={DefaultEquipment} alt="dastemp beeinventor" />;
    case 'daslock':
      return <Avatar src={DefaultEquipment} alt="dastemp beeinventor" />;
    case 'daswater':
      return <Avatar src={DefaultPipe} alt="daswater beeinventor" />;
    default:
      return <Avatar src={DefaultImage} alt="blank beeinventor" />;
  }
};

export const handleAddIcon = (resource: ResourceType | 'pouring-area') => {
  switch (resource) {
    case 'field':
      return <AddBuildingSvgIcon sx={{ width: '32px', height: '32px' }} />;
    case 'pouring-area':
      return <AddPouringArea sx={{ width: '32px', height: '32px' }} />;
    case 'worker':
      return <AddMemberSvgIcon sx={{ width: '32px', height: '32px' }} />;
    case 'crane':
      return <AddGroupSvgIcon sx={{ width: '32px', height: '32px' }} />;
    case 'structure':
      return <AddStructureSvgIcon sx={{ width: '32px', height: '32px' }} />;
    default:
      return <AddGroupSvgIcon sx={{ width: '32px', height: '32px' }} />;
  }
};

export const handleStatusButton = (t: any, statusIcon) => {
  switch (statusIcon) {
    case 'loading':
      return <CircularProgress size={16} />;
    case 'success':
      return <CompleteButtonSvgIcon />;
    default:
      return <>{t('save')}</>;
  }
};

export const handleDefaultStructureImage = (fieldType: FieldType) => {
  let defaultImage: string | undefined;
  switch (fieldType) {
    case 'balk':
      defaultImage = balkDefaultPng;
      break;
    case 'floor':
      defaultImage = floorDefaultPng;
      break;
    case 'pillar':
      defaultImage = pilarDefaultPng;
      break;
    case 'wall':
      defaultImage = wallDefaultPng;
      break;
    default:
      defaultImage = wallDefaultPng;
  }
  return defaultImage;
};
