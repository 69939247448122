import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface UwbAlertSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const UwbAlertSvgIcon = ({ disabled, ...props }: UwbAlertSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5774 19.5515L15.0063 10.8568H25.0063L26.4847 19.8553H26.4775C26.4924 19.9729 26.4999 20.0918 26.4999 20.2119C26.4999 22.5675 23.5898 24.4771 20 24.4771C16.4101 24.4771 13.5 22.5675 13.5 20.2119C13.5 19.9873 13.5265 19.7667 13.5774 19.5515Z"
      fill="currentColor"
    />
    <ellipse
      rx="4.99999"
      ry="2.85714"
      transform="matrix(1 0 0 -1 20 10.8571)"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4999 21.2469H11L10 26.4417H10.0067C10.0024 26.4563 10 26.4701 10 26.4831C10 29.8111 14.4772 32.509 20 32.509C25.5228 32.509 30 29.8111 30 26.4831C30 26.4701 29.9977 26.4562 29.9934 26.4417H30L29 21.2469H28.4999C28.4996 24.4027 24.6941 26.9609 19.9999 26.9609C15.3056 26.9609 11.5002 24.4027 11.4999 21.2469Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4425 18.6232C11.4993 19.5252 10.9502 20.606 10.9502 21.7677C10.9502 24.9237 15.002 27.482 20.0002 27.482C24.9984 27.482 29.0502 24.9237 29.0502 21.7677C29.0502 20.6054 28.5006 19.5242 27.5565 18.6219L27.6955 19.5384C27.7002 19.5674 27.7046 19.5965 27.7087 19.6256L27.713 19.6539C27.7173 19.6826 27.7208 19.7113 27.7232 19.74C27.7408 19.8956 27.7498 20.0528 27.7498 20.2114C27.7498 21.9919 26.6527 23.4138 25.2635 24.3254C23.8583 25.2474 21.994 25.7753 19.9998 25.7753C18.0056 25.7753 16.1413 25.2474 14.7361 24.3254C13.3469 23.4138 12.2498 21.9919 12.2498 20.2114C12.2498 20.0568 12.2583 19.9034 12.2751 19.7515C12.2776 19.719 12.2813 19.6864 12.2863 19.6539L12.4425 18.6232Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default UwbAlertSvgIcon;
