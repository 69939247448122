import { Coordinates, DasIdWillAssign, DeviceType } from '../../../types';

import i18n from '../../../i18n';

export const hasAssignedDevice = (devices: DasIdWillAssign[]) => {
  if (devices.length === 0) return false;
  return devices.reduce((prev, curr) => {
    if (curr.id) return prev && true;
    return false;
  }, true);
};

export const checkNameFormat = (name) => {
  if (name === '') {
    return {
      isError: true,
      message: i18n.t('error:inputIsEmpty'),
    };
  }
  return { isError: false, message: '' };
};

export const checkAlertInterval = (value: number) => {
  if (value < 5) {
    return {
      isError: true,
      message: i18n.t('error:minimumInputValue', { value: 5 }),
    };
  }
  return { isError: false, message: '' };
};

export const hasGroupsIds = (groupIds: Array<string>) => {
  return groupIds.length > 0;
};

export const checkResourceHasContact = (contacts: string[]) => {
  if (contacts.length === 0) return false;
  return true;
};

export const checkhasAssignedDevice = (
  devices: Array<{ id: string; type: DeviceType; dasId: string }>,
) => {
  if (devices.length === 0) return false;
  return devices.reduce((prev, curr) => {
    if (curr.id) return prev && true;
    return false;
  }, true);
};

const areLocationsEqual = (
  location1?: Coordinates | null,
  location2?: Coordinates | null,
): boolean => {
  if (!location1 && !location2) {
    return true;
  }
  if (!location1 || !location2) {
    return false;
  }
  return (
    location1.lat === location2.lat &&
    location1.lon === location2.lon &&
    location1.alt === location2.alt
  );
};

export const checkIsDasIdEqual = (
  dasIdInit: DasIdWillAssign[],
  dasIdAssign: DasIdWillAssign[],
): boolean => {
  let n = dasIdInit.length;
  let m = dasIdAssign.length;

  let initIds = [...dasIdInit].sort((a, b) => a.name.localeCompare(b.name));
  let assignIds = [...dasIdAssign].sort((a, b) => a.name.localeCompare(b.name));

  if (n !== m) {
    return false;
  } else {
    for (let i = 0; i < n; i += 1) {
      if (initIds[i].name !== assignIds[i].name) {
        return false;
      }
      if (initIds[i].location && assignIds[i].location) {
        if (!areLocationsEqual(initIds[i].location, assignIds[i].location)) {
          return false;
        }
      }
    }
  }

  return true;
};

export const checkIdEqual = (
  idSelected: string | undefined,
  idAssign: string | undefined,
) => {
  if (idSelected !== idAssign) return false;
  return true;
};

export const checkIsAvatarUpdated = (avatar: File | undefined) => {
  if (avatar) {
    return true;
  }
  return false;
};

export const checkEmtpy = (data) => {
  if (data === '') {
    return {
      isError: true,
      message: i18n.t('error:inputIsEmpty'),
    };
  }
  return { isError: false, message: '' };
};

export const checkYear = (data) => {
  if (!/^\d{4}$/.test(data)) {
    return {
      isError: true,
      message: i18n.t('error:notYear'),
    };
  }
  return { isError: false, message: '' };
};

export const checkEmptyNumber = (data) => {
  if (data === null || data === undefined) {
    return {
      isError: true,
      message: i18n.t('error:inputIsWrong'),
    };
  }
  return { isError: false, message: '' };
};
