/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  InputBase,
  InputBaseProps,
  styled,
} from '@mui/material';

import ErrorSvg from '../../assets/images/svg/ic_error_ifo.svg';

const Container = styled(Box, { label: 'Input-Container' })`
  display: inline-block;
  width: 100%;
  text-align: center;
  border-radius: 4px;
`;

type StyledInputProps = {
  isFocus?: boolean;
  disabled?: boolean;
  borderColor?: string;
};

// prettier-ignore
const StyledInput = styled(InputBase, { shouldForwardProp: (prop) => !/isFocus|borderColor/.test(prop.toString()) })<StyledInputProps>`
  display: flex;
  width: inherit;
  height: 40px;
  background: rgba(0, 0, 0, 0.2);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  color: white;
  border-color: transparent;
  
  &:focus-within {
    border-color: ${({ borderColor }) => borderColor || 'rgba(0, 0, 0, 0.5)'};
  }

  &:hover {
    background: ${({ disabled }) =>
      disabled ? '#323232' : 'rgba(0, 0, 0, 0.3)'};
  }

  &.Mui-error {
    border-color: #ff6b00;
  }

  &.Mui-disabled {
    color: rgba(255, 255, 255, 0.5);
    background: rgba(0, 0, 0, 0.3);
  }

  & > .MuiInputBase-input {
    ${({theme}) => ({...theme.typography.body2})}
    padding: 0 8px;

    &::placeholder {
      color: ${({ theme }) => theme.color.secondary.$60};
    }

    &.Mui-disabled {
      -webkit-text-fill-color: unset;
      cursor: not-allowed;
    }
  }
  input:-webkit-autofill,
    input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  input[data-autocompleted] {
    background-color: transparent !important;
  }
 
`;

const Error = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  & > span {
    color: #ff6b00;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

interface InputProps extends InputBaseProps {
  containerClassName?: string;
  containerSx?: BoxProps['sx'];
  showErrorIcon?: boolean;
  errorMessage?: string;
}

const Input: React.FC<InputProps> = ({
  containerClassName,
  containerSx,
  errorMessage,
  showErrorIcon = true,
  onFocus,
  onBlur,
  ...otherProps
}) => {
  const [isFocus, setIsFocus] = useState(false);

  const hanldeOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Container className={containerClassName} sx={containerSx}>
      <StyledInput
        onFocus={hanldeOnFocus}
        onBlur={handleOnBlur}
        isFocus={isFocus}
        {...otherProps}
      />
      {errorMessage && errorMessage !== '' && (
        <Error>
          {showErrorIcon && <img src={ErrorSvg} alt="error" />}
          <span>{errorMessage}</span>
        </Error>
      )}
    </Container>
  );
};

export default Input;
