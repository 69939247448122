import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface AreaAlertSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const AreaAlertSvgIcon = ({ disabled, ...props }: AreaAlertSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 21.9176L22.4285 22.5008C21.5428 23.4047 21 24.637 21 26C21 28.7614 23.2386 31 26 31C28.7614 31 31 28.7614 31 26C31 24.637 30.4572 23.4047 29.5715 22.5008L29 21.9176V21.101V13C29 11.3431 27.6569 10 26 10C24.3431 10 23 11.3431 23 13V21.101V21.9176ZM21 13C21 10.2386 23.2386 8 26 8C28.7614 8 31 10.2386 31 13V21.101C32.2372 22.3636 33 24.0927 33 26C33 29.866 29.866 33 26 33C22.134 33 19 29.866 19 26C19 25.723 19.0161 25.4498 19.0474 25.1812L16.0869 27.0003V23.4303L21 20.4114V18.5704L15.3155 22.0615L8.75146 18.0334L15.3104 14L21 17.4961V13ZM26 15C25.4477 15 25 15.4477 25 16V23.1707C23.8348 23.5825 23 24.6938 23 26C23 27.6569 24.3431 29 26 29C27.6569 29 29 27.6569 29 26C29 24.6938 28.1652 23.5825 27 23.1707V16C27 15.4477 26.5523 15 26 15ZM8 22.9827V19.4127L14.5435 23.4303V27.0003L8 22.9827Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default AreaAlertSvgIcon;
