import axios from 'axios';

import { MapboxDirections } from '../types';

interface MapboxForwardGeocodes {
  id: string;
  type: 'Feature';
  place_type: [
    'region',
    'postcode',
    'district',
    'place',
    'locality',
    'neighborhood',
    'address',
    'poi',
  ];
  relevance: number;
  address?: string;
  properties: {
    accuracy?: string;
    address?: string;
    category?: string;
    maki?: string;
    wikidata?: string;
    short_code?: string;
  };
  text: string;
  place_name: string;
  matching_text?: string;
  matching_place_name?: string;
  bbox: [number, number, number, number];
  center: [number, number];
  geometry: {
    type: 'Point';
    coordinates: [number, number];
    interpolated?: boolean;
    omitted?: boolean;
  };
  context: Array<{ id: string; text: string }>;
}

export interface MapboxGeocodingResponse {
  type: 'FeatureCollection';
  query: string[];
  features: MapboxForwardGeocodes[];
  routable_points?: {
    points?: Array<{ coordinates: [number, number] }> | null;
  };
}

const mapboxAxios = axios.create({
  baseURL: 'https://api.mapbox.com',
});

export const forwardGeocoding = (searchText: string, language: string) => {
  return mapboxAxios.get<MapboxGeocodingResponse>(
    `/geocoding/v5/mapbox.places/${searchText}.json`,
    {
      params: {
        access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
        language,
      },
    },
  );
};

export const getDirection = ({
  mapboxToken,
  queryLocation,
}: {
  queryLocation: string;
  mapboxToken: string;
}) => {
  return mapboxAxios.get<MapboxDirections>(
    `directions/v5/mapbox/driving/${queryLocation}?steps=true&geometries=geojson&access_token=${mapboxToken}`,
  );
};
