import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  Contact,
  DasIdWillAssign,
  Group,
  PlantWillAssign,
  StatusType,
} from '../../../types';
import { Dascas } from '../../../types/Device';
import { Plant } from '../../../types/Resource';

import { bindDascasToPlant, unbindDascas } from '../../../apis/DascasApi';
import {
  setPlantGroup,
  updatePlant,
  uploadPlantAvatar,
} from '../../../apis/DastrackApi';
import {
  bindProjectContact,
  unBindProjectContact,
} from '../../../apis/ProjectApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useFilteredGroups from '../../../hooks/useFilteredGroups';
import useHasPolicy from '../../../hooks/useHasPolicy';
import { setPinLocation } from '../../../slices/pages/projectPageSlice';

import CheckListItem from '../../../components/CheckListItem';
import ConfirmButton from '../../../components/ConfirmButton';
import ManagementDialog from '../../../components/Dialog/ManagementDialog';

import CompleteButtonSvgIcon from '../../../assets/SvgIcon/CompleteButtonSvgIcon';
import LoadingButtonSvgIcon from '../../../assets/SvgIcon/LoadingButtonSvgIcon';
import SaveSvgIcon from '../../../assets/SvgIcon/SaveSvgIcon';
import mainTheme from '../../../theme';
import { checkIsArrayEqual } from '../../../utils/checkArrayEqual';
import { navigatorTowerCrane as navigator } from '../../../utils/common';
import ManagementNoPermissionContent from '../../ManagementNoPermissionContent';
import ConnectedDeviceContent from '../edit-content/ConnectedDeviceContent';
import ContactEdit from '../edit-content/ContactEdit';
import GroupEdit from '../edit-content/GroupEdit';
import BasicInformationContent from '../edit-content/TowerCraneBasicInformationContent';
import {
  checkCraneBasicInformationAvalible,
  checkIsFormCraneEdited,
} from '../validation/crane-validation';
import { checkContactAvailable } from '../validation/environment-validation';
import {
  checkIsAvatarUpdated,
  checkIsDasIdEqual,
  hasAssignedDevice,
} from '../validation/management-validation';

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  display: flex;
  flex: 1;
`;

const ContentWrapper = styled('div')`
  width: 340px;
  border-radius: 4px;
  display: flex;
  flex: 1;
`;

const DialogActions = styled(MuiDialogActions)`
  display: flex;
  padding: 24px;
  height: 80px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  width: 280px;
  margin-top: 20px;
`;

interface EditTowerCraneProps extends DialogProps {
  title: string;
  navigatorIndex: number;
  groups: Array<Group> | undefined;
  selectedResource: Plant | undefined;
  selectedGroups: Array<Group>;
  resourceContacts: Array<Contact>;
  connectedDevice: Array<Dascas>;
  onSuccessCreateCrane: () => void;
  onSuccessBind?: () => void;
  onCloseDialog: () => void;
  onSelectNavigatorIndex: (index: number) => void;
}

const EditTowerCrane: React.FC<EditTowerCraneProps> = ({
  open,
  title,
  navigatorIndex,
  selectedGroups,
  groups = [],
  selectedResource,
  resourceContacts,
  connectedDevice,
  onSuccessCreateCrane,
  onSuccessBind,
  onCloseDialog,
  onSelectNavigatorIndex,
}) => {
  const { projectId } = useParams();
  const { policies, role: userProjectRole } = useAppSelector(
    (store) => store.projects,
  );
  const queryClient = useQueryClient();

  const [checkedNavigator, setCheckedNavigator] = useState(
    new Array(navigator.length).fill(false),
  );
  const { t } = useTranslation('project-setting');
  const [plantGroupWillAssign, setPlantGroupWillAssign] = useState<
    Array<string>
  >([]);
  const [saveButtonStatus, setSaveButtonStatus] = useState<
    'loading' | 'success'
  >();

  const [plantWillAssign, setPlantWillAssign] = useState<PlantWillAssign>();
  const [dascasWillAssign, setDascasWillAssign] = useState<
    Array<DasIdWillAssign>
  >([]);
  const [assignedDasCASFirstInit, setAssignedDasCASFirstInit] = useState(false);

  const [initDascas, setInitDascas] = useState<Array<DasIdWillAssign>>([]);
  const [status, setStatus] = useState<StatusType>('default');
  const [message, setMessage] = useState('');
  const hasBindDevicePolicy = useHasPolicy(policies, 'WriteDeviceBinding');
  const [listContact, setListContact] = useState<string[]>(
    resourceContacts.map((contact) => contact.id),
  );
  const initContact = resourceContacts.map((contact) => contact.id);

  const { mutateAsync: mutateUnbindContact } =
    useMutation(unBindProjectContact);

  const { mutateAsync: mutateBindContact } = useMutation(bindProjectContact);
  const { mutateAsync: mutateEditPlant } = useMutation(updatePlant);
  const { mutateAsync: mutateUploadAvatar } = useMutation(uploadPlantAvatar);
  const { mutateAsync: mutateUpdatePlantGroupIds } = useMutation(setPlantGroup);
  const { mutateAsync: mutateBindDascas } = useMutation(bindDascasToPlant);
  const { mutateAsync: mutateUnbindDascas } = useMutation(unbindDascas);
  const dispatch = useAppDispatch();

  const filteredGroups = useFilteredGroups(
    'WriteOnePlantGroup',
    groups,
    userProjectRole,
    policies,
  );

  useEffect(() => {
    setPlantWillAssign({
      id: selectedResource?.id ?? '',
      name: selectedResource?.name ?? '',
      model: selectedResource?.model ?? '',
      serialNumber: selectedResource?.serialNumber ?? '',
      permitNumber: selectedResource?.permitNumber ?? '',
      permitIssuer: selectedResource?.permitIssuer ?? '',
      permitExpiryDate: selectedResource?.permitExpiryDate ?? '',
      safetyLoad: selectedResource?.safetyLoad ?? undefined,
      orgId: selectedResource?.orgId ?? '',
      projectId: selectedResource?.projectId ?? '',
      remark: selectedResource?.remark ?? '',
      imageURL: selectedResource?.imageURL ?? '',
      groupIds: selectedResource?.groupIds ?? [],
      bindingDastracks: selectedResource?.bindingDastracks ?? [],
      bindingDascases: selectedResource?.bindingDascases ?? [],
      brand: selectedResource?.brand ?? '',
      numberReading: selectedResource?.numberReading ?? undefined,
      yearOfProduction: selectedResource?.yearOfProduction ?? undefined,
      location: selectedResource?.location ?? null,
      plantType: selectedResource?.plantType ?? 'crane',
    });
    if (selectedResource?.location?.lon && selectedResource?.location?.lat) {
      dispatch(
        setPinLocation([
          selectedResource.location.lon,
          selectedResource.location.lat,
        ]),
      );
    }
  }, [selectedResource]);

  useEffect(() => {
    if (connectedDevice.length > 0 && assignedDasCASFirstInit === false) {
      const init: Array<DasIdWillAssign> = connectedDevice.map((dascas) => ({
        id: dascas.id,
        name: dascas.dasId,
        batteryLevel: 0,
      }));
      setDascasWillAssign(init);
      setInitDascas(init);
      setAssignedDasCASFirstInit(true);
    }
  }, [connectedDevice, assignedDasCASFirstInit]);

  useEffect(() => {
    if (open) {
      setPlantGroupWillAssign(selectedGroups.map((group) => group.id));
    }
  }, [open, , selectedGroups]);

  useEffect(() => {
    const updateChecked = checkedNavigator.map((_, index) =>
      index === navigatorIndex ? true : false,
    );
    setCheckedNavigator(updateChecked);
  }, [navigatorIndex]);

  const couldBindDevice =
    userProjectRole === 'owner' ||
    userProjectRole === 'admin' ||
    hasBindDevicePolicy;

  const handleCloseEditDialog = () => {
    onCloseDialog();
    setInitDascas([]);
    setDascasWillAssign([]);

    dispatch(setPinLocation([0, 0]));
    setPlantGroupWillAssign([]);
    onSelectNavigatorIndex(0);
    setStatus('default');
    setMessage('');
    setPlantWillAssign(undefined);
  };

  const unbindContacts = async (contactIds: string[]) => {
    const requestUnbindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateUnbindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: plantWillAssign?.id ?? '',
      });
      return data;
    });
    await Promise.all(requestUnbindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries(['project-contacts', projectId as string]);
  };

  const bindContacts = async (contactIds: string[]) => {
    const requestBindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateBindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: plantWillAssign?.id ?? '',
        type: 'plant',
      });
      return data;
    });
    await Promise.all(requestBindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries(['project-contacts', projectId as string]);
  };

  const handleBindDascas = async (dasIds: DasIdWillAssign[], id: string) => {
    const requestBindDascas = dasIds.map(async (dasId) => {
      const { data } = await mutateBindDascas({
        projectId: projectId as string,
        dasId: dasId.name,
        data: {
          plantId: id,
        },
      });
      return data;
    });
    await Promise.all(requestBindDascas).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    });
  };

  const handleUnbindDascas = async (dasIds: DasIdWillAssign[]) => {
    const requestUnbindDascas = dasIds.map(async (d) => {
      const { data } = await mutateUnbindDascas({
        dasId: d.name,
        projectId: projectId as string,
      });
      return data;
    });

    await Promise.all(requestUnbindDascas).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    });
  };

  const handleSubmit = async () => {
    if (!plantWillAssign) return;
    try {
      setSaveButtonStatus('loading');
      setStatus('default');

      await mutateEditPlant({
        projectId: projectId ?? '',
        plantId: plantWillAssign.id ?? '',
        payload: {
          name: plantWillAssign.name ?? '',
          model: plantWillAssign.model ?? '',
          serialNumber: plantWillAssign.serialNumber ?? '',
          permitNumber: plantWillAssign.permitNumber ?? '',
          permitIssuer: plantWillAssign.permitIssuer ?? '',
          permitExpiryDate: plantWillAssign.permitExpiryDate ?? '',
          safetyLoad: plantWillAssign.safetyLoad ?? 0,
          remark: plantWillAssign.remark ?? '',
          brand: plantWillAssign.brand ?? '',
          numberReading: plantWillAssign.numberReading ?? 0,
          yearOfProduction: plantWillAssign.yearOfProduction ?? 0,
          orgId: plantWillAssign.orgId ?? '',
          plantType: plantWillAssign.plantType ?? 'crane',
          location: plantWillAssign.location ?? undefined,
        },
      });

      if (plantWillAssign?.avatarFile) {
        await mutateUploadAvatar({
          projectId: projectId ?? '',
          plantId: plantWillAssign.id ?? '',
          imageFile: plantWillAssign.avatarFile,
        });
      }

      // set plant groupids
      if (plantGroupWillAssign.length !== 0) {
        await mutateUpdatePlantGroupIds({
          projectId: projectId as string,
          plantId: plantWillAssign?.id ?? '',
          groupIds: plantGroupWillAssign,
        });
      } else {
        setMessage('Please choose at least one group');
        setStatus('error');
      }

      // UNBIND CONTACT REQUEST
      const listContactUnbind = initContact.filter(
        (id) => !listContact.includes(id),
      );
      if (listContactUnbind.length > 0) {
        unbindContacts(listContactUnbind);
      }

      // BIND CONTACT REQUEST
      const listContactWillBind = listContact.filter(
        (id) => !initContact.includes(id),
      );
      if (listContactWillBind.length > 0) {
        bindContacts(listContactWillBind);
      }

      if (couldBindDevice && dascasWillAssign.length > 0) {
        await handleBindDascas(dascasWillAssign, plantWillAssign.id);
      }

      const dasIdWillUnbind = initDascas.filter(
        (item) => !dascasWillAssign.some((dw) => dw.name === item.name),
      );

      if (couldBindDevice && dasIdWillUnbind.length > 0) {
        await handleUnbindDascas(dasIdWillUnbind);
      }

      let timer: NodeJS.Timeout;
      let timer2: NodeJS.Timeout;
      timer = setTimeout(() => {
        setSaveButtonStatus('success');
        onSuccessBind?.();
        onSuccessCreateCrane();

        timer2 = setTimeout(() => {
          setSaveButtonStatus(undefined);
          handleCloseEditDialog();
        }, 500);
      }, 500);
      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } catch (err) {
      setSaveButtonStatus(undefined);
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    }
  };

  let saveButtonStatusIcon;
  switch (saveButtonStatus) {
    case 'loading':
      saveButtonStatusIcon = <LoadingButtonSvgIcon />;
      break;
    case 'success':
      saveButtonStatusIcon = <CompleteButtonSvgIcon />;
      break;
    default:
      saveButtonStatusIcon = <SaveSvgIcon />;
  }

  const saveable = () => {
    if (selectedResource && plantWillAssign) {
      if (
        !checkIsArrayEqual(selectedResource.groupIds, plantGroupWillAssign) ||
        !checkIsDasIdEqual(initDascas, dascasWillAssign) ||
        !checkIsArrayEqual(initContact, listContact) ||
        checkIsAvatarUpdated(plantWillAssign.avatarFile) ||
        checkIsFormCraneEdited(selectedResource, plantWillAssign)
      ) {
        return true;
      }
    }
    return false;
  };

  const handleOnChange = (value: any) => {
    const contactId = value;
    const isContain = listContact.includes(contactId);
    if (!isContain) {
      setListContact([...listContact, contactId]);
    } else {
      setListContact(listContact.filter((item) => item !== contactId));
    }
  };

  return (
    <ManagementDialog
      open
      onClose={handleCloseEditDialog}
      status={status}
      message={message}
      title={title}
    >
      <DialogContent>
        <CheckList>
          {navigator.map((item, index) => {
            let checked: boolean = false;

            switch (item.name) {
              case 'group':
                checked = true;
                break;
              case 'tower-crane-basic-information':
                checked = checkCraneBasicInformationAvalible(plantWillAssign);
                break;
              case 'emergency-contact':
                checked = checkContactAvailable(listContact);
                break;
              case 'connected-device':
                checked = hasAssignedDevice(dascasWillAssign);
                break;
            }

            return (
              <CheckListItem
                name={t(`navigator.${item.name}`)}
                required={item.required}
                checked={checked}
                onClick={() => onSelectNavigatorIndex(index)}
                selected={checkedNavigator[index]}
                key={index}
                data-cy={`btn-list-navigator-plant-${item.name}`}
              />
            );
          })}
        </CheckList>
        <ContentWrapper className="content wrapper">
          {navigator[navigatorIndex].name === 'group' && (
            <GroupEdit
              groupIdsSelected={plantGroupWillAssign}
              handleGroupAssign={(groupIds) =>
                setPlantGroupWillAssign(groupIds)
              }
              listGroup={filteredGroups}
              resource="crane"
            />
          )}

          {navigator[navigatorIndex].name ===
            'tower-crane-basic-information' && (
            <BasicInformationContent
              handlePlantDataWIllAssign={(plantData) =>
                setPlantWillAssign(plantData)
              }
              data={plantWillAssign}
            />
          )}
          {navigator[navigatorIndex].name === 'emergency-contact' && (
            <ContactEdit onChange={handleOnChange} listContact={listContact} />
          )}
          {navigator[navigatorIndex].name === 'connected-device' && (
            <>
              {couldBindDevice ? (
                <ConnectedDeviceContent
                  data={dascasWillAssign}
                  handleDasIDWillAssign={(dasIds) =>
                    setDascasWillAssign(dasIds)
                  }
                  projectId={projectId}
                  resourceType="crane"
                />
              ) : (
                <ManagementNoPermissionContent />
              )}
            </>
          )}
        </ContentWrapper>
      </DialogContent>
      <DialogActions>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': {
              background: mainTheme.color.secondary.$60,
            },
            marginRight: 'auto',
          }}
          color="secondary"
          onClick={handleCloseEditDialog}
          data-cy="btn-cancel-edited-plant"
        >
          {t('cancel')}
        </DialogButton>
        <ConfirmButton
          sx={{
            '& .MuiButton-startIcon > svg': {
              width: '32px',
              height: '32px',
            },
          }}
          startIcon={saveButtonStatusIcon}
          status={saveButtonStatus}
          onClick={handleSubmit}
          disabled={!saveable()}
          data-cy="btn-save-edited-plant"
        >
          {t('save')}
        </ConfirmButton>
      </DialogActions>
    </ManagementDialog>
  );
};

export default EditTowerCrane;
