/* eslint-disable react/display-name */
import React from 'react';
import {
  Dialog as MuiDialog,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  keyframes,
  styled,
} from '@mui/material';
import classNames from 'classnames';

import { StatusType } from '../../types';

import CleanDateSvgIcon from '../../assets/SvgIcon/CleanDateSvgIcon';

const Dialog = styled(MuiDialog)`
  color: white;
  & .MuiDialog-paper {
    width: 676px;
    height: 686px;
    max-width: unset;
    background: ${({ theme }) => theme.color.secondary.$80};
  }
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  color: white;
  padding: 8px 8px 8px 16px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  ${({ theme }) => ({ ...theme.typography.h3 })}
  font-family: 'Montserrat';
  flex: 1;
  color: white;
  padding: 0;
`;

const expand = keyframes`
  from {
    height: 0px;
  }

  to {
    height: 62px;
  }
`;

interface StatusBlockProps {
  type: StatusType;
}

const StatusBlock = styled('div')<StatusBlockProps>`
  text-align: center;
  color: white;
  padding: 10px;
  background: ${({ theme, type }) => {
    if (type === 'default') return '#A1A1A1';
    return theme.palette[type].main;
  }};
  animation: linear 0.2s ${expand};
`;

interface ManagementDialogProps extends DialogProps {
  status?: StatusType;
  message?: string;
  title?: string;
}

const ManagementDialog: React.VFC<ManagementDialogProps> = React.forwardRef(
  (
    { className, onClose, children, title, status, message, ...otherProps },
    ref,
  ) => {
    const handleOnClose = (event: React.MouseEvent) => {
      if (onClose) onClose(event, 'backdropClick');
    };

    return (
      <Dialog
        className={classNames(className, 'ManagementDialog')}
        ref={ref}
        {...otherProps}
      >
        <Header>
          <DialogTitle>{title}</DialogTitle>
          <CleanDateSvgIcon
            sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
            onClick={handleOnClose}
          />
        </Header>
        {status && status !== 'default' && (
          <StatusBlock type={status}>{message}</StatusBlock>
        )}
        {children}
      </Dialog>
    );
  },
);

export default ManagementDialog;
