import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  BoxProps,
  ClickAwayListener,
  IconButton,
  LinearProgress,
  Popper as MuiPopper,
  styled,
  Typography,
} from '@mui/material';

import { DeviceType, ErrorProps } from '../types';
import {
  AssignDeviceItemComponent,
  AssignDeviceItemData,
} from '../types/Device';

import clearSvg from '../assets/images/svg/btn_clean_date_light.svg';
import errorSvg from '../assets/images/svg/ic_error_ifo.svg';
import BatteryLevelSvgIcon from '../assets/SvgIcon/BatteryLevelSvgIcon';
import ChangeDeviceSvgIcon from '../assets/SvgIcon/ChangeDeviceSvgIcon';
import DeviceSquareSvgIcon from '../assets/SvgIcon/DeviceSquareSvgIcon';
import { handleIconMedium } from '../assets/SvgIcon/HandleIcon';
import SearchSvgIcon from '../assets/SvgIcon/SearchSvgIcon';
import RebindConfirm from '../pages/project-setting/confirm-management/RebindConfirm';

import Loading from './Loading';

interface ExternalContainerProps {
  disabled?: boolean;
}

const ExternalContainer = styled(Box)<ExternalContainerProps>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
`;

interface ContainerProps {
  selected: boolean;
  popperOpen: boolean;
  error?: boolean;
}
// prettier-ignore
const Container = styled(Box, {shouldForwardProp: prop => prop!== "popperOpen" && prop !== "error" && prop !== "selected"  })<ContainerProps>`
  ${({ theme }) => theme.typography.body2};
  /* height: 64px; */
  padding: 11px;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${({ theme, selected }) => ({
    color: selected ? 'white' : theme.color.secondary.$60,
    span: selected
      ? undefined
      : {
          opacity: 0.42,
        },
  })}
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ popperOpen, error, theme }) => {
    if (error) return theme.color.highlight;
    if (popperOpen) return 'rgba(0, 0, 0, 0.5)';
    return 'transparent';
  }};
  user-select: none;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  span {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .btn-clear {
    cursor: pointer;
  }
`;

// prettier-ignore
const SelectedItem = styled('span')`
  flex:1;
  display: flex;
  align-items: center;
`;

const ErrorContainer = styled(Box)`
  ${({ theme }) => theme.typography.caption};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.highlight};
`;

const Popper = styled(MuiPopper)`
  z-index: 2000;

  & .container {
    max-height: 280px;
    background: ${({ theme }) => theme.color.secondary.$100};
    border-radius: 4px;
  }

  & .header {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.secondary.$60};
    padding: 4px 0;

    input {
      ${({ theme }) => theme.typography.body2};
      flex: 1;
      display: inline-block;
      color: white;
      background: unset;
      padding: 0;
      border: none;
      outline: none;
    }
  }

  & .menu {
    color: white;
    padding: 0;
    height: 240px;
    overflow-y: auto;
    margin: 0;
  }
`;

const getIsAssignable = (isAssigned: boolean, bindTo: string | undefined) => {
  if (isAssigned) {
    if (bindTo) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

const Item = styled('li', {
  shouldForwardProp: (name: PropertyKey) =>
    name !== 'isAssigned' && name !== 'bindTo',
})<AssignDeviceItemComponent>(({ isAssigned, bindTo }) => {
  const assignable = getIsAssignable(isAssigned, bindTo);

  return {
    cursor: 'pointer',
    listStyle: 'none',
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    padding: '9.5px 10px',
    opacity: assignable ? 1 : 0.3,
    pointerEvents: assignable ? 'auto' : 'none',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.1)',
    },
  };
});

const ItemSelected = styled('li')`
  cursor: pointer;
  list-style: none;
  display: flex;
  height: 42px;
  align-items: center;
`;

const ItemDetails = styled('div')`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DeviceDetails = styled('div')`
  display: flex;
  align-items: center;
`;

interface SelectBindToDeviceProps
  extends Omit<BoxProps, 'onSelect' | 'onBlur'> {
  progress?: number;
  selectedId?: string;
  placeholder?: string;
  popperPlaceholder?: string;
  data: Array<AssignDeviceItemData>;
  isLoading?: boolean;
  keyPrefix?: string;
  error?: ErrorProps;
  disabled?: boolean;
  reset?: boolean;
  onSelect: (d: AssignDeviceItemData | undefined) => void;
  onBlur: () => void;
  type?: DeviceType;
}

const SelectBindToDevice: React.FC<SelectBindToDeviceProps> = ({
  progress = 100,
  selectedId,
  placeholder,
  popperPlaceholder,
  data,
  isLoading = true,
  error,
  keyPrefix,
  disabled,
  reset,
  onSelect,
  onBlur,
  type,
  ...props
}) => {
  const { t } = useTranslation();
  const externalContainerRef = useRef<HTMLDivElement | null>(null);
  const [selectedData, setSelectedData] = useState<AssignDeviceItemData>();
  const [open, setOpen] = useState(false);
  const [showConfrimRebind, setShowConfirmRebind] = useState<boolean>(false);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].id === selectedId) {
        setSelectedData(data[i]);
        break;
      }
    }
  }, [selectedId, data]);

  const handleOnClickClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedData(undefined);
    onSelect(undefined);
  };

  const handleOnSelect = (d: AssignDeviceItemData | undefined) => {
    setSelectedData(d);
    onSelect(d);
  };

  return (
    <>
      <ExternalContainer
        ref={externalContainerRef}
        disabled={disabled}
        {...props}
      >
        <Container
          selected={!!selectedData}
          popperOpen={open}
          error={error?.isError}
          onClick={() => setOpen(!open)}
          data-cy="select-list-device"
        >
          {!selectedData && (
            <DeviceSquareSvgIcon sx={{ width: '40px', height: '40px' }} />
          )}

          <SelectedItem>
            {selectedData ? (
              <ItemSelected>
                {handleIconMedium(type ?? 'dasloop')}{' '}
                <ItemDetails>
                  <div>{selectedData.name}</div>
                  <DeviceDetails>
                    {selectedData.batteryLevel > 0 && (
                      <>
                        <Typography variant="body2">
                          {selectedData.batteryLevel
                            ? selectedData.batteryLevel
                            : 0}
                          %
                        </Typography>
                        <div
                          style={{
                            marginLeft: '4px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <BatteryLevelSvgIcon
                            batteryValue={selectedData.batteryLevel}
                            maxValue={100}
                          />
                        </div>
                      </>
                    )}
                  </DeviceDetails>
                </ItemDetails>
              </ItemSelected>
            ) : (
              placeholder
            )}
          </SelectedItem>
          {selectedData && (
            <img
              className="btn-clear"
              src={clearSvg}
              alt=""
              onClick={handleOnClickClear}
            />
          )}
          {!open && !selectedData && (
            <IconButton onClick={() => setOpen(!open)}>
              <ChangeDeviceSvgIcon />
            </IconButton>
          )}
        </Container>
        {error?.isError && (
          <ErrorContainer>
            <img src={errorSvg} alt="" /> {error.message}
          </ErrorContainer>
        )}
      </ExternalContainer>
      <Popper
        open={open}
        anchorEl={externalContainerRef.current}
        placement="bottom"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
            onBlur();
          }}
        >
          <div
            className="container"
            style={{ width: `${externalContainerRef.current?.clientWidth}px` }}
          >
            <div className="header">
              <SearchSvgIcon sx={{ width: '32px', height: '32px', mr: 'e' }} />
              <input
                placeholder={popperPlaceholder}
                value={filterText}
                onChange={(e) => {
                  setFilterText(e.currentTarget.value);
                }}
              />
            </div>
            {progress < 100 && (
              <LinearProgress
                sx={{
                  '&.MuiLinearProgress-root': {
                    backgroundColor: 'black',
                  },
                }}
                variant="determinate"
                value={progress}
                color="primary"
              />
            )}
            {isLoading ? (
              <Loading />
            ) : (
              <ul className="menu">
                {data
                  .filter((d) => d.name.indexOf(filterText) > -1)
                  .sort((a, b) => {
                    const isAssignableA = getIsAssignable(
                      a.isAssigned,
                      a.bindTo,
                    );
                    const isAssignableB = getIsAssignable(
                      b.isAssigned,
                      b.bindTo,
                    );
                    if (isAssignableA && !isAssignableB) {
                      return -1;
                    }
                    if (!isAssignableA && isAssignableB) {
                      return 1;
                    }
                    return a.name.localeCompare(b.name);
                  })
                  .map((d) => {
                    let displayName;

                    if (d.isAssigned) {
                      if (d.bindTo) {
                        displayName = d.bindTo;
                      } else {
                        displayName = t('no-permission');
                      }
                    }
                    return (
                      <Item
                        key={`${keyPrefix}-OrgAutocomplete-item-${d.id}`}
                        className="item"
                        isAssigned={d.isAssigned}
                        bindTo={d.bindTo}
                        onClick={() => {
                          if (d.bindTo) {
                            setShowConfirmRebind(true);
                            setOpen(false);
                          }
                          handleOnSelect(d);
                        }}
                      >
                        <div>{handleIconMedium(type ?? 'dasloop')}</div>
                        <Typography
                          variant="body2"
                          sx={{ color: 'white', marginLeft: '8px' }}
                        >
                          {d.name} {displayName}
                        </Typography>
                      </Item>
                    );
                  })}
              </ul>
            )}
          </div>
        </ClickAwayListener>
      </Popper>
      {showConfrimRebind && (
        <RebindConfirm
          open={showConfrimRebind}
          onClose={() => setShowConfirmRebind(false)}
          handleOnSelect={(d) => handleOnSelect(d)}
        />
      )}
    </>
  );
};

export default SelectBindToDevice;
