import { Popper, PopperProps } from '@mui/material';

interface MenuItemPopperProps extends PopperProps {}

const MenuItemPopper: React.FC<MenuItemPopperProps> = ({
  children,
  ...props
}) => {
  return (
    <Popper
      nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined}
      {...props}
    >
      {children}
    </Popper>
  );
};

export default MenuItemPopper;
