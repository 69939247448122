import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { Area, PolicyType, User } from '../../types';
import {
  WebSocketAlert,
  WebSocketProjectAttendanceReportCreated,
} from '../../types/Websocket';

import { getAreasOfProject } from '../../apis/AreaApi';
import { getFloorPlans } from '../../apis/FloorPlanApi';
import {
  getMyProjectPolicies,
  getProjectContact,
  getProjectFeatureList,
  getProjectRole,
  getProjectUsers,
} from '../../apis/ProjectApi';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAlertQueueTimeOut } from '../../hooks/useAlertQueueTimeOut';
import useAlertSnackBar from '../../hooks/useAlertSnackBar';
import { useScrollButton } from '../../hooks/useScrollButton';
import { alertHandler } from '../../hooks/useWebSocket';
import {
  setCurrenProject,
  setCurrentMembers,
  setIsLoadingProjectContact,
  setProjectArea,
  setProjectContact,
  setProjectFloorPlan,
  setProjectPolicies,
  setProjectRole,
} from '../../slices/projectSlice';
import { setFeatureListMap } from '../../slices/systemSlice';
import { setCurrentProjectId } from '../../slices/websocketSlice';

import CloseSvgIcon from '../../assets/SvgIcon/CloseSvgIcon';
import { PubSubContext } from '../../utils/PubSub';

import BasicMenu from './BasicMenu';
import SSSSMenu from './SSSSMenu';

const Container = styled('div')`
  display: flex;
  flex-flow: column;
  width: 50px;
  height: 100vh;
  color: white;
  background-color: ${({ theme }) => theme.color.primary.$100};

  & nav {
    height: 100%;
    display: flex;
    flex-flow: column;

    & .back {
      cursor: pointer;
      height: 64px;
      background-color: ${({ theme }) => theme.externalColor.secondary.$140};

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }

  & .swtich-menu {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;

    & > .switch-item {
      flex: 1;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

  & a {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    list-style-type: none;
    color: white;

    &.active {
      background-color: ${({ theme }) => theme.color.secondary.$100};
    }

    & .icon {
      width: 100%;
      height: 40px;
    }

    &.home {
      height: 50px;
    }

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
`;

const ENTER_DELAY = 1000;

export const Divider = styled('div', { label: 'ProjectSidebar-Divider' })`
  margin: 10px;
  &:before {
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 1px;
  }
`;

const Icon = styled('div')`
  & > svg {
    display: block;
    width: 40px;
    height: 40px;
  }
`;

const ProjectSidebar = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const pubSub = useContext(PubSubContext);
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const selectedProjectId = useAppSelector(
    (store) => store.system.selectedProjectId,
  );
  const projectRole = useAppSelector((store) => store.projects.role);
  const [websocketAlert, setWebsocketAlert] = useState<WebSocketAlert | null>(
    null,
  );
  const [topMenuRef, setTopMenuRef] = useState<HTMLDivElement | null>(null);
  const [bottomMenuRef, setbottomMenuRef] = useState<HTMLDivElement | null>(
    null,
  );
  const topScroll = useScrollButton(topMenuRef);
  const bottomScroll = useScrollButton(bottomMenuRef);

  useAlertSnackBar(selectedProjectId ?? '', websocketAlert);

  useAlertQueueTimeOut(projectId, 30000);

  useEffect(() => {
    if (pubSub) {
      const handler = async (data) => {
        const alert = await alertHandler(
          projectId,
          data.type ?? data.resourceType,
          data,
        );
        setWebsocketAlert(alert);
      };

      const attendanceReportHandler = (
        data: WebSocketProjectAttendanceReportCreated,
      ) => {
        if (
          (data.projectId === projectId && projectRole === 'owner') ||
          projectRole === 'admin'
        ) {
          enqueueSnackbar(`${t('report-generated')}`, {
            key: `report-created-${data.projectId}-${data.link.id}`,
            variant: 'success',
            autoHideDuration: 30000,
            action: (
              <Icon
                onClick={() =>
                  closeSnackbar(
                    `report-created-${data.projectId}-${data.link.id}`,
                  )
                }
              >
                <CloseSvgIcon width={40} height={40} />
              </Icon>
            ),
          });
        }
      };

      pubSub.subscribe('alert', handler);
      pubSub.subscribe(
        'project-attendance-report-created',
        attendanceReportHandler,
      );
      pubSub.subscribe(
        'project-plant-attendance-report-created',
        attendanceReportHandler,
      );

      return () => {
        pubSub.unsubscribe('alert', handler);
        pubSub.unsubscribe(
          'project-attendance-report-created',
          attendanceReportHandler,
        );
        pubSub.unsubscribe(
          'project-plant-attendance-report-created',
          attendanceReportHandler,
        );
      };
    }
  }, [pubSub, projectId, projectRole]);

  const data = useQueries({
    queries: [
      {
        queryKey: ['project-role', selectedProjectId],
        queryFn: () =>
          getProjectRole(selectedProjectId as string).then(
            (res) => res.data.data,
          ),
        enabled: !!selectedProjectId,
        refetchOnWindowFocus: false,
        onSuccess: (role) => dispatch(setProjectRole(role)),
      },
      {
        queryKey: ['project-policies', selectedProjectId],
        queryFn: () =>
          getMyProjectPolicies(selectedProjectId as string).then(
            (res) => res.data.data,
          ),
        enabled: !!selectedProjectId,
        refetchOnWindowFocus: false,
        onSuccess: (policies) => dispatch(setProjectPolicies(policies)),
      },
      {
        queryKey: ['project-contacts', selectedProjectId],
        queryFn: () =>
          getProjectContact(selectedProjectId as string).then(
            (res) => res.data.data,
          ),
        enabled: !!selectedProjectId,
        refetchOnWindowFocus: false,
        onSuccess: (contacts) => {
          dispatch(setProjectContact(contacts));
          dispatch(setIsLoadingProjectContact(false));
        },
        onError: () => {
          dispatch(setIsLoadingProjectContact(false));
        },
      },
      {
        queryKey: ['project-floor-plan', selectedProjectId],
        queryFn: () =>
          getFloorPlans(selectedProjectId as string).then(
            (res) => res.data.data,
          ),
        enabled: !!selectedProjectId,
        refetchOnWindowFocus: false,
        onSuccess: (floorPlan) => {
          dispatch(setProjectFloorPlan(floorPlan));
        },
      },
      {
        queryKey: ['get-project-merbers', projectId],
        queryFn: async () => {
          const res = await getProjectUsers(projectId as string);
          const projectMemberMap = res.data.data.reduce((prev, curr) => {
            prev[curr.id] = curr;
            return prev;
          }, {});
          return projectMemberMap as { [id: string]: User | undefined };
        },
        enabled: !!projectId,
        refetchOnWindowFocus: false,
        onSuccess: (memberMap) => {
          dispatch(setCurrentMembers(memberMap));
        },
      },
    ],
  });

  const { data: dataFeatures } = useQuery(
    ['get-project-feature-list', selectedProjectId],
    async () =>
      getProjectFeatureList(selectedProjectId as string).then(
        (res) => res.data.data,
      ),
    {
      enabled: !!selectedProjectId,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        dispatch(setFeatureListMap(res));
      },
    },
  );

  const handleReset = () => {
    dispatch(setProjectRole(null));
    dispatch(setProjectPolicies([]));
    dispatch(setCurrenProject(null));
    dispatch(setCurrentProjectId(null));
  };

  const policyMap = useMemo(() => {
    const map = new Map<PolicyType, boolean>();
    data[1].data?.forEach((policy) => {
      map.set(policy.name, true);
    });
    return map;
  }, [data?.[1]?.data]);

  const alertReadable =
    data[0].data === 'admin' ||
    data[0].data === 'owner' ||
    policyMap.has('ReadAlert');

  const areaReadable =
    projectRole === 'owner' ||
    projectRole === 'admin' ||
    policyMap.has('ReadArea');

  const areaWriteable =
    data[0].data === 'admin' ||
    data[0].data === 'owner' ||
    policyMap.has('WriteArea');

  const overviewReadable =
    data[0].data === 'admin' ||
    data[0].data === 'owner' ||
    policyMap.has('ReadProjectOverview');

  const reportReadable =
    data[0].data === 'admin' ||
    data[0].data === 'owner' ||
    policyMap.has('ReadArea') ||
    policyMap.has('WriteArea');

  let disablePa = true;
  if (dataFeatures?.dasloop) {
    disablePa = dataFeatures?.dasloop.metadata.disablePa;
  } else if (dataFeatures?.four_s) {
    disablePa = dataFeatures?.four_s.metadata.disablePa;
  }

  const PAreadable =
    !disablePa && (data[0].data === 'admin' || data[0].data === 'owner');

  useQuery(
    ['project-area', selectedProjectId],
    async () => {
      const areas: Area[] = [];
      const load = async (nextCursor?: string) => {
        const res = await getAreasOfProject(selectedProjectId as string, {
          nextCursor,
          limit: 200,
          selectVisible: false,
        });
        res.data.data.forEach((area) => {
          areas.push(area);
        });

        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };

      await load();

      return areas;
    },
    {
      enabled: !!selectedProjectId && areaReadable,
      refetchOnWindowFocus: false,
      onSuccess: (areas) => {
        dispatch(setProjectArea(areas));
      },
    },
  );

  return (
    <Container>
      {dataFeatures?.four_s ? (
        <SSSSMenu
          dataFeatures={dataFeatures}
          projectId={projectId}
          selectedProjectId={selectedProjectId}
          enterDelay={ENTER_DELAY}
          overviewReadable={overviewReadable}
          reportReadable={reportReadable}
          alertReadable={alertReadable}
          areaWriteable={areaWriteable}
          PAreadable={PAreadable}
          topScroll={{
            ...topScroll,
            setRef: setTopMenuRef,
          }}
          bottomScroll={{
            ...bottomScroll,
            setRef: setbottomMenuRef,
          }}
          onReset={handleReset}
        />
      ) : (
        <BasicMenu
          dataFeatures={dataFeatures}
          projectId={projectId}
          selectedProjectId={selectedProjectId}
          enterDelay={ENTER_DELAY}
          overviewReadable={overviewReadable}
          reportReadable={reportReadable}
          alertReadable={alertReadable}
          areaWriteable={areaWriteable}
          PAreadable={PAreadable}
          topScroll={{
            ...topScroll,
            setRef: setTopMenuRef,
          }}
          bottomScroll={{
            ...bottomScroll,
            setRef: setbottomMenuRef,
          }}
          onReset={handleReset}
        />
      )}
    </Container>
  );
};

export default ProjectSidebar;
