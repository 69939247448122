import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';

import { Group, VehicleWithOrg } from '../../../../types';

import { useAppSelector } from '../../../../hooks';

import EmptyDataWarning from '../../../../components/EmptyDataWarning';
import Loading from '../../../../components/Loading';
import ProjectPanel from '../../../../components/Panel/ProjectPanel';
import Search from '../../../../components/Search';

import MuiTheme from '../../../../theme';
import { navigatorVehicle } from '../../../../utils/common';
import EditVehicle from '../Edit';
import ViewVehicle from '../View';

import VehicleItemCheckbox from './VehicleItemCheckbox';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

type ListOfVehicleProjectProps = {
  selectedId: (ids: Array<string>) => void;
  data: Array<VehicleWithOrg>;
  onSuccessEdit: () => void;
  onSuccessBind: () => void;
  listGroupProject: Array<Group>;
  isLoadingAssingGroup: boolean;
  isLoading: boolean;
};

const ListOfVehicleProject: React.FC<ListOfVehicleProjectProps> = ({
  selectedId,
  data,
  onSuccessBind,
  onSuccessEdit,
  listGroupProject,
  isLoadingAssingGroup,
  isLoading,
}) => {
  const { t, i18n } = useTranslation('project-setting');
  const [filterText, setFilterText] = useState('');
  const [idsAssign, setidsAssign] = useState<Array<string>>([]);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showView, setShowView] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleWithOrg>();
  const [navigatorIndex, setNavigatorIndex] = useState(1);
  const { dastrackV } = useAppSelector((store) => store.vehicleManagement);
  const { projectContact } = useAppSelector((store) => store.projects);
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId } = useParams();
  const groupIdSelected = searchParams.get('groupId');
  const vehicleIdSelected = searchParams.get('vehicleId');
  const navigate = useNavigate();

  const contacts = useMemo(() => {
    if (projectContact.length > 0 && selectedVehicle) {
      return projectContact.filter((contact) =>
        contact.bindingProjectReference.some(
          (ref) => ref.type === 'vehicle' && ref.refId === selectedVehicle.id,
        ),
      );
    }
    return [];
  }, [projectContact, selectedVehicle]);

  const handleOnChange = (value: any) => {
    const newValue = value;
    let updateValue = [...idsAssign];
    if (idsAssign.includes(newValue)) {
      updateValue.splice(updateValue.indexOf(newValue), 1);
    } else {
      updateValue.push(newValue);
    }
    setidsAssign(updateValue);
  };

  useEffect(() => {
    if (isLoadingAssingGroup) {
      setidsAssign([]);
    }
  }, [isLoadingAssingGroup]);

  useEffect(() => {
    selectedId(idsAssign);
  }, [idsAssign]);

  const groupSelected = useMemo(() => {
    return listGroupProject.filter((group) =>
      selectedVehicle ? selectedVehicle.groupIds.includes(group.id) : [],
    );
  }, [selectedVehicle]);

  const connectedDevice = useMemo(() => {
    if (dastrackV.length > 0) {
      return dastrackV.filter((dastrack) =>
        selectedVehicle?.bindingDastrackVs.includes(dastrack.dasId),
      );
    }
    return [];
  }, [selectedVehicle, dastrackV]);

  const items = data
    .filter((vehicle) => vehicle.name.indexOf(filterText) !== -1)
    .sort((a, b) => a.name.localeCompare(b.name, i18n.language))
    .map((vehicle) => (
      <VehicleItemCheckbox
        key={`Vehicle ${vehicle.id}`}
        name={vehicle.name}
        imageURL={vehicle.imageURL}
        dasIds={vehicle.bindingDastrackVs}
        idSelected={vehicle.id}
        orgName={vehicle.orgDetail?.name ?? ''}
        orgColor={vehicle.orgDetail?.color}
        onChange={handleOnChange}
        onClick={() => {
          setShowView(true);
          setSelectedVehicle(vehicle);
          if (groupIdSelected) {
            setSearchParams({
              groupId: groupIdSelected,
              vehicleId: vehicle.id,
            });
          }
        }}
      />
    ));

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 129px)',
        marginLeft: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={t('page.vehicle-management.title-vehicle-list-permission')}
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: MuiTheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          value={filterText}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
      </div>
      <ListContainer data-cy="container-vehicle-list-permission">
        {isLoading ? (
          <Loading />
        ) : items.length > 0 ? (
          items
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>
      {showEdit && (
        <EditVehicle
          open={showEdit}
          title={t(
            'page.vehicle-management.button-more.edit-vehicle-information',
          )}
          navigator={navigatorVehicle}
          navigatorIndex={navigatorIndex}
          listGroup={listGroupProject}
          listGroupSelected={groupSelected}
          vehicleSelected={selectedVehicle}
          connectedDevice={connectedDevice}
          vehicleContacts={contacts}
          onSuccessEdit={onSuccessEdit}
          onSuccessBind={onSuccessBind}
          onCloseDialog={() => {
            if (groupIdSelected) {
              navigate(
                `/project-setting/${projectId}/vehicle-group-management/edit-vehicle-group?groupId=${groupIdSelected}`,
              );
            } else {
              navigate(
                `/project-setting/${projectId}/vehicle-group-management/edit-vehicle-group`,
              );
            }
            setShowEdit(false);
          }}
          onSelectNavigatorIndex={(index) => setNavigatorIndex(index)}
        />
      )}

      {showView && (
        <ViewVehicle
          open={showView}
          title={t('page.vehicle-management.button-more.vehicle-information')}
          navigator={navigatorVehicle}
          navigatorIndex={navigatorIndex}
          vehicleSelected={selectedVehicle}
          groupSelected={listGroupProject}
          connectedDevice={connectedDevice}
          vehicleContact={contacts}
          onCloseDialog={() => {
            setShowView(false);
            if (groupIdSelected) {
              navigate(
                `/project-setting/${projectId}/vehicle-group-management/edit-vehicle-group?groupId=${groupIdSelected}`,
              );
            } else {
              navigate(
                `/project-setting/${projectId}/vehicle-group-management/edit-vehicle-group`,
              );
            }
          }}
          setDialogEdit={(state) => {
            if (groupIdSelected && vehicleIdSelected) {
              setSearchParams({
                groupId: groupIdSelected,
                vehicleId: vehicleIdSelected,
              });
            }
            setShowEdit(state);
          }}
          onSelectNavigatorIndex={(index) => setNavigatorIndex(index)}
        />
      )}
    </ProjectPanel>
  );
};

export default ListOfVehicleProject;
