import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import {
  CSS2DObject,
  CSS2DRenderer,
} from 'three/examples/jsm/renderers/CSS2DRenderer';

import {
  AOA,
  AOAUwb,
  Area,
  AreaType,
  Beacon,
  CCTV,
  CCTVMode,
  DeviceType,
  DocumentWithFullscreenElement,
  HTMLDivElementWithFullscreen,
  LocatorType3D,
  PinLocationMode,
} from '../../../../types';

import aoaTagCoverPng from '../../../../assets/images/png/3d-plus/aoa-tag-cover.png';
import cctvCoverPng from '../../../../assets/images/png/3d-plus/cctv-cover.png';
import dasaoaCoverPng from '../../../../assets/images/png/3d-plus/dasaoa-cover.png';
import dasbeaconCoverPng from '../../../../assets/images/png/3d-plus/dasbeacon-cover.png';
import { MeshoptDecoder } from '../../../../utils/3d/meshopt_decoder.module';
import { getStrengthColor } from '../../../../utils/common';
import { easeInOutQuad } from '../../../../utils/easingFunction';
import SrsWebrtc from '../../../../utils/SrsWebrtc';
import {
  Line,
  Point,
  toDrawRanges2Point,
  toLineSegments,
} from '../../../../utils/threejs/LineSegment';
import Video360Renderer from '../../../../utils/Video360Renderer';

import AreaCreator from './AreaCreator';
import DasbeaconCreator from './DasbeaconCreator';
import DasconcreteCreator from './DasconcreteCreator';
import { DaswaterCreator } from './DaswaterCreator';
import { globalTracker } from './ResourceTracker';
import WorkerCreator from './WorkerCreator';

import './ThreeManager.css';

export type DeviceConfig = {
  name?: string;
  dasId: string;
  position: { x: number; y: number; z: number };
  rotation?: number;
};

export type WorkerConfig = DeviceConfig & {
  status: 'indoor' | 'outdoor' | 'offline' | 'alert';
};

export type DasConcreteConfig = DeviceConfig & {
  strength: number | null;
};

export type AreaSettingThreeManager = {
  id: string;
  positions: [number, number, number][];
  height: number;
  altitude: number;
  type: AreaType;
}[];

class ThreeManager {
  static CARDINAL_DIRECTION_DISTANCE = 40;

  static CARDINAL_DIRECTION_TEXTS = [
    {
      name: 'N',
      position: [0, 0, this.CARDINAL_DIRECTION_DISTANCE],
    },
    {
      name: 'E',
      position: [-this.CARDINAL_DIRECTION_DISTANCE, 0, 0],
    },
    {
      name: 'S',
      position: [0, 0, -this.CARDINAL_DIRECTION_DISTANCE],
    },
    {
      name: 'W',
      position: [this.CARDINAL_DIRECTION_DISTANCE, 0, 0],
    },
  ];

  private static _dummy: THREE.Object3D = new THREE.Object3D();

  originOffset: { x: number; y: number; z: number } = { x: 0, y: 0, z: 0 };

  container: HTMLDivElement;

  scene: THREE.Scene;

  camera: THREE.PerspectiveCamera;

  renderer: THREE.WebGLRenderer;

  loadingManager: THREE.LoadingManager;

  labelRenderer: CSS2DRenderer;

  controls: OrbitControls;

  private _directionMeshes: THREE.Mesh[] = [];

  private listObjectLoaded: Array<THREE.Object3D>;

  private _gltfLoader: GLTFLoader;

  private _requestAnimationFrame: number;

  private _materialMap: { [name: string]: THREE.Material };

  private _objectMap: { [name: string]: THREE.Mesh | THREE.Group | undefined };

  private _objectEditMap: {
    [name: string]: THREE.Mesh | THREE.Group | undefined;
  };

  private _axesHelper: THREE.AxesHelper | null = null;

  private _prevousSetWorker: Array<{
    index: number;
    type: 'indoor' | 'outdoor' | 'offline' | 'alert';
  }>;

  private _prevousSetWorkerDaswatch: Array<{
    index: number;
    type: 'indoor' | 'outdoor' | 'offline' | 'alert';
  }>;

  private _previousLines: any[] = [];

  private _lineSegments: Line[] = [];

  private _labelMap: Map<string, CSS2DObject>;

  private _contentMap: Map<string, CSS2DObject>;

  private _cctvConfig: Map<
    string,
    {
      videoEl: HTMLVideoElement;
      canvasEl?: HTMLCanvasElement;
      srsWebrtc: SrsWebrtc;
      video360Renderer?: Video360Renderer;
    }
  >;

  private timerCCTV360: NodeJS.Timeout | undefined;

  private _textureMap: Map<string, THREE.Texture>;

  layersChannel: number[];

  workerLabelVisible: boolean;

  daswatchLabelVisible: boolean;

  plantLabelVisible: boolean;

  locatorLabelVisible: boolean;

  aoaTagLabelVisible: boolean;

  areaLabelVisible: boolean;

  daswaterLabelVisible: boolean;

  cctvLabelVisible: boolean;

  cctvConentVisible: boolean;

  editMode: PinLocationMode | undefined;

  private _areaCreator: AreaCreator;

  private flyToTimer: NodeJS.Timer | undefined;

  constructor(
    container: HTMLDivElement,
    config?: {
      originOffset?: { x: number; y: number; z: number };
      editMode?: PinLocationMode;
      cctvMode?: CCTVMode;
      displayLabel?: {
        worker?: boolean;
        daswatch?: boolean;
        plant?: boolean;
        locator?: boolean;
        area?: boolean;
        aoaTag?: boolean;
        daswater?: boolean;
        cctv?: boolean;
      };
    },
  ) {
    const canvas = container.querySelector('canvas');

    if (!canvas) {
      throw new Error('canvas not found');
    }

    this.originOffset = config?.originOffset ?? { x: 0, y: 0, z: 0 };
    this.container = container;
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75);
    this.loadingManager = new THREE.LoadingManager();
    this._requestAnimationFrame = 0;
    this._materialMap = {};
    this._objectMap = {};
    this._objectEditMap = {};
    this.listObjectLoaded = [];
    this._prevousSetWorker = [];
    this._prevousSetWorkerDaswatch = [];
    this._labelMap = new Map();
    this._contentMap = new Map();
    this._textureMap = new Map();
    this._cctvConfig = new Map();
    this.workerLabelVisible = config?.displayLabel?.worker ?? true;
    this.daswatchLabelVisible = config?.displayLabel?.daswatch ?? true;
    this.plantLabelVisible = config?.displayLabel?.plant ?? false;
    this.locatorLabelVisible = config?.displayLabel?.locator ?? false;
    this.areaLabelVisible = config?.displayLabel?.area ?? false;
    this.aoaTagLabelVisible = config?.displayLabel?.aoaTag ?? false;
    this.daswaterLabelVisible = config?.displayLabel?.daswater ?? false;
    this.cctvLabelVisible = config?.displayLabel?.cctv ?? false;
    this.cctvConentVisible = false;
    this.renderer = this.renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
    });
    this.editMode = config?.editMode;
    this.labelRenderer = new CSS2DRenderer();

    this._gltfLoader = new GLTFLoader();
    this._gltfLoader.setMeshoptDecoder(MeshoptDecoder);
    this._areaCreator = new AreaCreator();
    this.controls = new OrbitControls(this.camera, canvas);

    this.renderer.localClippingEnabled = true;

    this.layersChannel = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  }

  async init() {
    if (import.meta.env.MODE === 'development') {
      this._axesHelper = new THREE.AxesHelper(5);
      (this._axesHelper.material as THREE.Material).depthTest = false;
      this._axesHelper.renderOrder = 1;
      this.scene.add(this._axesHelper);
    }

    const size = {
      width: this.container.offsetWidth,
      height: this.container.offsetHeight,
    };

    this._initialLight();
    this._initialTexture();
    this._initialMaterials();
    this._initialFog();
    this._initialControl();
    this._initialCamera(size);
    this._addCardinalDirectionText();
    this._initialRenderer(size);
    this._initialLabelRender(size);
    this._initialLayers();

    const animate = () => {
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
      this.labelRenderer.render(this.scene, this.camera);
      this._requestAnimationFrame = requestAnimationFrame(animate);
    };

    animate();

    window.addEventListener('resize', this._resize.bind(this));
  }

  private _resize() {
    if (this.container && this.camera && this.renderer) {
      const size = {
        width: this.container.offsetWidth,
        height: this.container.offsetHeight,
      };
      this.camera.aspect = size.width / size.height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(size.width, size.height);
      this.labelRenderer.setSize(size.width, size.height);
    }
  }

  private static resetDummy() {
    ThreeManager._dummy.position.set(0, 0, 0);
    ThreeManager._dummy.rotation.set(0, 0, 0);
    ThreeManager._dummy.scale.set(1, 1, 1);
  }

  private _initialTexture() {
    const textureLoader = new THREE.TextureLoader();
    let texture: THREE.Texture;

    texture = globalTracker.track(textureLoader.load(dasbeaconCoverPng));
    texture.name = 'dasbeacon';
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    this._textureMap.set('dasbeacon', texture);

    texture = globalTracker.track(textureLoader.load(dasaoaCoverPng));
    texture.name = 'dasaoa';
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    this._textureMap.set('dasaoa', texture);

    texture = globalTracker.track(textureLoader.load(aoaTagCoverPng));
    texture.name = 'aoa-tag';
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    this._textureMap.set('aoa-tag', texture);

    texture = globalTracker.track(textureLoader.load(cctvCoverPng));
    texture.name = 'cctv';
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    this._textureMap.set('cctv', texture);
  }

  initEditMesh(deviceType: PinLocationMode) {
    const dasbeaconCreator = new DasbeaconCreator();

    if (deviceType === 'aoa') {
      const dasaoaEdit = dasbeaconCreator.createMesh({
        name: `dasaoaEdit`,
        materials: [
          this._materialMap.dasaoaCover,
          this._materialMap.indoorMaterial,
          this._materialMap.indoorMaterial,
          this._materialMap.indoorMaterial,
          this._materialMap.indoorMaterial,
          this._materialMap.indoorMaterial,
        ],
      });

      dasaoaEdit.layers.enable(1);

      this._objectEditMap.aoa = dasaoaEdit;
      this.scene.add(dasaoaEdit);
      this.listObjectLoaded.push(dasaoaEdit);
    }

    if (deviceType === 'beacon') {
      const dasBeaconEdit = dasbeaconCreator.createMesh({
        name: `dasbeaconEdit`,
        materials: [
          this._materialMap.dasbeaconCover,
          this._materialMap.dasbeaconCover,
          this._materialMap.indoorMaterial,
          this._materialMap.indoorMaterial,
          this._materialMap.indoorMaterial,
          this._materialMap.indoorMaterial,
        ],
      });

      dasBeaconEdit.layers.enable(1);

      this._objectEditMap.beacon = dasBeaconEdit;

      this.scene.add(dasBeaconEdit);
      this.listObjectLoaded.push(dasBeaconEdit);
    }
    const daswaterCreator = new DaswaterCreator();
    if (deviceType === 'daswater') {
      const daswater = daswaterCreator.createMesh({
        name: `daswaterEdit`,
        material: this._materialMap.daswaterMaterial,
      });
      this._objectEditMap.daswater = daswater;
      this.scene.add(daswater);
      this.listObjectLoaded.push(daswater);
    }

    const dasconcreteCreator = new DasconcreteCreator();

    if (deviceType === 'dasconcrete') {
      const dasconcrete = dasconcreteCreator.createMesh({
        name: 'dasconcreteEdit',
        materials: new THREE.MeshBasicMaterial({
          color: 0x00bfff,
          transparent: true,
          opacity: 0.7,
        }),
      });
      this._objectEditMap.dasconcrete = dasconcrete;
      this.scene.add(dasconcrete);
      this.listObjectLoaded.push(dasconcrete);
    }
  }

  getListObject() {
    return this.listObjectLoaded;
  }

  getLabel() {
    return this._labelMap;
  }

  private _initialMaterials() {
    const deviceStatusMaterialData: Array<{
      type: WorkerConfig['status'];
      color: number;
    }> = [
      {
        type: 'alert',
        color: 0xff6b00,
      },
      {
        type: 'indoor',
        color: 0x5296d5,
      },
      {
        type: 'offline',
        color: 0xa1a1a1,
      },
      {
        type: 'outdoor',
        color: 0x78dc00,
      },
    ];

    const areaTypes: Array<{
      type: AreaType;
      color: number;
    }> = [
      {
        type: 'normal',
        color: 0x5296d5,
      },
      {
        type: 'boundary',
        color: 0x656565,
      },
      {
        type: 'danger',
        color: 0xcc0000,
      },
      {
        type: 'restricted',
        color: 0xe6a600,
      },
      {
        type: 'attendance',
        color: 0xb152c6,
      },
      {
        type: 'confined',
        color: 0xe96363,
      },
      {
        type: 'rest',
        color: 0x58b99e,
      },
    ];

    deviceStatusMaterialData.forEach((data) => {
      const material = globalTracker.track(
        new THREE.MeshBasicMaterial({
          color: data.color,
          // depthTest: false,
        }),
      );
      material.name = `${data.type}Material`;
      this._materialMap[`${data.type}Material`] = material;
    });

    const dasbeaconCover: THREE.MeshBasicMaterial = globalTracker.track(
      new THREE.MeshBasicMaterial({
        map: this._textureMap.get('dasbeacon'),
      }),
    );

    dasbeaconCover.name = 'dasbeaconCover';
    this._materialMap.dasbeaconCover = dasbeaconCover;

    const dasaoaCover: THREE.MeshBasicMaterial = globalTracker.track(
      new THREE.MeshBasicMaterial({
        map: this._textureMap.get('dasaoa'),
      }),
    );

    const daswaterMaterial: THREE.MeshBasicMaterial = globalTracker.track(
      new THREE.MeshBasicMaterial({
        color: '#ff6b00',
      }),
    );

    daswaterMaterial.name = 'daswaterMaterial';
    this._materialMap.daswaterMaterial = daswaterMaterial;

    dasaoaCover.name = 'dasaoaCover';
    this._materialMap.dasaoaCover = dasaoaCover;

    const aoaTagCover: THREE.MeshBasicMaterial = globalTracker.track(
      new THREE.MeshBasicMaterial({ map: this._textureMap.get('aoa-tag') }),
    );
    aoaTagCover.name = 'aoaTagCover';
    this._materialMap.aoaTagCover = aoaTagCover;

    const cctvCover: THREE.MeshBasicMaterial = globalTracker.track(
      new THREE.MeshBasicMaterial({ map: this._textureMap.get('cctv') }),
    );
    cctvCover.name = 'cctvCover';
    this._materialMap.cctvCover = cctvCover;

    areaTypes.forEach((at) => {
      const material = globalTracker.track(
        new THREE.MeshBasicMaterial({
          color: at.color,
          transparent: true,
          opacity: 0.5,
          depthWrite: false,
        }),
      );

      material.name = `${at.type}Material`;
      this._materialMap[`${at.type}Material`] = material;
    });
  }

  initialCameraState(config: any) {
    if (config && config.cameraState) {
      const { position, quaternion } = config.cameraState;
      this.camera.position.fromArray(position);
      this.camera.quaternion.fromArray(quaternion);
      this.camera.updateProjectionMatrix();
    }
  }

  initialWorker() {
    const types: WorkerConfig['status'][] = [
      'alert',
      'indoor',
      'offline',
      'outdoor',
    ];
    const workerCreator = new WorkerCreator();

    types.forEach((type) => {
      const worker = workerCreator.create({
        name: `${type}-worker`,
        material: this._materialMap[`${type}Material`],
      });

      this._objectMap[`${type}Worker`] = worker;
      this.scene.add(worker);
    });
  }

  initialDasConcrete() {
    const dasConcreteCreator = new DasconcreteCreator();
    const mesh = dasConcreteCreator.create({
      name: 'DasConcrete',
      material: new THREE.MeshPhongMaterial({ color: 0xffffff }),
    });
    mesh.setColorAt(0, new THREE.Color(0xffffff));
    this._objectMap.DasConcrete = mesh;
    this.scene.add(mesh);
  }

  initialDaswatch() {
    const types: WorkerConfig['status'][] = [
      'alert',
      'indoor',
      'offline',
      'outdoor',
    ];
    const workerCreator = new WorkerCreator();

    types.forEach((type) => {
      const workerDaswatch = workerCreator.create({
        name: `${type}-daswatch`,
        material: this._materialMap[`${type}Material`],
      });
      workerDaswatch.layers.set(this.layersChannel[1]);
      this._objectMap[`${type}Daswatch`] = workerDaswatch;
      this.scene.add(workerDaswatch);
    });
  }

  initialLocator() {
    const dasbeaconCreator = new DasbeaconCreator();
    const dasbeacon = dasbeaconCreator.create({
      name: `dasbeacon`,
      materials: [
        this._materialMap.indoorMaterial,
        this._materialMap.indoorMaterial,
        this._materialMap.indoorMaterial,
        this._materialMap.indoorMaterial,
        this._materialMap.dasbeaconCover,
        this._materialMap.dasbeaconCover,
      ],
    });

    this._objectMap.dasbeacon = dasbeacon;
    dasbeacon.layers.set(this.layersChannel[2]);
    this.scene.add(dasbeacon);

    const dasaoa = dasbeaconCreator.create({
      name: `dasaoa`,
      materials: [
        this._materialMap.indoorMaterial,
        this._materialMap.indoorMaterial,
        this._materialMap.indoorMaterial,
        this._materialMap.indoorMaterial,
        this._materialMap.dasaoaCover,
        this._materialMap.dasaoaCover,
      ],
    });
    dasaoa.layers.set(this.layersChannel[3]);
    this._objectMap.dasaoa = dasaoa;
    this.scene.add(dasaoa);

    const aoaTag = dasbeaconCreator.createAOATag({
      name: 'aoaTag',
      materials: [
        this._materialMap.offlineMaterial,
        this._materialMap.offlineMaterial,
        this._materialMap.offlineMaterial,
        this._materialMap.offlineMaterial,
        this._materialMap.aoaTagCover,
        this._materialMap.aoaTagCover,
      ],
    });
    dasaoa.layers.set(this.layersChannel[4]);
    this._objectMap.aoaTag = aoaTag;
    this.scene.add(aoaTag);
  }

  initialDaswater() {
    const daswaterCreator = new DaswaterCreator();
    const daswater = daswaterCreator.create({
      name: `daswaterEdit`,
      material: this._materialMap.daswaterMaterial,
    });
    daswater.traverse((obj) => {
      obj.layers.set(this.layersChannel[5]);
    });
    this._objectMap.daswater = daswater;
    this.scene.add(daswater);
  }

  private _initialLight() {
    const lightColor = 0xffffff;

    const ambientLight: THREE.AmbientLight = globalTracker.track(
      new THREE.AmbientLight(lightColor, 0.5),
    );
    this.scene.add(ambientLight);

    const directionalLight: THREE.DirectionalLight = globalTracker.track(
      new THREE.DirectionalLight(lightColor, 1),
    );
    directionalLight.position.set(0, 10, 5);
    directionalLight.target.position.set(-5, 0, 0);
    this.scene.add(directionalLight);
    this.scene.add(directionalLight.target);
  }

  private _initialLayers() {
    this.layersChannel.forEach((layer) => {
      this.camera.layers.enable(layer);
    });
  }

  private _initialControl() {
    this.controls.domElement = this.renderer.domElement;
    this.controls.addEventListener('change', () => {
      this._directionMeshes.forEach((mesh) => {
        switch (mesh.geometry.name) {
          case 'N':
            mesh.position.set(
              this.camera.position.x,
              this.camera.position.y - 20,
              this.camera.position.z + ThreeManager.CARDINAL_DIRECTION_DISTANCE,
            );
            break;
          case 'E':
            mesh.position.set(
              this.camera.position.x - ThreeManager.CARDINAL_DIRECTION_DISTANCE,
              this.camera.position.y - 20,
              this.camera.position.z,
            );
            break;
          case 'S':
            mesh.position.set(
              this.camera.position.x,
              this.camera.position.y - 20,
              this.camera.position.z - ThreeManager.CARDINAL_DIRECTION_DISTANCE,
            );
            break;
          case 'W':
            mesh.position.set(
              this.camera.position.x + ThreeManager.CARDINAL_DIRECTION_DISTANCE,
              this.camera.position.y - 20,
              this.camera.position.z,
            );
            break;
        }
        mesh.updateMatrix();
      });
    });
  }

  private _initialCamera(size: { width: number; height: number }) {
    this.camera.aspect = size.width / size.height;
    this.camera.position.x = 0;
    this.camera.position.y = 100;
    this.camera.position.z = -50;
    this.camera.updateProjectionMatrix();
  }

  private _initialRenderer(size: { width: number; height: number }) {
    this.renderer.setSize(size.width, size.height);
    this.renderer.setClearColor(0x000000, 1.0);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  private _initialLabelRender(size: { width: number; height: number }) {
    this.labelRenderer.setSize(size.width, size.height);
    this.labelRenderer.domElement.style.position = 'absolute';
    this.labelRenderer.domElement.style.zIndex = '1';
    this.labelRenderer.domElement.style.top = '0px';
    this.labelRenderer.domElement.style.pointerEvents = 'none';
    this.container.appendChild(this.labelRenderer.domElement);
  }

  initialTrackPath() {
    const indoorLineMaterial = globalTracker.track(
      new THREE.LineBasicMaterial({ color: 0x5296d5 }),
    );
    indoorLineMaterial.name = 'indoorLineMaterial';

    const outdoorLineMaterial = globalTracker.track(
      new THREE.LineBasicMaterial({ color: 0x78dc00 }),
    );
    outdoorLineMaterial.name = 'outdoorLineMaterial';

    this._materialMap.indoorLineMaterial = indoorLineMaterial;
    this._materialMap.outdoorLineMaterial = outdoorLineMaterial;
  }

  private _initialFog() {
    this.scene.fog = new THREE.FogExp2(0x000000, 0.002);
  }

  async initialPlant() {
    return new Promise<void>((resolve, reject) => {
      this._gltfLoader.load(
        '/static/gltf/excavator.glb',
        (gltf) => {
          gltf.scene.userData.name = 'basePlant';
          gltf.scene.scale.set(0.01, 0.01, 0.01);
          this._objectMap.excavator = gltf.scene;
          resolve();
        },
        () => {},
        (event) => {
          reject(event);
        },
      );
    });
  }

  async initialCCTV() {
    return new Promise<boolean>((resolve, reject) => {
      this._gltfLoader.load(
        '/static/gltf/cctv.glb',
        (gltf) => {
          gltf.scene.userData.name = 'baseCCTV';
          gltf.scene.rotateY(90 * (Math.PI / 180));
          gltf.scene.rotateZ(10 * (Math.PI / 180));

          gltf.scene.traverse((obj) => {
            obj.layers.set(this.layersChannel[6]);
          });
          this._objectMap.cctvModel = gltf.scene;

          this._objectEditMap.cctvModel = gltf.scene;
          gltf.scene.name = 'cctvEditMesh';
          resolve(true);
        },
        () => {},
        (event) => {
          reject(event);
        },
      );
    });
  }

  setOriginOffset(x: number, y: number, z: number) {
    this.originOffset = {
      x,
      y,
      z,
    };
  }

  private _addCardinalDirectionText = () => {
    const fontLoader = new FontLoader();
    const fontLink =
      'https://threejs.org/examples/fonts/helvetiker_bold.typeface.json';

    fontLoader.load(fontLink, (font) => {
      const material = globalTracker.track(
        new THREE.MeshBasicMaterial({
          color: 0xffffff,
          depthTest: false,
        }),
      );

      ThreeManager.CARDINAL_DIRECTION_TEXTS.forEach((direction) => {
        const geometry: TextGeometry = globalTracker.track(
          new TextGeometry(direction.name, {
            font: font,
            size: 2,
            height: 0,
            curveSegments: 3,
          }),
        );
        geometry.name = direction.name;

        const mesh: THREE.Mesh = globalTracker.track(
          new THREE.Mesh(geometry, material),
        );
        mesh.matrixAutoUpdate = false;

        mesh.renderOrder = 1;
        mesh.position.set(
          this.camera.position.x + direction.position[0],
          this.camera.position.y - 20 + direction.position[1],
          this.camera.position.z + direction.position[2],
        );

        switch (direction.name) {
          case 'N':
            mesh.rotateX(-Math.PI / 2);
            break;
          case 'E':
            mesh.rotateY(Math.PI / 2);
            mesh.rotateX(-Math.PI / 2);
            break;
          case 'S':
            mesh.rotateX(Math.PI / 2);
            break;
          case 'W':
            mesh.rotateY(Math.PI / 2);
            mesh.rotateX(Math.PI / 2);
            break;
        }

        this._directionMeshes.push(mesh);
        this.scene.add(mesh);
        mesh.updateMatrix();
      });
    });
  };

  dispose() {
    this._labelMap.forEach((label) => {
      label.element.remove();
      label.clear();
    });
    if (this.timerCCTV360) {
      clearTimeout(this.timerCCTV360);
    }

    this._contentMap.forEach((content) => {
      content.element.remove();
      content.clear();
    });

    globalTracker.dispose();
    this.controls.dispose();
    this._axesHelper?.dispose();
    this.scene.clear();
    this.renderer.clear();
    this.labelRenderer.domElement.remove();
    this._directionMeshes.length = 0;
    window.removeEventListener('resize', this._resize);
    cancelAnimationFrame(this._requestAnimationFrame);
  }

  getScene() {
    return this.scene;
  }

  getLoading() {
    return this.loadingManager;
  }

  private _setCCTVPanel(
    key: string,
    position: THREE.Vector3,
    isVisible: boolean,
    cctv: CCTV,
  ) {
    const videoOffset = { x: 0, y: 3.7, z: 0 };

    if (!this._labelMap.has(key)) {
      const container = document.createElement(
        'div',
      ) as HTMLDivElementWithFullscreen;
      container.setAttribute('class', 'cctv-panel');
      container.style.pointerEvents = 'all';
      const fullScreen = document.createElement('div');
      fullScreen.setAttribute('class', 'cctv-panel-fullscreen');
      fullScreen.style.opacity = '0';
      fullScreen.style.transition = 'opacity 0.4';

      const mute = document.createElement('div');
      mute.setAttribute('class', 'cctv-panel-mute');
      mute.style.opacity = '0';
      mute.style.transition = 'opacity 0.4';

      container.onmouseenter = () => {
        fullScreen.style.opacity = '1';
        mute.style.opacity = '1';
      };
      container.onmouseleave = () => {
        fullScreen.style.opacity = '0';
        mute.style.opacity = '0';
      };

      container.append(fullScreen, mute);

      if (isVisible) {
        container.removeAttribute('hidden');
      } else {
        container.setAttribute('hidden', '');
      }

      const video = document.createElement('video');
      video.muted = true;
      video.autoplay = true;
      video.controls = false;
      video.classList.add('cctv-video-3d');

      let canvasEl: HTMLCanvasElement | undefined;

      if (cctv.mode === '360') {
        canvasEl = document.createElement('canvas');
        canvasEl.width = 1920;
        canvasEl.height = 1080;
        canvasEl.classList.add('cctv-video-3d');
        video.classList.add('hidden');
        container.append(video, canvasEl);
      } else {
        container.append(video);
      }

      const trigglerFullScreen = () => {
        const customDocument = document as DocumentWithFullscreenElement;

        const isInFullScreen =
          (customDocument.fullscreenElement &&
            customDocument.fullscreenElement !== null) ||
          (customDocument.webkitFullscreenElement &&
            customDocument.webkitFullscreenElement !== null) ||
          (customDocument.mozFullScreenElement &&
            customDocument.mozFullScreenElement !== null);

        if (isInFullScreen) {
          if (customDocument.exitFullscreen) {
            customDocument.exitFullscreen();
          } else if (customDocument.webkitExitFullscreen) {
            customDocument?.webkitExitFullscreen();
          } else if (customDocument.mozCancelFullScreen) {
            customDocument.mozCancelFullScreen();
          } else if (customDocument.msExitFullscreen) {
            customDocument.msExitFullscreen();
          }
        } else {
          if (container.requestFullscreen) {
            container.requestFullscreen();
          } else if (container.webkitRequestFullscreen) {
            container.webkitRequestFullscreen();
          } else if (container.mozRequestFullScreen) {
            container.mozRequestFullScreen();
          } else if (container.msExitFullscreen) {
            container.msExitFullscreen();
          }
        }
      };

      fullScreen.addEventListener('click', trigglerFullScreen);

      mute.addEventListener('click', function () {
        mute.classList.toggle('active-mute');
        if (this.classList.contains('active-mute')) {
          video.muted = false;
        } else {
          video.muted = true;
        }
      });

      const video2d = new CSS2DObject(container);

      video2d.renderOrder = 1;
      video2d.position.copy(position);

      video2d.position.set(
        video2d.position.x + videoOffset.x,
        video2d.position.y + videoOffset.y,
        video2d.position.z + videoOffset.z,
      );

      this._contentMap.set(key, video2d);
      this._cctvConfig.set(key, {
        videoEl: video,
        canvasEl,
        srsWebrtc: new SrsWebrtc(cctv.rtmpEndpoint ?? '', video),
      });

      this.scene.add(video2d);
    } else {
      const video2d = this._contentMap.get(key);
      if (isVisible) {
        video2d?.element.removeAttribute('hidden');
      } else {
        video2d?.element.setAttribute('hidden', '');
      }

      if (video2d) {
        video2d.position.copy(position);
        video2d.position.set(
          video2d.position.x + videoOffset.x,
          video2d.position.y + videoOffset.y,
          video2d.position.z + videoOffset.z,
        );
        video2d.visible = true;
      }
    }
  }

  private _setLabel(
    key: string,
    data: WorkerConfig,
    position: THREE.Vector3,
    isVisible: boolean,
  ) {
    let label: CSS2DObject | undefined;
    if (!this._labelMap.has(key)) {
      const text = document.createElement('div');

      text.className = 'label';
      text.textContent = data.name ?? data.dasId;
      label = new CSS2DObject(text);

      this._labelMap.set(key, label);
      this.scene.add(label);
    } else {
      label = this._labelMap.get(key);
    }

    if (label) {
      label.position.copy(position);
      label.position.set(
        label.position.x,
        label.position.y + 1.5,
        label.position.z,
      );
      label.visible = isVisible;
    }
  }

  onUpdateLabel(
    key: string,
    data: WorkerConfig,
    position: THREE.Vector3,
    isVisible: boolean,
  ) {
    this._setLabel(key, data, position, isVisible);
  }

  setWorker(data: Array<WorkerConfig>) {
    ThreeManager._dummy.scale.set(0, 0, 0);
    ThreeManager._dummy.updateMatrix();
    this._prevousSetWorker.forEach((d) => {
      const worker = this._objectMap[`${d.type}Worker`] as THREE.Group;
      if (worker) {
        const label = this._labelMap.get(worker.userData.dasId);

        if (label) {
          label.visible = false;
        }

        worker.children.forEach((obj) => {
          const mesh = obj as THREE.InstancedMesh;
          mesh.setMatrixAt(d.index, ThreeManager._dummy.matrix);
          mesh.instanceMatrix.needsUpdate = true;
        });
      }
    });

    this._prevousSetWorker.length = 0;

    data.forEach((d, i) => {
      this._prevousSetWorker.push({ index: i, type: d.status });
      const worker = this._objectMap[`${d.status}Worker`] as THREE.Group;

      if (worker) {
        worker.userData.name = d.name;
        worker.userData.dasId = d.dasId;

        ThreeManager._dummy.scale.set(1, 1, 1);
        ThreeManager._dummy.position.set(
          d.position.x - this.originOffset.x,
          d.position.y - this.originOffset.y,
          d.position.z - this.originOffset.z,
        );
        ThreeManager._dummy.updateMatrix();

        const key = `${d.dasId}-worker`;

        this._setLabel(
          key,
          d,
          ThreeManager._dummy.position,
          this.workerLabelVisible,
        );

        worker.children.forEach((obj) => {
          const mesh = obj as THREE.InstancedMesh;
          mesh.visible = true;
          mesh.setMatrixAt(i, ThreeManager._dummy.matrix);
          mesh.instanceMatrix.needsUpdate = true;
        });

        ThreeManager.resetDummy();
      }
    });
  }

  setDaswater(data: Array<WorkerConfig>) {
    ThreeManager._dummy.scale.set(0, 0, 0);
    ThreeManager._dummy.updateMatrix();

    data.forEach((d, i) => {
      const daswater = this._objectMap.daswater as THREE.Group;

      if (daswater) {
        daswater.userData.name = d.name;
        daswater.userData.dasId = d.dasId;

        ThreeManager._dummy.scale.set(1, 1, 1);
        ThreeManager._dummy.position.set(
          d.position.x - this.originOffset.x,
          d.position.y - this.originOffset.y,
          d.position.z - this.originOffset.z,
        );
        ThreeManager._dummy.updateMatrix();

        const key = `${d.dasId}-daswater`;

        this._setLabel(
          key,
          d,
          ThreeManager._dummy.position,
          this.daswaterLabelVisible,
        );

        daswater.children.forEach((obj) => {
          const mesh = obj as THREE.InstancedMesh;
          mesh.visible = true;
          mesh.setMatrixAt(i, ThreeManager._dummy.matrix);
          mesh.instanceMatrix.needsUpdate = true;
        });

        ThreeManager.resetDummy();
      }
    });
  }

  setDaswatch(data: Array<WorkerConfig>) {
    ThreeManager._dummy.scale.set(0, 0, 0);
    ThreeManager._dummy.updateMatrix();
    this._prevousSetWorkerDaswatch.forEach((d) => {
      const workerDaswatch = this._objectMap[
        `${d.type}Daswatch`
      ] as THREE.Group;
      if (workerDaswatch) {
        const label = this._labelMap.get(workerDaswatch.userData.dasId);

        if (label) {
          label.visible = false;
        }

        workerDaswatch.children.forEach((obj) => {
          const mesh = obj as THREE.InstancedMesh;
          mesh.setMatrixAt(d.index, ThreeManager._dummy.matrix);
          mesh.instanceMatrix.needsUpdate = true;
        });
      }
    });

    this._prevousSetWorkerDaswatch.length = 0;

    data.forEach((d, i) => {
      this._prevousSetWorkerDaswatch.push({ index: i, type: d.status });
      const workerDaswatch = this._objectMap[
        `${d.status}Daswatch`
      ] as THREE.Group;

      if (workerDaswatch) {
        workerDaswatch.userData.name = d.name;
        workerDaswatch.userData.dasId = d.dasId;

        ThreeManager._dummy.scale.set(1, 1, 1);
        ThreeManager._dummy.position.set(
          d.position.x - this.originOffset.x,
          d.position.y - this.originOffset.y,
          d.position.z - this.originOffset.z,
        );
        ThreeManager._dummy.updateMatrix();

        const key = `${d.dasId}-daswatch`;

        this._setLabel(
          key,
          d,
          ThreeManager._dummy.position,
          this.daswatchLabelVisible,
        );

        workerDaswatch.children.forEach((obj) => {
          const mesh = obj as THREE.InstancedMesh;
          mesh.visible = true;
          mesh.setMatrixAt(i, ThreeManager._dummy.matrix);
          mesh.instanceMatrix.needsUpdate = true;
        });

        ThreeManager.resetDummy();
      }
    });
  }

  setPlant(plants: Array<WorkerConfig>) {
    Object.entries(this._objectMap).forEach(([key, obj]) => {
      if (/.*-plant/.test(key) && obj) {
        obj.visible = false;
      }
    });

    plants.forEach((plant) => {
      const key = `${plant.dasId}-plant`;
      let excavator: THREE.Group;

      if (!this._objectMap[key] && this._objectMap.excavator) {
        excavator = globalTracker.track(
          this._objectMap.excavator.clone() as THREE.Group,
        );
        this._objectMap[key] = excavator;
        this.scene.add(excavator);
      } else {
        excavator = this._objectMap[key] as THREE.Group;
      }

      if (excavator) {
        excavator.visible = true;
        excavator.position.set(
          plant.position.x - this.originOffset.x,
          plant.position.y - this.originOffset.y,
          plant.position.z - this.originOffset.z,
        );
        excavator.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material = this._materialMap[`${plant.status}Material`];
          }
        });

        this._setLabel(key, plant, excavator.position, this.plantLabelVisible);
      }
    });
  }

  setCCTV(cctvs: Array<WorkerConfig & { cctv: CCTV }>) {
    Object.entries(this._objectMap).forEach(([key, obj]) => {
      if (/.*-cctvModel/.test(key) && obj) {
        obj.visible = false;
      }
    });
    cctvs.forEach((cctv) => {
      const key = `${cctv.dasId}-cctvModel`;
      let cctvModel: THREE.Group;

      if (!this._objectMap[key] && this._objectMap.cctvModel) {
        cctvModel = globalTracker.track(
          this._objectMap.cctvModel.clone() as THREE.Group,
        );
        this._objectMap[key] = cctvModel;
        this.scene.add(cctvModel);
      } else {
        cctvModel = this._objectMap[key] as THREE.Group;
      }
      if (cctvModel) {
        cctvModel.visible = true;
        cctvModel.position.set(
          cctv.position.x - this.originOffset.x,
          cctv.position.y - this.originOffset.y,
          cctv.position.z - this.originOffset.z,
        );

        this._setCCTVPanel(
          cctv.cctv.name,
          cctvModel.position,
          this.cctvConentVisible,
          cctv.cctv,
        );
        this._setLabel(key, cctv, cctvModel.position, this.cctvLabelVisible);
      }
    });
  }

  setLocators(
    locators: Array<
      WorkerConfig & {
        deviceType: LocatorType3D;
        aoa?: AOA | undefined;
        beacon?: Beacon | undefined;
        aoaUwb?: AOAUwb | undefined;
      }
    >,
  ) {
    Object.entries(this._objectEditMap).forEach(([key, obj]) => {
      if (/.*-(aoa|beacon)/.test(key) && obj) {
        obj.visible = false;
      }
    });

    locators.forEach((locator) => {
      const key = `${locator.dasId}-${locator.deviceType}`;
      let locatorModel: THREE.Mesh;

      if (
        !this._objectEditMap[key] &&
        this._objectEditMap[locator.deviceType]
      ) {
        locatorModel = globalTracker.track(
          (this._objectEditMap[locator.deviceType] as THREE.Mesh).clone(),
        );

        this._objectEditMap[key] = locatorModel;
        this.scene.add(locatorModel);
      } else {
        locatorModel = this._objectEditMap[key] as THREE.Mesh;
      }

      if (locatorModel) {
        locatorModel.visible = true;
        locatorModel.position.set(
          locator.position.x - this.originOffset.x,
          locator.position.y - this.originOffset.y,
          locator.position.z - this.originOffset.z,
        );
        locatorModel.rotateY(((locator.rotation ?? 0) * Math.PI) / 180);
        locatorModel.userData = {
          dasId: locator.dasId,
          key,
          locator,
          position: locatorModel.position,
        };
        const keyLocatorLabel = `${locator.dasId}-locator`;
        this._setLabel(
          keyLocatorLabel,
          locator,
          locatorModel.position,
          this.locatorLabelVisible,
        );
      }
    });
  }

  setDasbeacon(dasbeacons: Array<WorkerConfig>) {
    const dasbeacon = this._objectMap.dasbeacon as THREE.InstancedMesh;
    if (dasbeacon) {
      ThreeManager._dummy.scale.set(0, 0, 0);
      ThreeManager._dummy.updateMatrix();

      for (let i = 0; i < 10000; i++) {
        dasbeacon.setMatrixAt(i, ThreeManager._dummy.matrix);
      }

      dasbeacons.forEach((d, i) => {
        ThreeManager._dummy.scale.set(1, 1, 1);
        ThreeManager._dummy.position.set(
          d.position.x - this.originOffset.x,
          d.position.y - this.originOffset.y,
          d.position.z - this.originOffset.z,
        );
        ThreeManager._dummy.updateMatrix();

        const key = `${d.dasId}-locator-dasbeacon`;

        this._setLabel(
          key,
          d,
          ThreeManager._dummy.position,
          this.locatorLabelVisible,
        );

        dasbeacon.setMatrixAt(i, ThreeManager._dummy.matrix);
        dasbeacon.instanceMatrix.needsUpdate = true;

        ThreeManager.resetDummy();
      });
    }
  }

  setDasaoa(dasaoas: Array<WorkerConfig>) {
    const dasaoa = this._objectMap.dasaoa as THREE.InstancedMesh;

    if (dasaoa) {
      ThreeManager._dummy.scale.set(0, 0, 0);
      ThreeManager._dummy.updateMatrix();

      for (let i = 0; i < 1000; i++) {
        dasaoa.setMatrixAt(i, ThreeManager._dummy.matrix);
      }

      dasaoas.forEach((d, i) => {
        ThreeManager._dummy.scale.set(1, 1, 1);
        ThreeManager._dummy.position.set(
          d.position.x - this.originOffset.x,
          d.position.y - this.originOffset.y,
          d.position.z - this.originOffset.z,
        );
        ThreeManager._dummy.rotateY(((d?.rotation ?? 0) * Math.PI) / 180);
        ThreeManager._dummy.updateMatrix();

        const key = `${d.dasId}-locator-dasaoa`;

        this._setLabel(
          key,
          d,
          ThreeManager._dummy.position,
          this.locatorLabelVisible,
        );

        dasaoa.setMatrixAt(i, ThreeManager._dummy.matrix);
        dasaoa.instanceMatrix.needsUpdate = true;

        ThreeManager.resetDummy();
      });
    }
  }

  setAOATag(aoaTags: Array<WorkerConfig>) {
    const aoaTag = this._objectMap.aoaTag as THREE.InstancedMesh;

    if (aoaTag) {
      ThreeManager._dummy.scale.set(0, 0, 0);
      ThreeManager._dummy.updateMatrix();

      for (let i = 0; i < 1000; i++) {
        aoaTag.setMatrixAt(i, ThreeManager._dummy.matrix);
      }

      aoaTags.forEach((d, i) => {
        ThreeManager._dummy.scale.set(1, 1, 1);
        ThreeManager._dummy.position.set(
          d.position.x - this.originOffset.x,
          d.position.y - this.originOffset.y,
          d.position.z - this.originOffset.z,
        );

        ThreeManager._dummy.rotateY(((d.rotation ?? 0) * Math.PI) / 180);
        ThreeManager._dummy.updateMatrix();

        const key = `${d.dasId}-aoaTag`;

        this._setLabel(
          key,
          d,
          ThreeManager._dummy.position,
          this.aoaTagLabelVisible,
        );

        aoaTag.setMatrixAt(i, ThreeManager._dummy.matrix);
        aoaTag.instanceMatrix.needsUpdate = true;

        ThreeManager.resetDummy();
      });
    }
  }

  updateEditSetting(setting: WorkerConfig) {
    if (this.editMode === 'aoa' || this.editMode === 'beacon') {
      const locator = this._objectEditMap[this.editMode] as THREE.Mesh;
      if (locator) {
        locator.scale.set(1, 1, 1);
        locator.position.set(
          setting.position.x - this.originOffset.x,
          setting.position.y - this.originOffset.y,
          setting.position.z - this.originOffset.z,
        );
        locator.rotation.y = ((setting.rotation ?? 0) * Math.PI) / 180;
      }
    }

    if (this.editMode === 'daswater') {
      const daswater = this._objectEditMap[this.editMode] as THREE.Group;
      if (daswater) {
        daswater.position.set(
          setting.position.x - this.originOffset.x,
          setting.position.y - this.originOffset.y,
          setting.position.z - this.originOffset.z,
        );
      }
    }

    if (this.editMode === 'dasconcrete') {
      const dasconcrete = this._objectEditMap[this.editMode] as THREE.Group;
      if (dasconcrete) {
        dasconcrete.position.set(
          setting.position.x - this.originOffset.x,
          setting.position.y - this.originOffset.y,
          setting.position.z - this.originOffset.z,
        );
      }
    }

    if (this.editMode === 'cctv') {
      const cctvEdit = this._objectEditMap.cctvModel as THREE.Group;
      if (cctvEdit) {
        cctvEdit.position.set(
          setting.position.x - this.originOffset.x,
          setting.position.y - this.originOffset.y,
          setting.position.z - this.originOffset.z,
        );
        this.scene.add(cctvEdit);
      }
    }
  }

  setAreaSetting(areas: AreaSettingThreeManager) {
    Object.keys(this._objectEditMap).forEach((key) => {
      if (/.*-editArea$/.test(key)) {
        globalTracker.untrack(this._objectEditMap[key]);
        this._objectEditMap[key] = undefined;
        delete this._objectEditMap[key];
      }
    });

    areas.forEach((area) => {
      const mesh: THREE.Mesh = this._areaCreator.create(
        {
          positions: area.positions.map((p) => {
            return [
              p[0] - this.originOffset.x,
              p[1] - this.originOffset.y,
              p[2] - this.originOffset.z,
            ];
          }),
          height: area.height,
        },
        this._materialMap[`${area.type}Material`],
      );

      const boundingbox = new THREE.Box3().setFromObject(mesh);
      const centerVector = boundingbox.getCenter(new THREE.Vector3());

      const key = `${area.id}-editArea`;

      ThreeManager._dummy.updateMatrix();
      ThreeManager._dummy.position.set(
        centerVector.x,
        area.altitude + area.height - this.originOffset.y,
        centerVector.z,
      );

      ThreeManager.resetDummy();

      this._objectEditMap[key] = mesh;
      this.scene.add(mesh);
    });
  }

  setArea(
    areas: Array<
      Omit<Area, 'area'> & { positions: Array<[number, number, number]> }
    >,
  ) {
    Object.keys(this._objectMap).forEach((key) => {
      if (/.*-area$/.test(key)) {
        globalTracker.untrack(this._objectMap[key]);
        this._objectMap[key] = undefined;
        delete this._objectMap[key];
      }
    });

    return areas.map((area) => {
      const mesh: THREE.Mesh = this._areaCreator.create(
        {
          positions: area.positions.map((p) => {
            return [
              p[0] - this.originOffset.x,
              p[1] - this.originOffset.y,
              p[2] - this.originOffset.z,
            ];
          }),
          height: area.height,
        },
        this._materialMap[`${area.type}Material`],
      );
      mesh.layers.set(this.layersChannel[7]);

      const boundingbox = new THREE.Box3().setFromObject(mesh);
      const centerVector = boundingbox.getCenter(new THREE.Vector3());

      const key = `${area.id}-area`;

      ThreeManager.resetDummy();
      ThreeManager._dummy.position.set(
        centerVector.x,
        area.altitude + area.height - this.originOffset.y,
        centerVector.z,
      );
      ThreeManager._dummy.updateMatrix();

      this._setLabel(
        key,
        {
          dasId: area.id,
          name: area.name,
          position: {
            x: ThreeManager._dummy.position.x,
            y: ThreeManager._dummy.position.y,
            z: ThreeManager._dummy.position.z,
          },
          status: 'indoor',
        },
        ThreeManager._dummy.position,
        this.areaLabelVisible,
      );

      this._objectMap[key] = mesh;
      this.scene.add(mesh);

      return {
        x: centerVector.x + this.originOffset.x,
        y:
          area.altitude +
          area.height -
          this.originOffset.y +
          this.originOffset.y,
        z: centerVector.z + this.originOffset.z,
      };
    });
  }

  displayLabel(
    bool: boolean,
    type: DeviceType | 'area' | 'aoaTag' | 'plant' | 'worker',
  ) {
    switch (type) {
      case 'worker':
        this.workerLabelVisible = bool;
        break;
      case 'plant':
        this.plantLabelVisible = bool;
        break;
      case 'locator':
        this.locatorLabelVisible = bool;
        break;
      case 'area':
        this.areaLabelVisible = bool;
        break;
      case 'aoaTag':
        this.aoaTagLabelVisible = bool;
        break;
      case 'cctv':
        this.cctvLabelVisible = bool;
        break;
      case 'daswatch':
        this.daswatchLabelVisible = bool;
        break;
      case 'daswater':
        this.daswaterLabelVisible = bool;
    }
    const regex = new RegExp(`.*(-${type})`);
    this._labelMap.forEach((label, key) => {
      if (regex.test(key)) {
        label.visible = bool;
      }
    });
  }

  displayCCTV(value: string, bool) {
    this._contentMap.forEach((content, key) => {
      if (value === key) {
        if (bool) {
          content.element.removeAttribute('hidden');
        } else {
          content.element.setAttribute('hidden', '');
        }
      }
    });
  }

  triggerCctv(value: string, bool) {
    this._cctvConfig.forEach((item, key) => {
      if (value === key) {
        if (bool) {
          if (item.canvasEl) {
            const video360Renderer = new Video360Renderer(
              item.videoEl,
              item.canvasEl,
              {
                width: 1920,
                height: 1080,
              },
            );
            item.video360Renderer = video360Renderer;
          }
          item.srsWebrtc.play();
        } else {
          if (item.canvasEl) {
            item.video360Renderer?.dispose();
          }
          item.srsWebrtc.close();
        }
      }
    });
  }

  createTrackPath(data: Point[], startPointer: number, endPointer: number) {
    this._previousLines.forEach((obj) => {
      globalTracker.untrack(obj);
    });
    this._previousLines.length = 0;

    this._lineSegments = toLineSegments(
      data.map((d) => {
        return {
          ...d,
          position: [
            d.position[0] - this.originOffset.x,
            d.position[1] - this.originOffset.y,
            d.position[2] - this.originOffset.z,
          ],
        };
      }),
    );

    const drawRanges = toDrawRanges2Point(
      this._lineSegments,
      startPointer,
      endPointer,
    );

    this._lineSegments.forEach(({ position, type }, i) => {
      const geometry: THREE.BufferGeometry = globalTracker.track(
        new THREE.BufferGeometry(),
      );

      geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(Float32Array.from(position), 3),
      );
      geometry.setDrawRange(drawRanges[i].start, drawRanges[i].count);

      const line: THREE.Line = globalTracker.track(
        new THREE.Line(geometry, this._materialMap[`${type}LineMaterial`]),
      );

      this._previousLines.push(geometry);
      this._previousLines.push(line);
      this.scene.add(line);
    });
  }

  setTrackPath(startPointer: number, endPointer: number) {
    const drawRanges = toDrawRanges2Point(
      this._lineSegments,
      startPointer,
      endPointer,
    );

    const lines: THREE.Line[] = this._previousLines.filter(
      (obj) => obj instanceof THREE.Line,
    );

    lines.forEach((line, i) => {
      line.geometry.setDrawRange(drawRanges[i].start, drawRanges[i].count);
    });
  }

  flyTo(x: number, y: number, z: number) {
    clearInterval(this.flyToTimer);
    const target = new THREE.Vector3(
      x - this.originOffset.x,
      y - this.originOffset.y,
      z - this.originOffset.z,
    );
    this.controls.target = target;
    const cameraOrigin = this.camera.position.clone();
    const vector = target
      .clone()
      .add(this.camera.position.clone().multiplyScalar(-1));
    const unitVector = vector.clone().normalize();

    const lookAtOrigin = new THREE.Vector3(0, 0, -1)
      .applyQuaternion(this.camera.quaternion)
      .setY(target.y);

    vector.add(
      unitVector
        .clone()
        .multiplyScalar(-5)
        .add(
          unitVector
            .clone()
            .multiplyScalar(5)
            .multiply(new THREE.Vector3(0, 1, 0)),
        ),
    );

    let t = 0;
    this.flyToTimer = setInterval(() => {
      if (t <= 60) {
        const newLookAt = lookAtOrigin
          .clone()
          .lerp(target, easeInOutQuad(t / 60));
        this.camera.lookAt(newLookAt);
        const vec = cameraOrigin
          .clone()
          .add(vector.clone().multiplyScalar(easeInOutQuad(t / 60)));
        this.camera.position.set(vec.x, vec.y, vec.z);
        t++;
      } else {
        clearInterval(this.flyToTimer);
      }
    }, 1500 / 60);
  }

  setDasConcretes(dasConcreteConfigs: Array<DasConcreteConfig>) {
    const dasConcreteMesh = this._objectMap.DasConcrete as THREE.InstancedMesh;
    if (dasConcreteMesh) {
      // hide all
      ThreeManager._dummy.scale.set(0, 0, 0);
      ThreeManager._dummy.updateMatrix();
      for (let i = 0; i < 1000; i++) {
        dasConcreteMesh.setMatrixAt(i, ThreeManager._dummy.matrix);
      }

      ThreeManager.resetDummy();
      const color = new THREE.Color();

      dasConcreteConfigs.forEach((d, i) => {
        dasConcreteMesh.userData.dasId = d.dasId;

        ThreeManager._dummy.position.set(
          d.position.x - this.originOffset.x,
          d.position.y - this.originOffset.y,
          d.position.z - this.originOffset.z,
        );
        ThreeManager._dummy.updateMatrix();

        dasConcreteMesh.setMatrixAt(i, ThreeManager._dummy.matrix);
        dasConcreteMesh.setColorAt(
          i,
          color.setHex(getStrengthColor(d.strength)),
        );
      });

      if (dasConcreteMesh.instanceColor) {
        dasConcreteMesh.instanceColor.needsUpdate = true;
      }
      dasConcreteMesh.instanceMatrix.needsUpdate = true;

      ThreeManager.resetDummy();
    }
  }

  hideShowAllLabel(state: boolean) {
    this._labelMap.forEach((label) => {
      label.visible = state;
    });
  }

  hideAnObject(id: string, state: boolean) {
    const regexObjectId = new RegExp(`^${id}`);
    for (const key in this._objectMap) {
      if (regexObjectId.test(key)) {
        const selectedObject = this._objectMap[key];
        if (selectedObject) {
          selectedObject.visible = state;
        }
      }
    }
  }

  getAnObject(id: string) {
    const regexObjectId = new RegExp(`^${id}`);
    let selectedObject: THREE.Mesh | THREE.Group | undefined;
    for (const key in this._objectMap) {
      if (regexObjectId.test(key)) {
        selectedObject = this._objectMap[key];
      }
    }
    return selectedObject;
  }
}

export default ThreeManager;
