import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  Contact,
  EnvironmentWillAssign,
  Group,
  StatusType,
} from '../../../types';
import { Environment } from '../../../types/Resource';

import {
  bindProjectContact,
  unBindProjectContact,
} from '../../../apis/ProjectApi';
import {
  setResourceGroups,
  updateResource,
  uploadResourceAvatar,
} from '../../../apis/ResourceApi';
import { useAppSelector } from '../../../hooks';
import useFilteredGroups from '../../../hooks/useFilteredGroups';

import CheckListItem from '../../../components/CheckListItem';
import ConfirmButton from '../../../components/ConfirmButton';
import ManagementDialog from '../../../components/Dialog/ManagementDialog';

import CompleteButtonSvgIcon from '../../../assets/SvgIcon/CompleteButtonSvgIcon';
import LoadingButtonSvgIcon from '../../../assets/SvgIcon/LoadingButtonSvgIcon';
import SaveSvgIcon from '../../../assets/SvgIcon/SaveSvgIcon';
import mainTheme from '../../../theme';
import { checkIsArrayEqual } from '../../../utils/checkArrayEqual';
import { navigatorEnvironmant as navigator } from '../../../utils/common';
import AreaContent from '../edit-content/AreaContent';
import ContactEdit from '../edit-content/ContactEdit';
import EnvironmentBasicInformationContent from '../edit-content/EnvironmentBasicInformationContent';
import GroupEdit from '../edit-content/GroupEdit';
import {
  checkArea,
  checkContactAvailable,
  checkEnvironmentBasicInformationAvailable,
  checkIsFormEnvironmentEdited,
} from '../validation/environment-validation';
import { checkIsAvatarUpdated } from '../validation/management-validation';

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  display: flex;
  flex: 1;
`;

const ContentWrapper = styled('div')`
  width: 340px;
  border-radius: 4px;
  display: flex;
  flex: 1;

  & .loading {
    height: 100%;
  }
`;

const DialogActions = styled(MuiDialogActions)`
  display: flex;
  padding: 24px;
  height: 80px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  width: 280px;
  margin-top: 20px;
`;

interface EditEnvironmentDialogProps extends DialogProps {
  title: string;
  navigatorIndex: number;
  groups: Array<Group> | undefined;
  selectedGroups: Array<Group>;
  selectedResource: Environment | undefined;
  resourceContacts: Array<Contact>;
  onSuccessCreate: () => void;
  onSuccessBind?: () => void;
  onCloseDialog: () => void;
  onSelectNavigatorIndex: (index: number) => void;
}

const EditEnvironment: React.FC<EditEnvironmentDialogProps> = ({
  open,
  title,
  navigatorIndex,
  groups,
  selectedGroups,
  selectedResource,
  resourceContacts,
  onCloseDialog,
  onSuccessCreate,
  onSuccessBind,
  onSelectNavigatorIndex,
}) => {
  const projectId = useParams().projectId;
  const { policies, role: userProjectRole } = useAppSelector(
    (store) => store.projects,
  );
  const queryClient = useQueryClient();
  const { mutateAsync: mutateUpdateEnvironment } = useMutation(updateResource);
  const { mutateAsync: mutateUploadResourceAvatar } =
    useMutation(uploadResourceAvatar);
  const { mutateAsync: mutateSetResourceGroups } =
    useMutation(setResourceGroups);
  const { t } = useTranslation('project-setting');
  const [checkedValue, setCheckedValue] = useState(
    new Array(navigator.length).fill(false),
  );
  const [saveButtonStatus, setSaveButtonStatus] = useState<
    'loading' | 'success'
  >();
  const [environmentDataWillAssign, setEnvironmentDataWillAssign] =
    useState<EnvironmentWillAssign>();
  const [groupsWillAssign, setGroupsWillAssign] = useState<Array<string>>([]);
  const [listContact, setListContact] = useState<string[]>(
    resourceContacts.map((contact) => contact.id),
  );

  const [status, setStatus] = useState<StatusType>('default');
  const [message, setMessage] = useState('');
  const [checkedNavigator, setCheckedNavigator] = useState(
    new Array(navigator.length).fill(false),
  );
  const initContact = resourceContacts.map((contact) => contact.id);

  const { mutateAsync: mutateUnbindContact } =
    useMutation(unBindProjectContact);

  const { mutateAsync: mutateBindContact } = useMutation(bindProjectContact);

  const filteredGroups = useFilteredGroups(
    'WriteOneEnvironmentGroup',
    groups ?? [],
    userProjectRole,
    policies,
  );

  useEffect(() => {
    setEnvironmentDataWillAssign({
      id: selectedResource?.id ?? '',
      name: selectedResource?.name ?? '',
      imageUrl: selectedResource?.imageUrl ?? '',
      groupIds: selectedResource?.groupIds ?? [],
      remark: selectedResource?.remark ?? undefined,
      orgId: selectedResource?.orgId ?? '',
      areaId: selectedResource?.areaId ?? '',
    });
  }, [selectedResource]);

  useEffect(() => {
    if (open) {
      setGroupsWillAssign(selectedGroups.map((group) => group.id));
    }
  }, [open, selectedGroups]);

  const saveable = () => {
    if (selectedResource && environmentDataWillAssign && resourceContacts) {
      if (
        !checkIsArrayEqual(selectedResource.groupIds, groupsWillAssign) ||
        !checkIsArrayEqual(initContact, listContact) ||
        checkIsAvatarUpdated(environmentDataWillAssign.avatarFile) ||
        checkIsFormEnvironmentEdited(
          selectedResource,
          environmentDataWillAssign,
        ) ||
        selectedResource.areaId !== environmentDataWillAssign.areaId
      ) {
        return true;
      }
    }

    return false;
  };

  const handleEnvironmentDataWillAssign = (data: EnvironmentWillAssign) => {
    setEnvironmentDataWillAssign(data);
  };

  useEffect(() => {
    const updateChecked = checkedValue.map((item, index) =>
      index === navigatorIndex ? true : false,
    );
    setCheckedValue(updateChecked);
  }, [navigatorIndex]);

  const handleCloseEditDialog = () => {
    onCloseDialog();
    setEnvironmentDataWillAssign(undefined);
    setListContact([]);
    setGroupsWillAssign([]);
    onSelectNavigatorIndex(1);
    setStatus('default');
    setMessage('');
  };

  const unbindContacts = async (contactIds: string[]) => {
    const requestUnbindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateUnbindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: environmentDataWillAssign?.id ?? '',
      });
      return data;
    });
    await Promise.all(requestUnbindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries(['project-contacts', projectId as string]);
  };

  const bindContacts = async (contactIds: string[]) => {
    const requestBindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateBindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: environmentDataWillAssign?.id ?? '',
        type: 'environment',
      });
      return data;
    });
    await Promise.all(requestBindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries(['project-contacts', projectId as string]);
  };

  const handleUpdoadAvatar = async (resourceId: string, file: File) => {
    await mutateUploadResourceAvatar({
      projectId: projectId ?? '',
      resourceType: 'environment',
      resourceId,
      imageFile: file,
    });
  };

  const handleUpdateResource = async (
    data: EnvironmentWillAssign,
    resourceId: string,
  ) => {
    const { name, orgId, areaId, remark } = data;

    const res = await mutateUpdateEnvironment(
      {
        projectId: projectId as string,
        resourceType: 'environment',
        resourceId,
        payload: {
          orgId,
          name,
          areaId,
          remark: remark ?? undefined,
        },
      },
      {
        onError: (error) => {
          if (error instanceof Error) {
            setMessage(error.message);
            setStatus('error');
          }
        },
      },
    );

    return res.data.data;
  };

  const updateGroups = async (resourceId?: string) => {
    if (!resourceId) {
      setMessage('No specific resourceId');
      setStatus('error');
    } else if (groupsWillAssign.length > 0) {
      await mutateSetResourceGroups({
        projectId: projectId as string,
        resourceType: 'environment',
        resourceId,
        payload: {
          groupIds: groupsWillAssign,
        },
      });
    } else {
      setMessage('Must select at least one group');
      setStatus('error');
    }
  };

  const handleSubmit = async () => {
    if (!environmentDataWillAssign) return;
    setSaveButtonStatus('loading');
    setStatus('default');
    try {
      await handleUpdateResource(
        environmentDataWillAssign,
        environmentDataWillAssign.id,
      );

      await updateGroups(selectedResource?.id);

      if (environmentDataWillAssign.avatarFile) {
        await handleUpdoadAvatar(
          environmentDataWillAssign.id,
          environmentDataWillAssign.avatarFile,
        );
      }

      // UNBIND CONTACT REQUEST
      const listContactUnbind = initContact.filter(
        (id) => !listContact.includes(id),
      );
      if (listContactUnbind.length > 0) {
        unbindContacts(listContactUnbind);
      }

      // BIND CONTACT REQUEST
      const listContactWillBind = listContact.filter(
        (id) => !initContact.includes(id),
      );
      if (listContactWillBind.length > 0) {
        bindContacts(listContactWillBind);
      }

      const onSuccsess = () => {
        setSaveButtonStatus('success');
        onSuccessCreate();
        onSuccessBind?.();
      };

      let timer: NodeJS.Timeout;
      let timer2: NodeJS.Timeout;
      timer = setTimeout(() => {
        onSuccsess();
        handleCloseEditDialog();

        timer2 = setTimeout(() => {
          setSaveButtonStatus(undefined);
        }, 500);
      }, 500);
      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } catch (err) {
      setSaveButtonStatus(undefined);
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    }
  };

  let saveButtonStatusIcon;
  switch (saveButtonStatus) {
    case 'loading':
      saveButtonStatusIcon = <LoadingButtonSvgIcon />;
      break;
    case 'success':
      saveButtonStatusIcon = <CompleteButtonSvgIcon />;
      break;
    default:
      saveButtonStatusIcon = <SaveSvgIcon />;
  }

  useEffect(() => {
    setCheckedNavigator(
      checkedNavigator.map((_, index) =>
        index === navigatorIndex ? true : false,
      ),
    );
  }, [navigatorIndex]);

  const handleOnChangeArea = (areaId: string) => {
    setEnvironmentDataWillAssign({
      id: selectedResource?.id ?? '',
      name: selectedResource?.name ?? '',
      imageUrl: selectedResource?.imageUrl ?? '',
      groupIds: selectedResource?.groupIds ?? [],
      remark: selectedResource?.remark ?? undefined,
      orgId: selectedResource?.orgId ?? '',
      areaId,
    });
  };

  const handleOnChangeContact = (value: any) => {
    const contactId = value;
    const isContain = listContact.includes(contactId);
    if (!isContain) {
      setListContact([...listContact, contactId]);
    } else {
      setListContact(listContact.filter((item) => item !== contactId));
    }
  };

  return (
    <ManagementDialog
      open={open}
      onClose={handleCloseEditDialog}
      status={status}
      message={message}
      title={title}
    >
      <DialogContent>
        <CheckList>
          {navigator.map((item, index) => {
            let checked: boolean = false;
            switch (item.name) {
              case 'group':
                checked = true;
                break;
              case 'environment-basic-information':
                checked = checkEnvironmentBasicInformationAvailable(
                  environmentDataWillAssign,
                );
                break;
              case 'area':
                checked = checkArea(environmentDataWillAssign?.areaId);
                break;
              case 'emergency-contact':
                checked = checkContactAvailable(listContact);
                break;
            }
            return (
              <CheckListItem
                key={item.id}
                name={t(`navigator.${item.name}`)}
                required={item.required}
                checked={checked}
                onClick={() => onSelectNavigatorIndex(index)}
                selected={checkedValue[index]}
                data-cy={`btn-list-navigator-environment-${item.name}`}
              />
            );
          })}
        </CheckList>
        <ContentWrapper className="content wrapper">
          {navigator[navigatorIndex].name === 'group' && (
            <GroupEdit
              groupIdsSelected={groupsWillAssign}
              handleGroupAssign={(groupIds) => setGroupsWillAssign(groupIds)}
              listGroup={filteredGroups}
              resource="environment"
            />
          )}
          {navigator[navigatorIndex].name ===
            'environment-basic-information' && (
            <EnvironmentBasicInformationContent
              data={environmentDataWillAssign}
              handleEnvironmentDataWillAssign={handleEnvironmentDataWillAssign}
            />
          )}
          {navigator[navigatorIndex].name === 'area' && (
            <AreaContent
              onChange={handleOnChangeArea}
              projectId={projectId}
              selectedAreaId={environmentDataWillAssign?.areaId}
            />
          )}
          {navigator[navigatorIndex].name === 'emergency-contact' && (
            <ContactEdit
              onChange={handleOnChangeContact}
              listContact={listContact}
            />
          )}
        </ContentWrapper>
      </DialogContent>
      <DialogActions>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': {
              background: mainTheme.color.secondary.$60,
            },
            marginRight: 'auto',
          }}
          color="secondary"
          onClick={handleCloseEditDialog}
          data-cy="btn-cancel-edited-environment"
        >
          {t('cancel')}
        </DialogButton>
        <ConfirmButton
          sx={{
            '& .MuiButton-startIcon > svg': {
              width: '32px',
              height: '32px',
            },
          }}
          startIcon={saveButtonStatusIcon}
          status={saveButtonStatus}
          onClick={handleSubmit}
          disabled={!saveable()}
          data-cy="btn-save-edited-environment"
        >
          {t('save')}
        </ConfirmButton>
      </DialogActions>
    </ManagementDialog>
  );
};

export default EditEnvironment;
