import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { styled } from '@mui/material';
import * as turf from '@turf/turf';
import { Feature, MultiPolygon } from 'geojson';
import { GeoJSONSource } from 'mapbox-gl';

import { Area, AreaMultiPolygon, AreaType } from '../../../types';

import {
  getAreaColor,
  getAreaOutlineColor,
  handleCircleAreaColor,
} from '../../../utils/getAreaColor';
import toCapitalize from '../../../utils/toCapitalize';

import '../Map2d/index.css';

const ContainerTooltip = styled('div')``;

interface TextTypeAreaProps {
  areaType: AreaType;
}

//prettier-ignore
const TextTypeArea = styled("div", { shouldForwardProp: prop => prop !== 'areaType' })<TextTypeAreaProps>`
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  font-family: "Noto Sans";
  &:before {
    display: inline-block;
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${({ areaType }) => handleCircleAreaColor(areaType)};
    margin-right: 6px;
  }
`;

const Subtitle = styled('span')`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Noto Sans';
`;

const useMapboxArea = (
  isMapReady: boolean,
  areas: Area[] | undefined,
  mapboxRef: React.MutableRefObject<mapboxgl.Map | null>,
  popupRef: React.MutableRefObject<mapboxgl.Popup | null>,
) => {
  useEffect(() => {
    if (isMapReady && areas && areas.length > 0 && mapboxRef) {
      const areaFeatures: Array<Feature> = areas
        .filter((area) => !!area.active && !!area.visible)
        .filter((area) => area.type !== 'path' && area.type !== 'pipe')
        .sort((area) => (area.type === 'boundary' ? -1 : 1))
        .map((area, i) => ({
          id: i + 20,
          type: 'Feature',
          properties: {
            areaName: area.name,
            typeArea: area.type,
            areaColor: getAreaColor(area.type),
            outlineColor: getAreaOutlineColor(area.type),
          },
          geometry: {
            type: 'MultiPolygon',
            coordinates: area.area as AreaMultiPolygon,
          },
        }));

      if (mapboxRef.current?.getSource('area-source')) {
        (mapboxRef.current?.getSource('area-source') as GeoJSONSource).setData({
          type: 'FeatureCollection',
          features: areaFeatures,
        });
      }

      const placeHolder = document.createElement('div');

      const hoverInfoRoot = createRoot(placeHolder);

      const showHoveredInfo = (feature: Feature<MultiPolygon>) => {
        hoverInfoRoot.render(
          <ContainerTooltip>
            <TextTypeArea areaType={feature.properties?.typeArea}>
              {toCapitalize(feature.properties?.typeArea)}
            </TextTypeArea>
            <Subtitle>{feature.properties?.areaName}</Subtitle>
          </ContainerTooltip>,
        );
        if (mapboxRef.current) {
          const getCenter = turf.centerOfMass(feature);
          popupRef.current
            ?.setLngLat([
              getCenter.geometry.coordinates[0],
              getCenter.geometry.coordinates[1],
            ])
            .setDOMContent(placeHolder)
            .addTo(mapboxRef.current);
        }
      };

      let hoveredStateId: null | string | number = null;
      mapboxRef.current?.on('mousemove', 'area-layer', (e) => {
        if (e.features && e.features.length > 0 && mapboxRef) {
          const feature = e.features[0] as Feature<MultiPolygon>;
          showHoveredInfo(feature);
          if (hoveredStateId !== null) {
            mapboxRef.current?.setFeatureState(
              { source: 'area-source', id: hoveredStateId },
              { hover: false },
            );
          }

          hoveredStateId = e.features[0].id ?? null;
          mapboxRef.current?.setFeatureState(
            { source: 'area-source', id: hoveredStateId ?? undefined },
            { hover: true },
          );
        }
      });

      mapboxRef.current?.on('mouseleave', 'area-layer', () => {
        popupRef.current?.remove();
        if (hoveredStateId !== null) {
          mapboxRef.current?.setFeatureState(
            { source: 'area-source', id: hoveredStateId },
            { hover: false },
          );
          hoveredStateId = null;
        }
      });
    }
  }, [isMapReady, areas, mapboxRef]);
};

export default useMapboxArea;
