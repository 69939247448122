import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface AreaAlertSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const DaswaterLSvgIcon = ({ disabled, ...props }: AreaAlertSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 23H12V9H17L17 11L14 11L14 13L17 13L17 15H14V17H17V19H14L14 21H17V23ZM21.9999 30C18.7963 30 16.0947 27.8856 15.2635 25H19V13.576C20.5683 11.4374 21.9999 10 21.9999 10C21.9999 10 29.001 17.0288 28.9999 23.1258C28.9999 26.9217 25.8663 30 21.9999 30Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default DaswaterLSvgIcon;
