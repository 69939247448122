import React from 'react';
import { Box, InputBaseProps, styled, Typography } from '@mui/material';

import { InputValidationError } from '../../types';

import Input from './Input';

const ContainerDetails = styled('div')`
  margin: 10px 0;
  height: 58px;

  & > .container-input {
    display: flex;
  }
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.box_bbg,
}));

const SpanRequire = styled('span')`
  color: ${({ theme }) => theme.color.highlight};
`;

interface SizeInputFieldProps
  extends Omit<InputBaseProps, 'error' | 'onChange'> {
  title: string;
  name: string;
  values: unknown[];
  onChange?: (index: number, val: number) => void;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  error?: InputValidationError;
  isRequired?: boolean;
}

const SizeInputField: React.FC<SizeInputFieldProps> = ({
  title,
  name,
  values,
  onChange,
  onBlur,
  error,
  isRequired,
  ...otherProps
}) => (
  <ContainerDetails>
    <Title>
      {title}
      {isRequired && <SpanRequire>*</SpanRequire>}
    </Title>
    <div className="container-input">
      {[0, 1, 2].map((index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
          <Input
            value={values[index] ?? ''}
            type="number"
            onChange={(e) => onChange?.(index, Number(e.target.value))}
            inputProps={{ min: 0, inputMode: 'numeric', step: '0.1' }}
            {...otherProps}
          />
          {index !== 2 && (
            <SpanRequire
              sx={{ color: 'white', fontSize: '10px', margin: '0px 7px' }}
            >
              x
            </SpanRequire>
          )}
        </Box>
      ))}
    </div>
  </ContainerDetails>
);

export default SizeInputField;
