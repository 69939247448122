import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';

import { SettingMap } from '../types';

import mapboxCustomPng from '../assets/images/png/tool/mapbox-button-custom.png';
import mapboxSatelitePng from '../assets/images/png/tool/mapbox-button-satelite.png';

const Container = styled(Box)`
  display: flex;
  gap: 10px;
`;

interface ButtonSateliteProps {
  selected: boolean;
}
// prettier-ignore
const ButtonMapStyle = styled('div', {shouldForwardProp: props => props !== "props"})<ButtonSateliteProps>`
    width: 40px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    border: 4px solid ${({ theme, selected }) =>  selected ? theme.color.primary.$100 : "transparent"};
  `;

interface MapSwitchProps extends BoxProps {
  mapLocal: SettingMap | null;
  setMapLocal: React.Dispatch<React.SetStateAction<SettingMap | null>>;
}

const MapSwitch: React.FC<MapSwitchProps> = ({
  mapLocal,
  setMapLocal,
  ...prop
}) => {
  return (
    <Container {...prop}>
      <ButtonMapStyle
        onClick={() => setMapLocal({ mapStyle: 'custom' })}
        sx={{
          backgroundImage: `url(${mapboxCustomPng})`,
        }}
        selected={mapLocal?.mapStyle === 'custom'}
      />
      <ButtonMapStyle
        onClick={() => setMapLocal({ mapStyle: 'satelite' })}
        sx={{
          backgroundImage: `url(${mapboxSatelitePng})`,
        }}
        selected={mapLocal?.mapStyle === 'satelite'}
      />
    </Container>
  );
};

export default MapSwitch;
