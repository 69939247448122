/* eslint-disable react/display-name */
import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog as MuiDialog,
  DialogProps,
  DialogTitle,
  keyframes,
  styled,
  Typography,
} from '@mui/material';
import classNames from 'classnames';

import { StatusType } from '../../types';

import IconContainer from '../IconContainer';

const Dialog = styled(MuiDialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    min-width: 480px;
    min-height: 567px;
    border-radius: 10px;
    z-index: 2;
    color: white;
    background: ${({ theme }) => theme.color.secondary.$80};
  }
`;

const Title = styled(DialogTitle)(({ theme }) => ({
  ...theme.typography.h4,
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  background: theme.color.secondary.$80,
  padding: '8px 8px 8px 16px',
  '& > span': {
    flex: 1,
  },
  '& > .IconContainer': {
    cursor: 'pointer',
  },
}));

const expand = keyframes`
  from {
    height: 0px;
  }

  to {
    height: 62px;
  }
`;

interface StatusBlockProps {
  type: StatusType;
}

const StatusBlock = styled('div')<StatusBlockProps>`
  text-align: center;
  color: white;
  padding: 10px;
  background: ${({ theme, type }) => {
    if (type === 'default') return '#A1A1A1';
    return theme.palette[type].main;
  }};
  animation: linear 0.2s ${expand};
`;

interface ConfirmDarkDialogDialogProps extends DialogProps {
  title?: string;
  subTitle?: string;
  status?: StatusType;
  message?: string;
  titleNode?: React.ReactNode;
  enableClose?: boolean;
}

const ConfirmDarkDialog: React.VFC<ConfirmDarkDialogDialogProps> =
  React.forwardRef(
    (
      {
        className,
        onClose,
        title,
        subTitle,
        children,
        status,
        message,
        titleNode,
        enableClose,
        ...otherProps
      },
      ref,
    ) => {
      const handleOnClose = (event: React.MouseEvent) => {
        if (onClose) onClose(event, 'backdropClick');
      };

      return (
        <Dialog
          className={classNames(className, 'InformationDialog')}
          ref={ref}
          {...otherProps}
        >
          <Title>
            <span>
              {title}{' '}
              <Typography
                sx={{ flex: 1, fontWeight: '400' }}
                variant="h4"
                component="span"
              >
                {subTitle}
              </Typography>
            </span>
            {titleNode}
            {!enableClose ? (
              <IconContainer size={32} onClick={handleOnClose}>
                <CloseIcon
                  sx={{ width: '18px', height: '18px' }}
                  color="inherit"
                />
              </IconContainer>
            ) : (
              <IconContainer size={32}>
                <CloseIcon
                  sx={{ width: '18px', height: '18px' }}
                  color="inherit"
                />
              </IconContainer>
            )}
          </Title>
          {status && status !== 'default' && (
            <StatusBlock type={status}>{message}</StatusBlock>
          )}
          {children}
        </Dialog>
      );
    },
  );

export default ConfirmDarkDialog;
